import React, { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import PriceTierCard from "../../components/Cards/PriceTierCard";

// Services
import axiosStripe from "../../services/axios/stripe";

const ProductsPage = (props) => {
  const navigate = useNavigate();

  const openStripeCheckoutPage = (lookupKey) => {
    axiosStripe.purchaseSubscription(
      {
        products: [
          {
            lookup_key: lookupKey,
            quantity: 1,
            type: "subscription",
          },
        ],
      },
      (data) => {
        // Redirect to checkout page
        window.location.href = data.sessionUrl;
      },
    );
  };

  return (
    <div className="flex flex-col items-center overflow-scroll px-4 md:px-20 pb-20">
      <div className="flex flex-col justify-center w-full max-w-5xl text-center lg:text-left mt-12 text-2xl font-light font-snow-blue-3">
        {props.currentUser.firstName
          ? `Welcome, ${props.currentUser.firstName}! `
          : null}
        We know that each of our customers is different. That's why we have
        designed multiple tiers to best fit your personal needs.
        <div className="mt-6 text-2xl font-bold font-snow-blue-primary max-w-5xl">
          Please choose the tier you'd like to subscribe to.
        </div>
      </div>
      <div className="flex flex-col xl:flex-row items-center justify-around lg:justify-between mt-9 w-full max-w-[1270px]">
        <div className="flex flex-col md:flex-row items-center w-full justify-around max-w-2xl">
          <PriceTierCard
            buttonLabel="Check Out"
            details={["1 Company", "1 User"]}
            onClick={() => {
              alert(
                "The Han Solo tier is free. You will be asked for credit card information, but you will not be charged unless you choose to upgrade your account to a paid tier.",
              );
              openStripeCheckoutPage("hansolo-free-sub");
            }}
            price="$0/mo"
            title="Han Solo"
          />
          <PriceTierCard
            buttonLabel="Check Out"
            details={["1 Company", "20 Users"]}
            onClick={() => openStripeCheckoutPage("team-sub")}
            price="$89/mo"
            title="Team"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center w-full justify-around max-w-2xl">
          <PriceTierCard
            buttonLabel="Check Out"
            details={["2 Companies/ Subcontractors", "100 Users"]}
            onClick={() => openStripeCheckoutPage("squad-sub")}
            price="$179/mo"
            title="Squad"
          />
          <PriceTierCard
            buttonLabel="Check Out"
            details={["5 Companies/ Subcontractors", "300 Users"]}
            onClick={() => openStripeCheckoutPage("fleet-sub")}
            price="$269/mo"
            title="Fleet"
          />
        </div>
      </div>
      <div className="mx-auto mt-4 text-2xl font-light font-snow-blue-3 max-w-5xl">
        Get started with a{" "}
        <b>
          <u>7 day free trial</u>
        </b>{" "}
        today!
      </div>
      <button
        onClick={() => navigate("/admin/user/company/?openJoinCompanyDialog=1")}
        className=" mt-9 self-center w-4/7 h-14 py-4 px-12 items-center justify-center flex rounded-full bg-snow-blue-3 text-snow-white text-lg font-bold hover:opacity-80 active:opacity-40"
      >
        Join Existing Company
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ProductsPage);
