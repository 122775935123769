import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import CompanyCard from "../../../components/Cards/CompanyCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import StandardLabel from "../../../components/Labels/StandardLabel";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import JoinCompanyModal from "../../../components/Modals/JoinCompanyModal";
import NewUserUnsubedModal from "../../../components/Modals/NewUserUnsubedModal";
import NotificationModal from "../../../components/Modals/NotificationModal";

// Redux
import { updateUser } from "../../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../../services/axios/companies";
import axiosUsers from "../../../services/axios/users";

const ChangeCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Collect Search parameters and export
  const [searchParams] = useSearchParams();

  const [companyData, setCompanyData] = useState([]);
  const [displayNewUserUnsubedModal, setDisplayNewUserUnsubedModal] =
    useState(false);
  const [displayJoinCompanyModal, setDisplayJoinCompanyModal] = useState(
    searchParams.get("openJoinCompanyDialog")?.toString() === "1",
  );
  const [
    displayUnableToAccessCompanyModal,
    setDisplayUnableToAccessCompanyModal,
  ] = useState(false);
  const [displayUnpaidCompanyModal, setDisplayUnpaidCompanyModal] =
    useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    // Get companies
    axiosUsers.getCompanies({ uid: props.currentUser.uid }, (data) => {
      setCompanyData(data.userCompanies);
      setLoading(false);

      // is user apart of a company
      if (data.userCompanies.length === 0) {
        checkSubscription();
      }
    });
  };
  // check if user does not a active subscription if not part of a company
  const checkSubscription = () => {
    axiosUsers.getUserAccountInformationById(
      { uid: props.currentUser.uid },
      (data) => {
        if (data.subscriptionInformation == null) {
          setDisplayNewUserUnsubedModal(true);
        }
      },
    );
  };
  const handleChangeCompany = async (companyId) => {
    // Check if company user is changing to is paid
    axiosCompanies.checkIfPaid(
      {
        companyId,
        uid: props.currentUser.uid,
      },
      async (paymentData) => {
        setIsOwner(false);

        if (paymentData.paid) {
          await dispatch(
            updateUser({
              ...props.currentUser,
              currentCompanyId: companyId,
              isPaid: true,
              isPaidUpdatedAt: Date.now(),
            }),
          );
          navigate("/admin/route");

          return;
        } else if (
          // Not paid and we are the company owner
          paymentData.companyOwner.uid === props.currentUser.uid
        ) {
          setIsOwner(true);
          setDisplayUnpaidCompanyModal(true);
        } else {
          // Not paid, but someone else is the company owner
          setDisplayUnpaidCompanyModal(true);
        }
      },
    );
  };

  return (
    <>
      <>
        <div className="flex flex-col items-center">
          <HeaderBar
            buttons={[
              {
                color: "blue",
                enabled: true,
                label: "Join Company",
                onClick: () => setDisplayJoinCompanyModal(true),
              },
              {
                color: "blue",
                enabled: true,
                label: "Create Company",
                onClick: () => navigate("create"),
              },
            ]}
            title="My Companies/Subcontractors"
          />
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            {loading ? (
              <LoadingIcon />
            ) : (
              <>
                <div className="w-full">
                  {companyData.filter((c) => c.role === "admin").length > 0 ? (
                    <StandardLabel label="My Role: Admin" />
                  ) : null}
                  {companyData.map((company, key) =>
                    company.role === "admin" ? (
                      <CompanyCard
                        isLicensed={company.isPaid}
                        joinCode={company.joinCode}
                        key={key}
                        name={company.companyName}
                        onClick={() => handleChangeCompany(company.companyId)}
                        onRightIconClicked={() => {
                          navigate(company.companyId);
                        }}
                        role={company.role}
                        showEdit={true}
                        showLicensed={true}
                      />
                    ) : null,
                  )}
                </div>

                <div className="w-full my-4">
                  {companyData.filter((c) => c.role === "employee").length >
                  0 ? (
                    <StandardLabel label="My Role: Foreman" />
                  ) : null}
                  {companyData.map((company, key) =>
                    company.role === "employee" ? (
                      <CompanyCard
                        disable={true}
                        isLicensed={company.isPaid}
                        key={key}
                        name={company.companyName}
                        onClick={() =>
                          setDisplayUnableToAccessCompanyModal(true)
                        }
                        role={company.role === "employee" ? "foreman" : ""}
                        showLicensed={true}
                      />
                    ) : null,
                  )}
                </div>
                {companyData.length === 0 ? (
                  <div className="text-lg text-snow-primary">
                    It does not seem you have joined any companies yet...
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        <NotificationModal
          button={{
            color: "blue",
            label: "Okay",
            onClick: () => {
              // Close
              setDisplayUnpaidCompanyModal(false);

              // Redirect to account page if isOwner
              if (isOwner) navigate("/admin/account");

              setIsOwner(false);
            },
          }}
          open={displayUnpaidCompanyModal}
          subtitle={
            isOwner
              ? "The company you tried to access doesn't appear to be licensed. Please add them to your licensed companies list on your Account Page to continue."
              : "The company you tried to access doesn't appear to be licensed. Please contact the company manager to setup their subscription."
          }
          title="Subscription Notice"
        />
        <NotificationModal
          button={{
            color: "blue",
            label: "Okay",
            onClick: () => {
              // Close
              setDisplayUnableToAccessCompanyModal(false);
            },
          }}
          open={displayUnableToAccessCompanyModal}
          subtitle="You cannot access companies you are not an admin for in the web Admin Portal. Please go to the mobile app to acces this company."
          title="Access Denied"
        />
        <JoinCompanyModal
          adjustForSidebar={true}
          open={displayJoinCompanyModal}
          onClose={() => {
            setDisplayJoinCompanyModal(false);
          }}
          refreshPage={() => {
            fetchData();
          }}
          subtitle="Please enter the 8 digit join code given to you by a company admin."
          title="Join Company"
        />
        <NewUserUnsubedModal
          adjustForSidebar={true}
          open={displayNewUserUnsubedModal}
          onClose={() => {
            setDisplayNewUserUnsubedModal(false);
          }}
          refreshPage={() => {
            fetchData();
          }}
          subtitle="You can get started by joining a company or buying a subscription"
          title="Welcome To SnowScape"
        />
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ChangeCompanyPage);
