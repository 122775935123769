import React from "react";

const LandingFeaturesCard = (props) => {
  return (
    <div className="flex flex-col bg-snow-primary rounded-2xl py-6 px-8 w-full md:w-80 mx-2 my-4 lg:my-0 max-w-sm">
      <div className="flex justify-center w-full">
        <div className="flex justify-center items-center bg-snow-white w-24 h-24 rounded-full mb-4">
          <img alt="snowscape-logo" className="w-20 h-20" src={props.logo} />
        </div>
      </div>
      <div className="text-snow-white text-xl font-bold mb-2">
        {props.label}
      </div>
      <div className="text-snow-white text-base font-light">
        {props.description}
      </div>
      <ul className="text-snow-white text-base font-light list-disc pl-2.5">
        {props.bulletFeatures?.map((feature, key) => (
          <li className="my-2" key={key}>
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LandingFeaturesCard;
