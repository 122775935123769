import React from "react";
import DragAndDropUpload from "../FileUpload/DragAndDropUpload";
import ImageNoteItem from "./ImageNoteItem";

const ImageNoteScroller = (props) => {
  // Return null if no upload or existing images
  if (props.imageArray.length === 0 && !props.enableUpload) return null;

  return (
    <div className="w-full mb-5">
      <div className="text-sm font-bold mb-2 self-start text-snow-primary">
        {props.label}
      </div>
      <div className={"flex overflow-x-auto space-x-3 w-full"}>
        {props.imageArray.map((image, key) => (
          <ImageNoteItem
            deletePressed={() => {
              const updatedArray = [...props.imageArray];

              updatedArray.splice(key, 1);
              props.setImageArray(updatedArray);
            }}
            enableDelete={props.enableDelete}
            key={key}
            uri={props.isSitemap ? image : image.uri}
          />
        ))}
        {props.enableUpload ? (
          <DragAndDropUpload
            imageArray={props.imageArray}
            fullWidth={false} // props.imageArray.length === 0
            setImageArray={props.setImageArray}
            uploadBoxLabel={props.uploadBoxLabel || "Upload"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ImageNoteScroller;
