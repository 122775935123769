import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";

// Redux
import { updateUser } from "../../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../../services/axios/companies";
import axiosUsers from "../../../services/axios/users";

const AdminCreateCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateCompany = () => {
    setCreateLoading(true);

    // Submit updated job to server
    axiosCompanies.addCompany(
      { name: companyName, uid: props.currentUser.uid },
      () => {
        setCreateLoading(false);
        axiosUsers.getUserById({ uid: props.currentUser.uid }, (user) => {
          dispatch(updateUser(user));
          navigate(-1);
        });
      },
      (err) => {
        alert(err);
      },
    );
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Create Company/Subcontractor" />
      <div className="flex flex-1 flex-col w-full p-10 overflow-y-scroll">
        <div className="flex flex-row w-full justify-between items-start mb-4">
          <div className="text-lg pr-4">
            Create a new company or subcontractor. You can add a new set of
            properties, routes and users to each one you create.
          </div>
          <div>
            <StandardButton
              color="green"
              disabled={companyName.length <= 0}
              label="Save"
              loading={createLoading}
              onClick={handleCreateCompany}
            />
          </div>
        </div>

        <LabeledTextboxInput
          label="Company Name"
          placeholder="Name your company..."
          setValue={(val) => {
            setCompanyName(val);
          }}
          value={companyName}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminCreateCompanyPage);
