import React from "react";
import { Outlet } from "react-router-dom";

// Components
import LandingNavBar from "../../components/Navigation/LandingNavBar";

function LandingPage() {
  return (
    <div className="">
      <LandingNavBar />
      {/* md:px-20 px-4 */}
      <div className="py-20">
        <Outlet />
      </div>
    </div>
  );
}

export default LandingPage;
