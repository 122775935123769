import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../Buttons/RoundButton";

// Resources
import CheckGreen from "../../resources/checked-circle-green.svg";

function PriceTierCard(props) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-72 h-96 rounded-3xl bg-snow-white drop-shadow py-11 px-10 mb-10">
      <div className="flex flex-col flex-1 items-center">
        <div className="text-4xl font-semibold text-snow-primary">
          {props.title}
        </div>
        <div className="text-lg font-extralight text-snow-primary">
          {props.price}
        </div>

        <div className="flex flex-col flex-1 mt-6 w-3/4">
          {props.details?.map((detail, key) => (
            <div className="flex flex-row my-2" key={key}>
              <img className="mr-2" src={CheckGreen} alt="checkmark" />
              <div className="flex">{detail}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="text-sm text-center font-extralight text-snow-primary mb-4">
        {props.subtext}
      </div>

      <RoundButton
        label={props.buttonLabel || "Get Started"}
        color="blue"
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          } else {
            navigate("/get-started");
          }
        }}
      />
    </div>
  );
}

export default PriceTierCard;
