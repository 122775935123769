import React, { useEffect, useState } from "react";

// Components
import StandardButton from "../../components/Buttons/StandardButton";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";

// Resources
import MailIcon from "../../resources/mail.svg";

// Services
import axiosContact from "../../services/axios/contact";

const QuotePage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [successfullySent, setSuccessfullySent] = useState(false);

  useEffect(() => {
    // Verify Email
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(email));
  }, [email]);

  const handleSendMessage = async () => {
    const data = await axiosContact.sendContactUsEmail({
      type: "get-quote",
      email,
      name,
      message,
    });

    if (data.success) {
      setSuccessfullySent(true);
    }
  };

  return (
    <div className="flex w-full justify-center px-4 pt-20 pb-10 overflow-scroll">
      <div className="flex flex-col w-full max-w-[1200px]">
        <div className="text-snow-primary font-medium text-5xl text-center md:text-left">
          Get A Quote
        </div>
        <div className="mt-7 text-snow-primary font-light text-2xl text-left">
          We'd love to hear from you! Send us a message or email directly, and
          we'll try to get back to you ask quickly as we can!
        </div>

        <div className="flex flex-col lg:flex-row mt-10">
          {!successfullySent ? (
            <div className="flex flex-col flex-1 items-start lg:pr-32">
              {/* Name */}
              <LabeledTextboxInput
                disabled={false}
                label="Name"
                placeholder="John Doe"
                setValue={setName}
                value={name}
              />
              {/* Email */}
              <LabeledTextboxInput
                disabled={false}
                label="Email"
                placeholder="your-email@gmail.com"
                setValue={setEmail}
                value={email}
              />
              {/* Message */}
              <LabeledTextboxInput
                disabled={false}
                multiline
                label="Message"
                placeholder="Type your message here..."
                setValue={setMessage}
                value={message}
              />

              <div className="w-full mb-12">
                <StandardButton
                  color="blue"
                  disabled={!name || !email || !message || !emailValid}
                  label="Get Quote"
                  onClick={handleSendMessage}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col flex-1 items-center justify-center pr-32">
              <div className="rounded-xl border-2 border-snow-green bg-snow-white py-20 px-10 drop-shadow-xl">
                <div className="font-bold text-3xl text-center text-snow-green pb-6">
                  Message Successfully Sent
                </div>
                <div className="font-bold text-xl text-center text-snow-green">
                  We're excited to hear from you and will get back to you as
                  quickly as possible!
                </div>
              </div>
            </div>
          )}

          <a
            href={`mailto:info@mysnowscape.com?subject=Get%20a%20Quote&body=Hello%20there!%0A%0AMy%20name%20is%20${
              name || "[YOUR NAME]"
            }.%0A%0AI'd%20like%20to%20get%20a%20quote%20for...`}
          >
            <div className="px-12 py-16 drop-shadow rounded-xl border-[1px] border-snow-light-grey hover:opacity-60 active:opacity-40">
              <div className="flex flex-col justify-between items-center w-full">
                <div className="mb-1">
                  <img alt="mail" className="w-7" src={MailIcon} />
                </div>
                <div className="font-bold text-lg text-snow-primary mb-1">
                  Support Team
                </div>

                <div className="text-md text-snow-primary">
                  info@mysnowscape.com
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuotePage;
