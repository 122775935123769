import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import GenericCard from "../../../components/Cards/GenericCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import axios from "../../../services/axios/backendAxios";
import errors from "../../../services/errors";

import { format, formatDuration, intervalToDuration, subDays } from "date-fns";

import { getAuth, getIdToken } from "firebase/auth";
import InfiniteScroll from "react-infinite-scroll-component";
import StandardButton from "../../../components/Buttons/StandardButton";
import DateRangeFilter from "../../../components/DateRangePicker/DateRangeFilter";
const AdminTimeClockPage = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [nextPageToLoad, setNextPageToLoad] = useState(0);
  const [perPage] = useState(30);
  const [lastPage, setLastPage] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({ _id: "all" });
  const [employees, setEmployees] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  let { employeeId } = useParams();

  useEffect(() => {
    // Note React intentionally calls this twice in dev mode so we need a way to abort: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    let controller = new AbortController();
    const loadData = async () => {
      try {
        let loadEmployeePromise = axios.get(
          `company/${props.currentUser.currentCompanyId}/users?take=10000`,
        );
        await loadPage(0, controller.signal);
        setEmployees((await loadEmployeePromise).data);
        // setLoading(false);
      } catch (err) {
        errors.report(err);
        alert(
          "Sorry we were not able to load the data.  Please try refreshing.",
        );
      }
    };
    loadData();
    return () => {
      controller.abort();
    };
  }, []);
  useEffect(() => {
    setSelectedEmployee(employees.find((e) => e._id === employeeId));
  }, [employeeId, employees]);
  useEffect(() => {
    updateFilters();
  }, [selectedEmployee, dateRange]);

  const fetchData = async (pageToLoad, signal) => {
    let params = {
      "filter[company_id]": props.currentUser.currentCompanyId,
      skip: pageToLoad * perPage,
      take: perPage,
    };
    if (selectedEmployee && selectedEmployee._id !== "all") {
      params["filter[user_id]"] = selectedEmployee._id;
    }
    if (dateRange) {
      /**
       * VERY IMPORTANT: here we must pass the day AS THE USER SELECTED IT - NOT in UTC.  If the user asked for Jan 10 then the payload
       * should contain Jan 10 regardless of the time of day or timezone.
       */
      params["filter[start_date]"] = format(dateRange.startDate, "yyyy-MM-dd");
      params["filter[end_date]"] = format(dateRange.endDate, "yyyy-MM-dd");
    }
    const resp = await axios.get(
      `timeclock/record?${new URLSearchParams(params)}`,
      { signal: signal },
    );
    return resp.data;
  };

  const nextPage = async (abortSignal) => {
    if (lastPage) {
      return;
    }
    await loadPage(nextPageToLoad, abortSignal);
  };

  /**
   *
   * @param {AbortSignal} abortSignal
   */
  const loadPage = async (pageToLoad, abortSignal) => {
    try {
      const job = fetchData(pageToLoad, abortSignal);
      setNextPageToLoad(pageToLoad + 1); // do this before we await to reduce the risk of loading the same page twice
      const results = await job;
      if (abortSignal == null || !abortSignal.aborted) {
        if (results.length > 0) {
          if (pageToLoad === 0) {
            setRecords([...results]);
          } else {
            setRecords([...records, ...results]);
          }
        }
        if (results.length < perPage) {
          setLastPage(true);
        }
      }
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        console.debug("axios request aborted - ignoring");
      } else {
        errors.report(err);
        alert(
          "Sorry we were not able to load the data.  Please try refreshing.",
        );
      }
    }
    setLoading(false);
  };

  const updateFilters = async () => {
    setRecords([]);
    setLoading(true);
    setLastPage(false);
    await loadPage(0);
    setLoading(false);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMM d, yyyy h:mm bbb");
  };

  const timeWorked = (record) => {
    return formatDuration(
      intervalToDuration({
        start: new Date(record.startTime),
        end: record.endTime ? new Date(record.endTime) : new Date(),
      }),
      {
        format: [
          "years",
          "months",
          "weeks",
          "days",
          "hours",
          "minutes",
          "seconds",
        ],
      },
    );
  };

  const createInput = (name, value, type) => {
    const input = document.createElement("input");
    input.type = type || "text";
    input.name = name;
    input.value = value;
    return input;
  };

  const downloadReport = async () => {
    // Create a hidden form element
    const form = document.createElement("form");
    form.style.display = "none"; // Hide the form

    // Set form attributes
    form.method = "POST";
    form.action = axios.defaults.baseURL + "/report/timeclock";
    form.target = "_blank"; // Open response in a new tab

    // Append form inputs to the form
    form.appendChild(
      createInput("filters[companyId]", props.currentUser.currentCompanyId),
    );
    form.appendChild(
      createInput("filters[from]", format(dateRange.startDate, "yyyy-MM-dd")),
    );
    form.appendChild(
      createInput("filters[to]", format(dateRange.endDate, "yyyy-MM-dd")),
    );
    if (selectedEmployee && selectedEmployee._id !== "all") {
      form.appendChild(createInput("filters[user_id]", selectedEmployee._id));
    }
    form.appendChild(
      createInput(
        "authToken",
        await getIdToken(getAuth().currentUser),
        "hidden",
      ),
    );

    // Append the form to the document body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          buttons={[
            {
              color: "blue",
              label: "New Employee",
              onClick: () => navigate("/admin/timeclock/employee/new"),
            },
            {
              color: "blue",
              label: "Add Record",
              onClick: () => navigate("/admin/timeclock/new"),
            },
            {
              color: "blue",
              label: "Export Data",
              onClick: () => downloadReport(),
            },
          ]}
          title="Time Clock"
        />
        <div
          className="flex flex-1 flex-col w-full p-10 items-left overflow-y-scroll"
          style={{ minHeight: 600 }}
        >
          <div className="filterBlock flex flex-col md:flex-row md:space-x-3 space-y-3 md:space-y-0 mb-5">
            <DateRangeFilter
              dateRange={dateRange}
              onChange={(v) => {
                setDateRange(v);
              }}
            />
            <Select
              onChange={(value) => {
                setSelectedEmployee(value);
              }}
              options={[{ _id: "all" }, ...employees]}
              placeholder="Filter by Employee"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  // Set's the border color to match the Date Picker == 'snow-light-grey'
                  borderColor: "#829399",
                }),
              }}
              value={selectedEmployee}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) =>
                option._id === "all"
                  ? "All Employees"
                  : `${option.firstName} ${option.lastName}`
              }
            />
          </div>
          {loading ? (
            <LoadingIcon />
          ) : (
            <div className="w-full">
              <InfiniteScroll
                dataLength={records.length}
                next={nextPage}
                hasMore={!lastPage}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    No more records matching your filters
                  </p>
                }
              >
                {records.map((record) => (
                  <GenericCard
                    title={`${record.user.firstName} ${record.user.lastName}`}
                    key={record._id}
                    onClick={() => {
                      navigate(`/admin/timeclock/${record._id}`);
                    }}
                  >
                    <span>
                      {formatTime(record.startTime)} &rarr;{" "}
                      {record.endTime ? (
                        formatTime(record.endTime)
                      ) : (
                        <span style={{ color: "red" }}>Active Record</span>
                      )}
                    </span>
                    <br />
                    <span>Time worked: {timeWorked(record)}</span>
                  </GenericCard>
                ))}
              </InfiniteScroll>
              {lastPage ? null : (
                // This is necessary since the InfiniteScroll only loads more pages on scroll - if the user has a large screen and all the results fit on it, then more results will never be loaded. You can test this by setting the per page to a small value like 3
                <StandardButton
                  color={"blue"}
                  label="Load More Results"
                  loading={loading}
                  onClick={() => {
                    nextPage();
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminTimeClockPage);
