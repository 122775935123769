import React from "react";

const ModalWrapper = (props) => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center">
      <div
        className={`w-full h-full bg-full-black absolute ${
          props.bgOpacity ? "opacity-" + props.bgOpacity : "opacity-40"
        }`}
        onClick={props.clickOff}
      />
      {props.children}
    </div>
  );
};

export default ModalWrapper;
