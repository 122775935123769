import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./reducers";

// localstorage
import { loadState, saveState } from "./localstorage";

// middleware
// import loggerMiddleware from './middleware/logger';

const preloadedState = loadState();

// console.log('preloadedState: ', preloadedState);

const store = configureStore({
  // middleware: [loggerMiddleware],
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(() => {
  saveState({
    currentUser: store.getState().currentUser,
    metadata: store.getState().metadata,
  });
});

export default store;
