import React from "react";
import PencilBlue from "../../resources/pencil-blue.svg";

function CompanyServiceCard(props) {
  return (
    <div
      className="flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40"
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-xl font-bold font-snow-primary">
          {props.label || "No Service Name Given"}
        </div>
      </div>
      {props.showEdit ? (
        <div className="flex w-16 justify-end items-center">
          <img className="w-7" src={PencilBlue} alt="edit" />
        </div>
      ) : null}
    </div>
  );
}

export default CompanyServiceCard;
