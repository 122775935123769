import React from "react";
import CheckBox from "./CheckBox";

const LabeledCheckBox = (props) => {
  let marginBottom = props.children ? "mb-2" : "mb-5";
  let wrapperClass = `w-full ${marginBottom}`;
  return (
    <>
      <div className={wrapperClass}>
        <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
          {props.label}
        </div>
        <div className="text-sm font-light mb-1 self-start text-snow-primary"></div>
        <CheckBox
          checked={props.checked}
          label={props.checkboxLabel}
          lightMode={props.lightMode}
          onClick={props.onClick}
        />
      </div>
      {props.checked ? props.children : null}
    </>
  );
};
export default LabeledCheckBox;
