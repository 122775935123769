import React from "react";
import TrashRed from "../../resources/trash-can-red.svg";

function RouteCard(props) {
  return (
    <div
      className={`flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40 ${!props.isActive ? "opacity-60" : ""}`}
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        {props.isActive ? null : (
          <div className="text-2xl text-snow-red">Inactive</div>
        )}
        <div className="text-2xl">{props.name || "No Route Name Given"}</div>
        <div className="font-bold mb-2">
          {props.numberOfJobs === null
            ? "unknown number of"
            : props.numberOfJobs}{" "}
          job
          {props.numberOfJobs !== 1 ? "s" : null}
        </div>
        <div className="font-light">{props.description}</div>
      </div>
      {props.showDelete ? (
        <div
          className="flex w-16 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={(e) => {
            props.onRightIconClicked();
            // Don't run card pressed function
            e.stopPropagation();
          }}
        >
          <img className="w-7" src={TrashRed} alt="trash" />
        </div>
      ) : null}
    </div>
  );
}

export default RouteCard;
