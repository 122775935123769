import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Services
import axiosCompanies from "../../../services/axios/companies";
import axiosUsers from "../../../services/axios/users";
import UserPermissions from "../../../components/UserPermissions";

// TODO
// - Make Popup to ensure want to remove from company
// - Make Popup to ensure want to make admin

const AdminEmployeeDetailsPage = (props) => {
  const navigate = useNavigate();
  let { employeeUid } = useParams();

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayRemoveAdminModal, setDisplayRemoveAdminModal] = useState(false);
  const [
    displayRemoveUserFromCompanyModal,
    setDisplayRemoveUserFromCompanyModal,
  ] = useState(false);
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axiosUsers.getUserById({ uid: employeeUid }, (data) => {
      setEmployee(data);
      setLoading(false);
    });
  }, [employeeUid]);

  const handleMakeAdmin = () => {
    setLoading(true);
    // Add admin to server
    axiosCompanies.addAdmin(
      {
        companyId: props.currentUser.currentCompanyId,
        uid: employee.uid,
      },
      () => {
        setLoading(false);

        // Return to Employee Page
        navigate(-1);
      },
    );
  };

  const handleRemoveAsAdmin = () => {
    if (props.currentUser.uid === employeeUid) {
      alert(
        "You cannot remove yourself as admin. Ask another admin to do this.",
      );
      return;
    } else {
      setLoading(true);
      axiosCompanies.removeAdmin(
        {
          companyId: props.currentUser.currentCompanyId,
          uid: employeeUid,
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
      );
    }
  };

  const handleRemoveUserFromCompany = () => {
    if (props.currentUser.uid === employeeUid) {
      alert(
        "You cannot remove yourself from your company. Ask another admin to do this.",
      );
      return;
    } else {
      setLoading(true);
      axiosCompanies.removeEmployeeFromCompany(
        {
          companyId: props.currentUser.currentCompanyId,
          userIdToRemove: employeeUid,
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
      );
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          dropdown={{
            enabled: true,
            options:
              employee.companies &&
              employee.companies[props.currentUser.currentCompanyId]?.role ===
                "admin"
                ? [
                    {
                      color: "red",
                      label: "Remove Admin Permissions",
                      operation: () => setDisplayRemoveAdminModal(true),
                    },
                    {
                      color: "red",
                      label: "Remove From Company",
                      operation: () =>
                        setDisplayRemoveUserFromCompanyModal(true),
                    },
                  ]
                : [
                    {
                      color: "green",
                      label: "Add As Admin",
                      operation: () => setDisplayConfirmationModal(true),
                    },
                    {
                      color: "red",
                      label: "Remove From Company",
                      operation: () =>
                        setDisplayRemoveUserFromCompanyModal(true),
                    },
                  ],
          }}
          title="Employees"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10  overflow-y-scroll">
            <div className="flex flex-row w-full justify-end">
              <StandardButton
                color="blue"
                label="Back"
                onClick={() => navigate(-1)}
              />
            </div>
            <LabeledTextboxInput
              disabled={true}
              label="Name"
              value={employee.firstName + " " + employee.lastName}
            />
            <LabeledTextboxInput
              disabled={true}
              label="Phone Number"
              value={employee.phoneNumber}
            />
            <LabeledTextboxInput
              disabled={true}
              label="Role"
              value={
                employee.companies[props.currentUser.currentCompanyId].role
              }
            />
            <UserPermissions
              userToEdit={employee}
              companyId={props.currentUser.currentCompanyId}
            />
          </div>
        )}
      </div>
      <MultipleChoiceModal
        open={displayConfirmationModal}
        options={[
          {
            color: "green",
            label: "Make Admin",
            onClick: () => {
              setDisplayConfirmationModal(false);
              handleMakeAdmin();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmationModal(false),
          },
        ]}
        subtitle={`Are you sure you want to make ${
          employee?.firstName + " " + employee?.lastName
        } an admin?`}
        title="Add Admin"
      />
      <MultipleChoiceModal
        open={displayRemoveAdminModal}
        options={[
          {
            color: "red",
            label: "Remove",
            onClick: () => {
              setDisplayRemoveAdminModal(false);
              handleRemoveAsAdmin();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayRemoveAdminModal(false),
          },
        ]}
        subtitle="Are you sure you want to revoke admin privaleges from this user? They will be left as an employee of this company."
        title="Remove Admin Permission"
      />
      <MultipleChoiceModal
        open={displayRemoveUserFromCompanyModal}
        options={[
          {
            color: "red",
            label: "Remove",
            onClick: () => {
              setDisplayRemoveUserFromCompanyModal(false);
              handleRemoveUserFromCompany();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayRemoveUserFromCompanyModal(false),
          },
        ]}
        subtitle="Are you sure you want to remove this user from your company? They will no longer have access to any of this company's data."
        title="Remove User From Company"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminEmployeeDetailsPage);
