import React, { useState } from "react";

// Components
import AddCircleButton from "../Buttons/AddCircleButton";
import EmailItem from "./EmailItem";

// Resources
import PlusBlue from "../../resources/plus-blue-small.svg";

const EmailField = (props) => {
  const [newEmailInput, setNewEmailInput] = useState("");
  const [showNewEmailInput, setShowNewEmailInput] = useState(false);

  return (
    <div className="w-full mb-5">
      <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
        {props.label}
      </div>
      <div className="text-sm font-light mb-2 self-start text-snow-primary">
        {props.subtitle ||
          "Add emails you would like this properties reports sent to"}
      </div>
      {props.emailList.map((email, key) => (
        <EmailItem
          enableDelete={props.enableDelete}
          enableEdit={props.enableEdit}
          email={email}
          key={key}
          onChange={(val) => {
            const updatedEmailList = [...props.emailList];
            updatedEmailList[key] = val;
            props.setEmailList(updatedEmailList);
          }}
          onDelete={() => {
            const updatedEmailList = [...props.emailList];
            updatedEmailList.splice(key, 1);

            props.setEmailList(updatedEmailList);
          }}
        />
      ))}
      {props.enableAdd ? (
        showNewEmailInput ? (
          <div className="flex flex-row bg-snow-white rounded-3xl py-2.5 px-8 mb-2.5 justify-between drop-shadow-md hover:drop-shadow-lg max-w-lg">
            <input
              autoFocus
              type="text"
              className="w-full bg-snow-white"
              onChange={(e) => setNewEmailInput(e.target.value)}
              value={newEmailInput}
              onBlur={() => {
                if (newEmailInput) {
                  props.setEmailList([...props.emailList, newEmailInput]);
                  setNewEmailInput("");
                }
                setShowNewEmailInput(false);
              }}
              onKeyDown={(e) => {
                // Submit when Enter key is pressed
                if (e.key === "Enter") {
                  e.target.blur();
                }
              }}
            />
            <div
              className="flex ml-4 justify-end items-center hover:opacity-60 active:opacity-40"
              onClick={() => {
                if (newEmailInput) {
                  props.setEmailList([...props.emailList, newEmailInput]);
                  setNewEmailInput("");
                }
                setShowNewEmailInput(false);
              }}
            >
              <img className="w-5" src={PlusBlue} alt="add" />
            </div>
          </div>
        ) : (
          <AddCircleButton
            onClick={() => {
              setShowNewEmailInput(!showNewEmailInput);
            }}
          />
        )
      ) : null}
    </div>
  );
};

export default EmailField;
