import React from "react";

const LandingVideoPlayer = (props) => {
  return (
    <div className="flex-col w-full max-w-[550px]">
      <div className="flex w-full max-w-[550px] h-[220px] sm:h-[344px] relative">
        <div className="absolute bg-full-black w-full h-full z-20 rounded-3xl drop-shadow">
          <iframe
            className="w-full h-full rounded-3xl"
            src={props.url}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        {props.showBackgroundLayer ? (
          <div
            className={`absolute w-full h-full -top-3 sm:-top-6 left-4 sm:left-8 drop-shadow-2xl rounded-3xl border-[1px] border-snow-extra-light-grey ${
              props.bgColor ? "bg-[" + props.bgColor + "]" : "bg-snow-white"
            } `}
          />
        ) : null}
      </div>
      {props.label ? (
        <div className="flex justify-center text-snow-primary z-20 text-2xl font-bold mb-8">
          {props.label}
        </div>
      ) : null}
    </div>
  );
};

export default LandingVideoPlayer;
