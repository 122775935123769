import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import axios from "../../../services/axios/backendAxios";

// Services
import InputWrapper from "../../../components/Inputs/InputWrapper";
import errors from "../../../services/errors";
import flash from "../../../services/flash";
// import { ObjectId } from 'bson';
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import StandardButton from "../../../components/Buttons/StandardButton";

const TimeClockEmployeePage = (props) => {
  let { user_id } = useParams();
  const navigate = useNavigate();

  const [record, setRecord] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isNew, setIsNew] = useState(true);

  const loadData = async (signal) => {
    try {
      if (user_id === "new") {
        setRecord({
          // _id: (new ObjectId()).toString(),
          companyId: props.currentUser.currentCompanyId,
        });
      } else {
        const recordData = (
          await axios.get(`user/all/${user_id}`, {
            signal: signal,
          })
        ).data;
        setRecord(recordData);
        setIsNew(false);
      }
      setLoading(false);
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        errors.report(err);
        alert(
          "Sorry we were not able to load the data.  Please try refreshing.",
        );
      }
    }
  };

  useEffect(() => {
    // Note React intentionally calls this twice in dev mode so we need a way to abort: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    let controller = new AbortController();
    loadData(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  const setField = (field, value) => {
    let newObj = { ...record };
    newObj[field] = value;
    setRecord(newObj);
  };
  const save = async () => {
    try {
      setLoading(true);
      let resp;
      let newObj = { ...record };
      if (isNew) {
        newObj.phoneNumber = parsePhoneNumber(record.phoneNumber, "US").number;
        resp = await axios.post(`/user/add-timeclock-user`, newObj);
        setIsNew(false);
      } else {
        resp = await axios.patch(`/user/all/${user_id}`, newObj);
      }
      setRecord(resp.data);
      setLoading(false);
      flash.success("Saved record");
      navigate("/admin/employee");
    } catch (err) {
      errors.report(err, {
        message: "Unable to save edits to a record to the backend",
      });
      alert("Error trying to save: " + err?.response?.data);
      loadData();
    }
  };
  const deleteRecord = async () => {
    try {
      if (!isNew) {
        setLoading(true);
        await axios.delete(`/user/all/${user_id}`);
        flash.success("Record deleted");
        navigate("/admin/employee");
      } else {
        alert("Record has not been saved so it cannot be deleted.");
      }
    } catch (err) {
      errors.report(err, { message: "Unable to delete a time record" });
      alert("Error trying to delete: " + err?.response?.data);
      loadData();
    }
  };
  const canSave = () => {
    return (
      record.firstName != null &&
      record.lastName != null &&
      record.phoneNumber != null
    );
  };

  const handlePhoneNumberChange = (newNumber) => {
    setField("phoneNumber", new AsYouType("US").input(newNumber));
  };
  const inputClasses = `w-full text-lg font-regular border-2 bg-transparent rounded p-1`;

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          buttons={[
            {
              color: "red",
              label: "Delete",
              onClick: () => deleteRecord(),
            },
          ]}
          title="Time Clock Employee"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <div className="w-full">
              <div className="text-sm text-snow-primary font-light mb-1 max-w-md">
                Time clock employees cannot log into the app. They are only used
                for tracking time. A foreman can use the mobile app to track
                time for a "time clock only user". If you want to create a full
                user who can log into the mobile app, go{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="/admin/employee"
                >
                  here
                </a>{" "}
                instead.
              </div>
              <InputWrapper label="First Name">
                <input
                  className={inputClasses}
                  onChange={(e) => setField("firstName", e.target.value)}
                  type="text"
                  value={record.firstName}
                />
              </InputWrapper>
              <InputWrapper label="Last Name">
                <input
                  className={inputClasses}
                  onChange={(e) => setField("lastName", e.target.value)}
                  type="text"
                  value={record.lastName}
                />
              </InputWrapper>
              <InputWrapper label="Phone Number">
                <input
                  disabled={!isNew}
                  className={inputClasses}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  type="text"
                  value={record.phoneNumber}
                />
              </InputWrapper>

              <div className="max-w-sm" style={{ marginTop: 20 }}>
                <StandardButton
                  label={isNew ? "Create" : "Save"}
                  onClick={save}
                  disabled={!canSave()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(TimeClockEmployeePage);
