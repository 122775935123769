import errors from "../services/errors";
import store from "../store/index";
import { setFeaturesForCompany } from "../store/reducers/metadataSlice";
// import axios from '../services/axios/backendAxios';
import axios from "axios";

const features = {
  CHECK_INTERVAL_IN_MINUTES: 10,
  // we purposely pass in the set of feature here so that the component can refresh when the features state changes
  checkEnabled: (featureName, currentFeatures, companyId) => {
    return (
      currentFeatures &&
      currentFeatures[companyId] &&
      currentFeatures[companyId][featureName]
    );
  },
  refresh: async () => {
    try {
      const state = store.getState();
      let lastUpdated = state.metadata?.features?.updatedAt
        ? new Date(state.metadata.features.updatedAt)
        : null;
      let now = new Date();
      if (
        lastUpdated == null ||
        (now - lastUpdated) / 1000 / 60 > features.CHECK_INTERVAL_IN_MINUTES
      ) {
        console.log("Updating expired feature data");
        const currentCompanyId = state.currentUser?.currentCompanyId;
        if (currentCompanyId) {
          // const res = await axios.get('company/' + currentCompanyId);
          const res = await axios.get(
            `public/company/${currentCompanyId}/features`,
          );
          store.dispatch(
            setFeaturesForCompany({
              companyId: currentCompanyId,
              features: res.data ?? {},
            }),
          );
        }
      }
    } catch (err) {
      errors.report(err, {
        message: "unable to refresh features from the backend",
      });
    }
  },
};

export default features;
