import React from "react";
import AppleAppStoreIcon from "../../resources/app-store/apple-app-store-logo.svg";
import GooglePlayStoreIcon from "../../resources/app-store/google-play-store-logo.svg";

function StartARouteCard() {
  return (
    <div className="rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4">
      <h3 className="font-bold text-lg">Start A Route</h3>
      <p className="mb-3">
        Want to start a route and record data? Use our app and do it on the job
        site!
      </p>
      <div className="flex flex-row gap-5">
        <a
          className="w-44 hover:opacity-70"
          href="https://apps.apple.com/us/app/snow-commercial-management/id6443944347"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="apple-store" className="w-full" src={AppleAppStoreIcon} />
        </a>

        <a
          className="w-44 hover:opacity-70"
          href="https://play.google.com/store/apps/details?id=com.trayl.dev.snow"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="google-store"
            className="w-full"
            src={GooglePlayStoreIcon}
          />
        </a>
      </div>
    </div>
  );
}

export default StartARouteCard;
