import store from "../store/index";
import {
  flashMessage,
  flashMessageRemove,
} from "../store/reducers/metadataSlice";
let flash = {};
flash.success = async function (message) {
  try {
    store.dispatch(flashMessage({ message, type: "success" }));
    await new Promise((resolve) => setTimeout(resolve, 6000));
    store.dispatch(flashMessageRemove());
  } catch (error) {
    console.log(error);
  }
};
flash.error = async function (message) {
  try {
    store.dispatch(flashMessage({ message, type: "error" }));
    await new Promise((resolve) => setTimeout(resolve, 4000));
    store.dispatch(flashMessageRemove());
  } catch (error) {
    console.log(error);
  }
};
flash.clear = async function () {
  store.dispatch(flashMessageRemove());
};

export default flash;
