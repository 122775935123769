import axios from "axios";
import { endOfDay, startOfDay } from "date-fns";
import { getAuth, getIdToken } from "firebase/auth";
import { useCallback } from "react";
import firebaseAuth from "../services/firebase/firebaseAuth";

const createInput = (name, value, type) => {
  const input = document.createElement("input");
  input.type = type || "text";
  input.name = name;
  input.value = value;
  return input;
};

const useJobHistoryReport = ({
  companyId,
  dateRange = {},
  services = [],
  jobs = [],
  cities = [],
  flaggedForReview = null,
  season = null,
  searchPhrase = null,
  operators = [],
  routes = [],
}) => {
  const createJobHistoryReport = useCallback(() => {
    firebaseAuth.getUser(async () => {
      // Create a hidden form element
      const form = document.createElement("form");
      form.style.display = "none"; // Hide the form

      // Collects a fresh token
      const token = await getIdToken(getAuth().currentUser);

      // Set form attributes
      form.method = "POST";
      form.action = axios.defaults.baseURL + "/report/job-history";
      form.target = "_blank"; // Open response in a new tab

      // Append form inputs to the form
      form.appendChild(createInput("filters[companyId]", companyId));
      if (dateRange.startDate && dateRange.endDate) {
        form.appendChild(
          createInput(
            "filters[from]",
            startOfDay(dateRange.startDate).toISOString(),
          ),
        );
        form.appendChild(
          createInput("filters[to]", endOfDay(dateRange.endDate).toISOString()),
        );
        form.appendChild(
          createInput(
            "displayTimezone",
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          ),
        );
      }
      services.forEach((item) => {
        form.appendChild(
          createInput("filters[services][]", item.value ?? item),
        );
      });
      jobs.forEach((item) => {
        form.appendChild(createInput("filters[jobs][]", item.value ?? item));
      });

      cities.forEach((item) => {
        form.appendChild(createInput("filters[cities][]", item.value ?? item));
      });

      operators.forEach((item) => {
        form.appendChild(
          createInput("filters[operators][]", item.value ?? item),
        );
      });

      routes.forEach((item) => {
        form.appendChild(createInput("filters[routes][]", item.value ?? item));
      });

      if (season) form.appendChild(createInput("filters[season]", season));
      if (flaggedForReview)
        form.appendChild(
          createInput("filters[flaggedForReview]", flaggedForReview ? 1 : 0),
        );
      if (searchPhrase)
        form.appendChild(createInput("searchPhrase", searchPhrase));

      form.appendChild(createInput("authToken", token, "hidden"));

      // Append the form to the document body
      document.body.appendChild(form);

      // Submit the form
      form.submit();
    });
  }, [companyId, dateRange, services, jobs]);

  return { createJobHistoryReport };
};

export default useJobHistoryReport;
