import React from "react";
import CheckMarkBlue from "../../resources/check-mark-blue.svg";
import CheckMarkWhite from "../../resources/check-mark-white.svg";

const FilterCheckBox = (props) => {
  return (
    <div className="flex flex-row mr-5 cursor-pointer" onClick={props.onClick}>
      {props.checked ? (
        <div
          className={`flex h-5 w-5 border-2 rounded ${
            props.lightMode
              ? "border-snow-white bg-snow-white"
              : "border-snow-primary bg-snow-primary"
          } items-center justify-center`}
        >
          <img
            className=""
            src={props.lightMode ? CheckMarkBlue : CheckMarkWhite}
            alt="checkmark"
          />
        </div>
      ) : (
        <div
          className={`h-5 w-5 border-2 rounded ${
            props.lightMode ? "border-snow-white" : "border-snow-primary"
          }`}
        />
      )}
      <div
        className={`ml-1.5 text-base leading-5 ${
          props.lightMode ? "text-snow-white" : "text-snow-primary"
        }`}
      >
        {props.label}
      </div>
    </div>
  );
};
export default FilterCheckBox;
