import React, { useEffect } from "react";

// Components
import StandardButton from "../Buttons/StandardButton";
import AddPaymentMethodForm from "../Stripe/AddPaymentMethodForm";
import ModalWrapper from "./ModalWrapper";

const AddPaymentMethodModal = (props) => {
  useEffect(() => {}, [props.open]);

  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.onClose}>
          <div
            className={`flex flex-col w-2/3 h-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll ${
              props.adjustForSidebar ? "md:ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "Add Payment Method"}
              </div>
              <div className="h-10 flex flex-row">
                <StandardButton
                  color="red"
                  label="Cancel"
                  onClick={props.onClose}
                />
              </div>
            </div>
            <div>
              <AddPaymentMethodForm handleSuccess={props.onClose} />
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default AddPaymentMethodModal;
