import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import React, { useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import DateRangePickerWrapper from "../../../components/DateRangePicker/DateRangePickerWrapper";
import HeaderBar from "../../../components/Header/HeaderBar";

// Services
import useJobHistoryReport from "../../../hooks/useJobHistoryReport";
import axiosCompanies from "../../../services/axios/companies";
import axiosJobs from "../../../services/axios/jobs";
import axiosMaterials from "../../../services/axios/materials";
import firebaseAuth from "../../../services/firebase/firebaseAuth";

const reportTypeOptions = [
  { label: "Materials", value: "material" },
  { label: "Job History", value: "job_history" },
  // { label: 'Employee ', value: 'employee' },
];

const AdminReportsPage = (props) => {
  const animatedComponents = makeAnimated();

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [jobs, setJobs] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [services, setServices] = useState([]);
  const [type, setType] = useState(null);

  const { createJobHistoryReport } = useJobHistoryReport({
    companyId: props.currentUser.currentCompanyId,
    dateRange,
    jobs,
    services,
  });

  // Load materials for dropdown picker
  const loadMaterials = async (searchValue, callback) => {
    // Async's loadOptions must be syncronous - wrap in async inline function to solve
    (async () => {
      const materialData = await axiosMaterials.getCompanyMaterials({
        companyId: props.currentUser.currentCompanyId,
      });

      const filteredOptions = materialData.filter((option) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase()),
      );

      const filteredOptionsShaped = filteredOptions.map((item) => {
        return {
          label: item.name + " (" + item.baseUnit + ")",
          value: item._id,
        };
      });

      callback(filteredOptionsShaped);
    })();
  };

  // Load jobs for dropdown picker
  const loadJobs = async (searchValue, callback) => {
    axiosJobs.getAllJobs(
      { companyId: props.currentUser.currentCompanyId },
      (jobData) => {
        const filteredOptions = jobData.filter((option) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
        const filteredOptionsShaped = filteredOptions.map((item) => {
          return { label: item.name, value: item._id };
        });

        callback(filteredOptionsShaped);
      },
      () => {},
    );
  };

  // Load services for dropdown picker
  const loadServices = async (searchValue, callback) => {
    axiosCompanies.getCommonServices(
      { companyId: props.currentUser.currentCompanyId },
      (serviceData) => {
        const serviceDataArray = [
          ...serviceData.snow,
          ...serviceData.landscape,
        ];

        const filteredOptions = serviceDataArray.filter((option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase()),
        );

        const filteredOptionsShaped = filteredOptions.map((item) => {
          return {
            label: item.label,
            value: item.key,
          };
        });

        callback(filteredOptionsShaped);
      },
      () => {},
    );
  };

  // Helper function to create and append an input element
  const createInput = (name, value, type) => {
    const input = document.createElement("input");
    input.type = type || "text";
    input.name = name;
    input.value = value;
    return input;
  };

  // Build form and download CSV file of materials report
  const createMaterialsReport = () => {
    firebaseAuth.getUser(async () => {
      // Create a hidden form element
      const form = document.createElement("form");
      form.style.display = "none"; // Hide the form

      // Collects a fresh token
      const token = await getIdToken(getAuth().currentUser);

      // Set form attributes
      form.method = "POST";
      form.action = axios.defaults.baseURL + "/report/materials-consumed";
      form.target = "_blank"; // Open response in a new tab

      // Append form inputs to the form
      form.appendChild(
        createInput("filters[companyId]", props.currentUser.currentCompanyId),
      );
      form.appendChild(createInput("filters[from]", dateRange.startDate));
      form.appendChild(createInput("filters[to]", dateRange.endDate));
      materials.forEach((item) => {
        form.appendChild(createInput("filters[materials][]", item.value));
      });
      jobs.forEach((item) => {
        form.appendChild(createInput("filters[jobs][]", item.value));
      });
      form.appendChild(createInput("authToken", token, "hidden"));

      // Append the form to the document body
      document.body.appendChild(form);

      // Submit the form
      form.submit();
    });
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Materials" />
      <div className="flex flex-1 flex-col w-full p-10 pb-40 items-center overflow-y-scroll">
        <div className="max-w-xl w-full">
          <div className="flex flex-col w-full my-4">
            <div className="font-bold mb-1">Choose Report Type</div>
            <Select
              className="w-full"
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={null}
              isSearchable={true}
              onChange={(selectedOption) => setType(selectedOption)}
              options={reportTypeOptions}
            />
          </div>

          {/* Materials Dropdown */}
          {type?.value === "material" ? (
            <div className="flex flex-col w-full my-4">
              <div className="font-bold mb-1">Choose Materials</div>
              <AsyncSelect
                className="w-full"
                defaultOptions
                isMulti
                loadOptions={loadMaterials}
                onChange={(selectedOptions) => setMaterials(selectedOptions)}
                placeholder="All Materials"
              />
            </div>
          ) : null}

          {/* Services Dropdown */}
          {type?.value === "job_history" ? (
            <div className="flex flex-col w-full my-4">
              <div className="font-bold mb-1">Choose Services</div>
              <AsyncSelect
                className="w-full"
                defaultOptions
                isMulti
                loadOptions={loadServices}
                onChange={(selectedOptions) => setServices(selectedOptions)}
                placeholder="All Services"
              />
            </div>
          ) : null}

          {/* Properties Dropdown */}
          {type?.value === "material" || type?.value === "job_history" ? (
            <div className="flex flex-col w-full my-4">
              <div className="font-bold mb-1">Choose Properties</div>
              <AsyncSelect
                className="w-full"
                defaultOptions
                isMulti
                loadOptions={loadJobs}
                onChange={(selectedOptions) => setJobs(selectedOptions)}
                placeholder="All Properties"
              />
            </div>
          ) : null}

          <DateRangePickerWrapper
            dateRange={dateRange}
            open={type?.value}
            setDateRange={setDateRange}
          />

          <div className="mt-4">
            <StandardButton
              color="blue"
              disabled={!type}
              label={"Create Report"}
              onClick={() => {
                if (type?.value === "material") {
                  createMaterialsReport();
                } else if (type?.value === "job_history") {
                  createJobHistoryReport();
                } else {
                  alert("Invalid report type selected");
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminReportsPage);
