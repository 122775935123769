import React from "react";

/**
 *
 * @param {{value: any, unit: string, onChange: fn}} props
 */
function InputWithUnit(props) {
  return (
    <div>
      <div className="relative mt-2 rounded-md shadow-sm border-2 border-snow-primary">
        <input
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          type="text"
          name="price"
          id="price"
          className="bg-transparent block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          placeholder="0.00"
        />
        <div className="absolute inset-y-0 right-0 flex items-center align-center">
          <span className=" py-0 pl-2 pr-7 text-gray-500 sm:text-sm">
            {props.unit}
          </span>
        </div>
      </div>
    </div>
  );
}

export default InputWithUnit;
