import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../services/axios/companies";
import axiosUsers from "../../services/axios/users";

const AuthCreateFirstCompany = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateCompany = () => {
    setCreateLoading(true);

    // Submit updated job to server
    axiosCompanies.addCompany(
      { name: companyName, uid: props.currentUser.uid },
      (companyData) => {
        setCreateLoading(false);
        axiosUsers.getUserById({ uid: props.currentUser.uid }, (user) => {
          // Update user, use first company as new companyId
          dispatch(
            updateUser({
              ...user,
              currentCompanyId: companyData.company._id,
            }),
          );
          navigate("/admin");
          // TODO: Display some sort of success message!
          // navigate('/admin/tutorial');
        });
      },
    );
  };

  return (
    <div className="flex flex-col mx-auto overflow-scroll px-4 pb-20 items-center">
      <div className="mx-auto mt-12 mb-20 md:mt-32 text-2xl font-light font-snow-blue-3 max-w-2xl">
        Thanks for joining our family! Now that you're ready to go, let's create
        your first company.
      </div>
      <div className="w-full max-w-2xl">
        <LabeledTextboxInput
          label="Company Name"
          placeholder="Name your company..."
          setValue={(val) => {
            setCompanyName(val);
          }}
          value={companyName}
        />
        <RoundButton
          label={"Create"}
          color="blue"
          disabled={createLoading}
          onClick={handleCreateCompany}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AuthCreateFirstCompany);
