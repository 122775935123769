// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz8qeCdKbP7YOI7aQck3gt4pioManlGYw",
  authDomain: "snow-e83cb.firebaseapp.com",
  projectId: "snow-e83cb",
  storageBucket: "snow-e83cb.appspot.com",
  messagingSenderId: "805174212441",
  appId: "1:805174212441:web:d39348995d2a4b94cbfe48",
  measurementId: "G-E91GXEEVZD",
};

export default firebaseConfig;
