import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import EmployeeCard from "../../../components/Cards/EmployeeCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Services
import axiosCompanies from "../../../services/axios/companies";

const AdminManageAdminsPage = (props) => {
  const navigate = useNavigate();

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userToMakeAdmin, setUserToMakeAdmin] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosCompanies.getAllUsers(
      { companyId: props.currentUser.currentCompanyId },
      (employees) => {
        setEmployees(employees.filter((e) => e.uid != null));
        setLoading(false);
      },
    );
  }, []);

  const handleMakeAdmin = () => {
    setSubmitLoading(true);
    // Add admin to server
    axiosCompanies.addAdmin(
      {
        companyId: props.currentUser.currentCompanyId,
        uid: userToMakeAdmin.uid,
      },
      () => {
        setSubmitLoading(false);

        // Return to Employee Page
        navigate(-1);
      },
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          buttons={[
            {
              color: "green",
              disabled: !userToMakeAdmin,
              label: "Make Admin",
              loading: submitLoading,
              onClick: () => {
                setDisplayConfirmationModal(true);
              },
            },
            {
              color: "blue",
              enabled: true,
              label: "Back",
              onClick: () => navigate(-1),
            },
          ]}
          title="Manage Admins"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <div className="w-full">
              {employees.map((employee) =>
                "employee" ===
                employee.companies[props.currentUser.currentCompanyId].role ? (
                  <EmployeeCard
                    employee={employee}
                    showCheckMark={employee.uid === userToMakeAdmin?.uid}
                    key={employee._id}
                    onClick={() => {
                      setUserToMakeAdmin(employee);
                    }}
                  />
                ) : null,
              )}
              {employees.filter(
                (e) =>
                  e.companies[props.currentUser.currentCompanyId].role !==
                  "admin",
              ).length === 0 ? (
                <div className="text-xl text-snow-primary">
                  There are currently no employees to add as admins...
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <MultipleChoiceModal
        open={displayConfirmationModal}
        options={[
          {
            color: "green",
            label: "Make Admin",
            onClick: () => {
              setDisplayConfirmationModal(false);
              handleMakeAdmin();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmationModal(false),
          },
        ]}
        subtitle={`Are you sure you want to make ${
          userToMakeAdmin?.firstName + " " + userToMakeAdmin?.lastName
        } an admin?`}
        title="Add Admin"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminManageAdminsPage);
