import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function StandardButton(props) {
  return (
    <div
      className={`px-6 py-2.5 rounded-xl font-bold text-sm cursor-pointer ${
        props.color === "red"
          ? "bg-snow-red"
          : props.color === "green"
            ? "bg-snow-green"
            : "bg-snow-blue-3"
      }
            ${
              props.disabled || props.loading
                ? "opacity-60"
                : "hover:opacity-80 active:opacity-40"
            }`}
      onClick={!props.disabled ? props.onClick : props.disabledClick}
    >
      {props.loading ? (
        <div className="w-full flex justify-center">
          <PulseLoader
            color={props.loaderColor || "#fffafa"}
            size={props.loaderSize || 5}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="font-bold text-sm text-snow-white text-center">
          {props.label}
        </div>
      )}
    </div>
  );
}

export default StandardButton;
