import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosUsers from "../../services/axios/users";
import firebaseAuth from "../../services/firebase/firebaseAuth";

const AccountSetupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableButton, setDisableButton] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [user, loading] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (loading) return;

    if (!user) {
      navigate("/get-started");
    } else {
      axiosUsers.getUserById({ uid: user.uid }, (userData) => {
        // User doesn't exist on DB yet
        if (!userData) return;

        // Check if user still needs to create first company or not
        if (Object.keys(userData.companies).length > 0) {
          navigate("/admin");
        } else if (userData) {
          navigate("/checkout/products");
        }
      });
    }
  }, [user, loading, navigate]);

  const handleCreateUser = async () => {
    setDisableButton(true);

    firebaseAuth.getUser(
      async (fbUser) => {
        if (!fbUser) {
          // TODO: Handle no fbUser
        }
        const newUser = {
          firstName,
          lastName,
          phoneNumber: fbUser.phoneNumber,
          startDate: new Date().toLocaleDateString(),
          uid: fbUser.uid,
        };

        await axiosUsers.createNewUser(
          newUser,
          (userData) => {
            if (!userData.successfullyAdded) {
              console.log(userData.message);
              alert("Failed to create user - please try again.");
              setDisableButton(false);
              // TODO: Handle user not successfully added (do more?)
            }

            // Update user in redux
            dispatch(updateUser(userData.user));
            setDisableButton(false);

            // Navigate to
            navigate("/checkout/products");
          },
          (err) => {
            alert("Failed to create user - please try again.");
            console.log(err);
            setDisableButton(false);
          },
        );
      },
      (err) => {
        console.log(err);
        setDisableButton(false);
      },
    );
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mx-auto mt-12 md:mt-32 text-2xl font-light font-snow-blue-3 max-w-5xl px-4">
        Next, tell us a little bit about yourself. Just your first and last name
        will do.
      </div>
      <div className="mt-11 w-full max-w-sm">
        <div className="mb-4 text-center font-bold text-2xl text-snow-blue-3">
          Account Setup
        </div>
        <AuthLabeledTextInput
          disabled={false}
          // inputMode='numeric'
          label="First Name"
          maxLength={30}
          placeholder="John"
          setValue={setFirstName}
          value={firstName}
        />
        <AuthLabeledTextInput
          disabled={false}
          // inputMode='numeric'
          label="Last Name"
          maxLength={20}
          placeholder="Doe"
          setValue={setLastName}
          value={lastName}
        />
        <div className="w-full">
          <RoundButton
            label={"Next"}
            color="blue"
            disabled={
              disableButton || firstName.length < 1 || lastName.length < 1
            }
            onClick={handleCreateUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSetupPage;
