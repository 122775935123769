import React from "react";
import { connect } from "react-redux";

// Resources
import ArrowDownBlueIcon from "../../resources/arrow-down-blue.svg";
import ArrowUpBlueIcon from "../../resources/arrow-up-blue.svg";

const ReorderCardWrapper = (props) => {
  return (
    <div className="w-full flex flex-row">
      <div className="flex-1">{props.children}</div>
      {props.isActive !== false ? (
        <div className="flex ml-2 flex-col justify-center mb-4">
          <div
            className="my-2 hover:opacity-60 active:opacity-40"
            onClick={props.upPressed}
          >
            <img src={ArrowUpBlueIcon} alt="up-arrow" />
          </div>
          <div
            className="my-1 hover:opacity-60 active:opacity-40"
            onClick={props.downPressed}
          >
            <img src={ArrowDownBlueIcon} alt="down-arrow" />
          </div>
        </div>
      ) : (
        <div className="w-10" />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { metadata } = state;
  return { metadata };
};

export default connect(mapStateToProps)(ReorderCardWrapper);
