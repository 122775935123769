import React from "react";
import FlagRed from "../../resources/flag-red.svg";

const JobHistoryCard = (props) => {
  return (
    <div
      className="flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40"
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl font-bold font-snow-primary">
          {props.name || "No Property Name Given"}
        </div>
        <div className="font-light text-base mb-2">
          {props.address.streetLineOne +
            ", " +
            (props.address.streetLineTwo
              ? props.address.streetLineTwo + ", "
              : "") +
            props.address.city +
            ", " +
            props.address.state +
            " " +
            props.address.zip}
        </div>
        <div className="flex flex-row">
          <div className="font-bold">End Time:</div>
          <div className="text-base ml-2">{props.endTime}</div>
        </div>
        <div className="flex flex-row">
          <div className="font-bold">Operator:</div>
          <div className="text-base ml-3">
            {props.completedBy.firstName + " " + props.completedBy.lastName}
          </div>
        </div>
      </div>
      {props.showFlag ? (
        <div className="flex w-16 justify-end items-center">
          <img className="w-7" src={FlagRed} alt="trash" />
        </div>
      ) : null}
    </div>
  );
};

export default JobHistoryCard;
