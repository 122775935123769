import React from "react";
import { connect } from "react-redux";

/**
 *
 * @param props
 */
function FlashMessage(props) {
  return (
    <>
      {typeof props.flashMessage === "string" &&
      props.flashMessage.length > 0 ? (
        <div
          className={`fixed bottom-0 h-10 w-full z-50 ${props.flashType === "error" ? "bg-snow-red" : "bg-landscape-green"} text-full-white text-center text-lg pt-1`}
        >
          {props.flashMessage}
        </div>
      ) : null}
    </>
  );
}

// export default FlashMessage;
export default connect((state) => {
  console.log(state);
  return {
    flashMessage: state.metadata.flashMessage,
    flashType: state.metadata.flashType,
  };
})(FlashMessage);
