import React, { useCallback, useState } from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";

const UnsubscribeReasonModal = ({
  closeModal,
  open,
  adjustForSidebar = false,
  onUnsubscribe,
}) => {
  const [message, setMessage] = useState("");

  const handleUnsubscribe = useCallback(() => {
    onUnsubscribe && onUnsubscribe(message);
    closeModal();
  }, [onUnsubscribe, message, closeModal]);

  if (!open) return null;

  return (
    <ModalWrapper clickOff={closeModal}>
      <div
        className={`flex flex-col w-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 ${adjustForSidebar ? "ml-60" : null}`}
      >
        <div className="flex flex-col flex-1">
          <div className="text-2xl font-bold font-snow-primary mb-4">
            Cancel Subscription
          </div>
          <div className="text-base font-light mb-4">
            Before you go, please tell us why you are canceling this
            subscription. This will help us improve our service.
          </div>
          <textarea
            className="border-2 border-snow-light-grey rounded-md p-2 h-24 mb-8"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="mb-12">
          <div className="mb-2">
            <RoundButton
              color="red"
              label="Yes, cancel my subscription"
              onClick={handleUnsubscribe}
            />
          </div>
          <div className="">
            <RoundButton
              color="blue"
              label="No, I've changed my mind"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UnsubscribeReasonModal;
