import React, { useState } from "react";

// Resources
import CreateServiceModal from "../Modals/CreateServiceModal";
import ServiceFieldItem from "./ServiceFieldItem";
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";

const ServicesField = (props) => {
  const [displayCreateServiceModal, setDisplayCreateServiceModal] =
    useState(false);

  const handleSetServices = (service, key) => {
    if (props.readOnly) return;

    const updatedServices = [...props.services];
    updatedServices[key] = { ...service, checked: !service.checked };

    props.setServices(updatedServices);
  };

  const handleSetChildService = (parentKey, childKey, newValue) => {
    if (props.readOnly) return;
    const updatedServices = [...props.services];
    updatedServices[parentKey].options[childKey].checked = newValue;
    props.setServices(updatedServices);
  };

  const handleUpdateTextInput = (parentKey, childKey, newValue) => {
    let updatedServices = [...props.services];
    updatedServices[parentKey].textInput[childKey].value = newValue;
    props.setServices(updatedServices);
  };

  return (
    <div className="w-full mb-5">
      <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
        {props.label}
      </div>
      <div className="text-sm font-light mb-1 self-start text-snow-primary">
        {props.subtitle ||
          "Select what services you will provide to this property"}
      </div>
      {props.enableSelectAll ? (
        <div
          className="text-sm font-light underline self-start text-snow-primary hover:opacity-60 active:opacity-40 w-fit cursor-pointer mt-1"
          onClick={() => {
            const updatedServices = props.services.map((s) => {
              return { ...s, checked: true };
            });

            props.setServices(updatedServices);
          }}
        >
          (Select All)
        </div>
      ) : null}
      {props.services.map((service, key) => (
        <>
          <ServiceFieldItem
            key={key}
            index={key}
            service={service}
            onClick={() => handleSetServices(service, key)}
          />
          {props.showSubOptions && service.checked ? (
            <div style={{ marginLeft: 20 }}>
              {service?.options
                ? service.options.map((opt, childKey) => (
                    <ServiceFieldItem
                      key={childKey}
                      service={opt}
                      onClick={() => {
                        handleSetChildService(key, childKey, !opt.checked);
                      }}
                    />
                  ))
                : null}
              {service.textInput?.map((t, childKey) =>
                t.value === "" ? null : (
                  <LabeledTextboxInput
                    key={t.label}
                    disabled={false}
                    label={t.label}
                    multiline={false}
                    value={t.value}
                    setValue={(value) => {
                      handleUpdateTextInput(key, childKey, value);
                    }}
                  />
                ),
              )}
            </div>
          ) : null}
        </>
      ))}

      {props.enableAddNew ? (
        <div
          className="w-fit px-6 py-2.5 rounded-xl font-bold text-xs bg-snow-primary text-snow-white mb-2"
          onClick={() =>
            setDisplayCreateServiceModal(!displayCreateServiceModal)
          }
        >
          + Create New Service
        </div>
      ) : null}

      <CreateServiceModal
        adjustForSidebar
        onClose={() => setDisplayCreateServiceModal(false)}
        onCreateService={(newService) => {
          props.setServices([...props.services, newService]);
          setDisplayCreateServiceModal(false);
        }}
        open={props.enableAddNew && displayCreateServiceModal}
      />
    </div>
  );
};

export default ServicesField;
