import axios from "axios";
import errors from "../errors";
const axiosPushNotifications = {
  broadcastPushNotificationToAll: async (data) => {
    try {
      const res = await axios.post(
        `pushNotification/broadcast-to-all-users`,
        data,
      );

      return res.data;
    } catch (err) {
      errors.report(err);
    }
  },
};

export default axiosPushNotifications;
