import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import FilterBar from "../../../components/Filters/FilterBar";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Redux
import { updateUser } from "../../../store/reducers/currentUserSlice";
import { openManageSubscriptionModal } from "../../../store/reducers/metadataSlice";

// Services
import DeactivateConfirmModal from "../../../components/Modals/DeactivateConfirmModal";
import axiosCompanies from "../../../services/axios/companies";
import axiosUsers from "../../../services/axios/users";

const AdminUpdateCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState("");

  let { companyId } = useParams();

  const [company, setCompany] = useState({});
  const [displayConfirmAddLicenseModal, setDisplayConfirmAddLicenseModal] =
    useState(false);
  const [
    displayConfirmRemoveLicenseModal,
    setDisplayConfirmRemoveLicenseModal,
  ] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(() => {
    setLoading(true);
    axiosCompanies.getCompanyById({ companyId }, (data) => {
      setCompany(data);
      setLoading(false);
    });
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [companyId, fetchData]);

  useEffect(() => {
    if (company?.temperature === "C") {
      setCurrentFilter("C");
    } else setCurrentFilter("F");
  }, [company]);

  const handleSaveCompany = useCallback(() => {
    setLoading(true);
    company.temperature = currentFilter;

    // Submit updated job to server
    axiosCompanies.updateCompanyById(
      { ...company, companyId: company._id },
      () => {
        // Update user to update company name
        axiosUsers.getUserById({ uid: props.currentUser.uid }, (userData) => {
          dispatch(updateUser(userData));
          setLoading(false);
          navigate(-1);
        });
      },
    );
  }, [company, currentFilter, dispatch, navigate, props.currentUser.uid]);

  const handleAddCompanyLicense = useCallback(() => {
    if (!company) return;
    axiosCompanies.addCompanyLicense(
      { companyId: company._id, uid: props.currentUser.uid },
      () => {
        setDisplayConfirmAddLicenseModal(false);
        alert("Successfully Activated " + company.name);
        // Refresh data
        fetchData();
      },
      (err) => {
        setDisplayConfirmAddLicenseModal(false);
        alert(err.data.message);
        console.error("Error in handleLicenseCompany: ", err);
        // If error caused by user unsubscribed or undersubscribed, display upgrade modal
        if (err.data.needsUpgrade) {
          dispatch(openManageSubscriptionModal());
        }
      },
    );
  }, [company, dispatch, fetchData, props.currentUser.uid]);

  const handleRemoveCompanyLicense = useCallback(async () => {
    if (!company) return;

    axiosCompanies.removeCompanyLicense(
      { companyId: company._id, uid: props.currentUser.uid },
      () => {
        setDisplayConfirmRemoveLicenseModal(false);
        alert("Successfully Deactivated " + company.name);
        // Refresh data
        fetchData();
      },
      (err) => {
        setDisplayConfirmRemoveLicenseModal(false);
        alert(err.message ?? err.data.message);
        console.error("Error in handleLicenseCompany: ", err);
        alert(err);
      },
    );
  }, [company, fetchData, props.currentUser.uid]);

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          dropdown={{
            enabled: true,
            options: !company.isPaid
              ? [
                  {
                    color: "green",
                    label: "Activate",
                    operation: () => setDisplayConfirmAddLicenseModal(true),
                  },
                ]
              : [
                  {
                    color: "red",
                    label: "Deactivate",
                    operation: () => setDisplayConfirmRemoveLicenseModal(true),
                  },
                ],
          }}
          title="Update Company"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <div className="flex flex-row w-full justify-between mb-10">
              <div
                className={`text-lg border-[1px] py-1 px-3 rounded-md font-bold ${
                  company.isPaid ? "text-snow-green" : "text-snow-red"
                }`}
              >
                Status: {company.isPaid ? "Active" : "Inactive"}
              </div>
              <StandardButton
                color="green"
                label="Save"
                onClick={handleSaveCompany}
              />
            </div>
            <LabeledTextboxInput
              label="Company Name"
              maxLength={50}
              placeholder="Name your company..."
              setValue={(val) => {
                setCompany({ ...company, name: val });
              }}
              value={company.name}
            />
            <LabeledTextboxInput
              disabled={true}
              label="Join Code"
              placeholder="Join code..."
              // setValue={(val) => {
              //     setCompany({ ...company, name: val });
              // }}
              value={company.joinCode}
            />
            <LabeledTextboxInput
              disabled={true}
              label="My Role"
              placeholder="Join code..."
              value={props.currentUser.companies[company._id].role}
            />
            <LabeledTextboxInput
              disabled={true}
              label="Company Owner"
              value={company.owner.firstName + " " + company.owner.lastName}
            />
            <LabeledTextboxInput
              label="Company Name for Email Reports"
              description="This name will appear in the sender and subject line of email reports sent to clients."
              value={company.emailReportName ?? company.name}
              setValue={(val) => {
                setCompany({ ...company, emailReportName: val });
              }}
            />
            <LabeledTextboxInput
              label="Reply To Address for Email Reports"
              description="If a client replies to a report email, this is the email address that will get the response."
              value={company.emailReportReplyTo ?? ""}
              setValue={(val) => {
                setCompany({ ...company, emailReportReplyTo: val });
              }}
            />
            <FilterBar
              currentFilter={currentFilter}
              filters={[
                { label: "Fahrenheit", value: "F" },
                { label: "Celsius", value: "C" },
              ]}
              setCurrentFilter={setCurrentFilter}
            />
          </div>
        )}
        <div className="mb-12">
          <StandardButton
            color="red"
            label="Deactivate"
            onClick={() => setDisplayConfirmRemoveLicenseModal(true)}
          />
        </div>
      </div>
      {/* Add License To Company Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayConfirmAddLicenseModal}
        options={[
          {
            color: "green",
            label: "Activate",
            onClick: handleAddCompanyLicense,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmAddLicenseModal(false),
          },
        ]}
        subtitle="Are you sure you want to activate this company?"
        title="Activate Company"
      />

      {/* Remove License To Company Modal */}
      <DeactivateConfirmModal
        adjustForSidebar={true}
        open={displayConfirmRemoveLicenseModal}
        options={[
          {
            color: "red",
            label: "Deactivate",
            onClick: handleRemoveCompanyLicense,
          },
          {
            color: "blue",
            label: "I've changed my mind",
            onClick: () => setDisplayConfirmRemoveLicenseModal(false),
          },
        ]}
        title="Deactivate Company"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminUpdateCompanyPage);
