import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Pages
import ProductsPage from "./ProductsPage";

// Services
import axiosUsers from "../../services/axios/users";

const BillingWrapper = (props) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [customReturnUrl, setCustomReturnUrl] = useState(null);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
      setCustomReturnUrl(query.get("custom_redirect"));
    }

    if (query.get("canceled")) {
      setSuccess(false);

      const customRedirect = query.get("custom_redirect");

      if (customRedirect) {
        window.location.href = customRedirect;
      }
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, [sessionId]);

  const handleSuccessfulSubscribe = () => {
    if (!sessionId) return;

    if (customReturnUrl) {
      // If navigating to the app, handle setStripeCustomerIdFromSessionId inside app (authorization)
      const url = new URL(
        customReturnUrl + "?success=true&sessionId=" + sessionId,
      );

      // Return user to app with custom url
      window.location.href = url;
    } else {
      axiosUsers.setStripeCustomerIdFromSessionId(
        {
          uid: props.currentUser.uid,
          sessionId,
        },
        (data) => {
          if (!data.success) {
            throw new Error(
              `Syncing stripe customer with user unsuccessful:  ${data.message}`,
            );
          }

          navigate("/create-first-company");
        },
        (err) => {
          alert("Error in setting user.stripe.customerId " + err);
        },
      );
    }
  };

  if (!success && message === "") {
    return <ProductsPage />;
  } else if (success && sessionId !== "") {
    setTimeout(() => {
      handleSuccessfulSubscribe();
    }, 500);

    return <ProductsPage />;
  } else {
    return <ProductsPage />;
  }
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(BillingWrapper);
