import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="mt-24 flex flex-col h-96 justify-around text-center">
      <h1 className="text-3xl bold">Oops!</h1>
      <p className="text-base bold">Sorry, an unexpected error has occurred.</p>
      <p className="text-base bold">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
