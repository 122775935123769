import React, { useState } from "react";
import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import RoundButton from "../../../components/Buttons/RoundButton";
import NotificationModal from "../../../components/Modals/NotificationModal";
// Services
import axiosJobs from "../../../services/axios/jobs";
import firebaseAuth from "../../../services/firebase/firebaseAuth";
import errors from "../../../services/errors";

const AdminBulkImportJobs = (props) => {
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(false);
  const [file, setFile] = useState();
  const [displayNotificationModal, setDisplayNotificationModal] =
    useState(false);
  const [textNotificationModal, setTextNotificationModal] = useState("");
  const [buttonColor, setButtonColor] = useState("blue");
  const [created, setCreated] = useState(false);

  let companyId = props.currentUser.currentCompanyId;

  // Helper function to create and append an input element
  const createInput = (name, value, type) => {
    const input = document.createElement("input");
    input.type = type || "text";
    input.name = name;
    input.value = value;
    return input;
  };

  const downloadTemplate = async () => {
    firebaseAuth.getUser(async () => {
      // Create a hidden form element
      const form = document.createElement("form");
      form.style.display = "none"; // Hide the form

      // Collects a fresh token
      const token = await getIdToken(getAuth().currentUser);

      // Set form attributes
      form.method = "POST";
      form.action = axios.defaults.baseURL + "/job/excel-template";
      form.target = "_blank"; // Open response in a new tab

      // Append form inputs to the form
      form.appendChild(createInput("companyId", companyId));
      form.appendChild(createInput("authToken", token, "hidden"));

      // Append the form to the document body
      document.body.appendChild(form);

      // Submit the form
      form.submit();
    });
  };

  const handleFileChange = async (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  // On file upload (click the upload button)
  const handleUploadClick = async () => {
    if (!file) {
      return;
    }
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("data", file);
    formData.append("companyId", companyId);

    // send data to backend
    setPageLoading(true);
    try {
      const data = await axiosJobs.bulkUploadExcel(formData);
      setCreated(true);
      setButtonColor("blue");
      setTextNotificationModal(
        `Your file was processed and properties created. \n Processed ${data?.recordsImported}/${data?.total} rows (${data?.failed} failed). \n Press the button and fill out the remaining details`,
      );
      setDisplayNotificationModal(true);
    } catch (err) {
      setTextNotificationModal(err.response.data.message);
      setButtonColor("red");
      errors.report(err);
      setDisplayNotificationModal(true);
    }
    setPageLoading(false);
  };

  return (
    <>
      <div className="flex flex-col">
        <HeaderBar title="Bulk Import Properties" />
        {pageLoading ? (
          <LoadingIcon />
        ) : (
          <div>
            <div className="flex flex-1 flex-col w-full p-10 pb-40 items-center overflow-y-scroll">
              <div className="flex flex-1 flex-col w-full mb-10">
                <p className="mb-10">
                  This tool allows you to bulk upload properties via and excel
                  document. <br />
                  You can either generate this document from QuickBooks or use
                  the template bellow.
                </p>

                <div className="text-2xl font-medium text-snow-primary mb-4">
                  Instructions for exporting from QuickBooks
                </div>

                <p className="mb-6">
                  Step 1: Log into your QuickBooks account <br />
                  Step 2: Click on the customer tab on the left side of the
                  screen <br />
                  Step 3: In the middle of the page on the right side of screen
                  above the word "action" is a symbol - click on the middle
                  symbol (a square with an arrow in it) <br />
                  Step 4: QuickBooks will export the customer list into an Excel
                  sheet.
                  <br />
                  Step 5: Upload the QuickBooks Excel file using the button
                  below.
                </p>

                <div className="text-2xl font-medium text-snow-primary mb-4">
                  Instructions for Using the Template
                </div>

                <p className="mb-6 ">
                  Step 1: Download the template. <br />
                  Step 2: Delete the 3 rows of example data and replace them
                  with your company data (but keep the column headers in the
                  first row). <br />
                  Step 3: Please indicate if the customer is for the "snow" or
                  "landscape" season using the type column. Using exact spelling
                  enter: 'snow', 'landscape' or 'both' to set property usage.
                  <br />
                  Step 4: After filling in the data, upload the file with the
                  button below.
                </p>

                <div>
                  <button
                    className="text-1xl font-medium text-snow-primary my-2 underline underline-offset-2"
                    onClick={downloadTemplate}
                  >
                    Download Template
                  </button>
                </div>

                <h1 className="text-2xl font-medium text-snow-primary my-1">
                  Upload File
                </h1>
                <div>
                  <input
                    className="text-snow-primary my-4"
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>
                {file ? (
                  <div>
                    <a className="text-1xl  text-snow-primary my-4 ">
                      upload file - should be xls / xlsx only
                    </a>
                    <div className=" max-w-md my-4">
                      <RoundButton
                        label="Upload Excel File"
                        color="blue"
                        onClick={handleUploadClick}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <NotificationModal
        button={{
          color: buttonColor,
          label: "Okay",
          onClick: () => {
            // Close and go back
            setDisplayNotificationModal(false);
            if (created === true) {
              navigate("/admin/property/");
            }
          },
        }}
        open={displayNotificationModal}
        subtitle={textNotificationModal}
        title="Information"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminBulkImportJobs);
