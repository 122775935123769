import axios from "axios";

const axiosContact = {
  sendContactUsEmail: async (data) => {
    try {
      const res = await axios.post(`contact/`, data);

      return res.data;
    } catch (err) {
      console.error(err);
    }
  },
};

export default axiosContact;
