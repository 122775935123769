/*eslint no-unused-vars: "off"*/
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// React Date Picker CSS
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Run config functions (just import)
import axiosConfig from "./config/axios";

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

// Authentication
import AuthenticationManager from "./AuthenticationManager";

// Redux
import store from "./store";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import stripeConfig from "./config/stripe";
import { Elements } from "@stripe/react-stripe-js";
import flash from "./services/flash";
const stripePromise = loadStripe(stripeConfig.publicKey);

// Honeybadger:
const honeybadger = Honeybadger.configure({
  apiKey: "hbp_J85WpZnMMcBPNjehAvMnDBtlq4jv6Z0YcTqS",
  environment: process.env.REACT_APP_ENVIRONMENT ?? "development",
});
flash.clear();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <HoneybadgerErrorBoundary honeybadger={honeybadger}>
          <AuthenticationManager />
        </HoneybadgerErrorBoundary>
      </Elements>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
