import { createSlice } from "@reduxjs/toolkit";

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    uid: null,
  },
  reducers: {
    // signIn(state, action) {
    //     state.uid = action.payload.uid;
    // },
    updateUser(state, action) {
      // Get values from payload or previous state
      const admin = action.payload.admin || state.admin;
      const companies = action.payload.companies || state.companies;
      const currentCompanyId =
        action.payload.currentCompanyId || state.currentCompanyId;
      const email = action.payload.email || state.email;
      const firstName = action.payload.firstName || state.firstName;
      const isPaid = action.payload.isPaid || state.isPaid;
      const isPaidUpdatedAt =
        action.payload.isPaidUpdatedAt || state.isPaidUpdatedAt;
      const lastName = action.payload.lastName || state.lastName;
      const permissions = action.payload.permissions || state.permissions;
      const phoneNumber = action.payload.phoneNumber || state.phoneNumber;
      const startDate = action.payload.startDate || state.startDate;
      const uid = action.payload.uid || state.uid;

      // Reset Values
      state.admin = admin;
      state.companies = companies;
      state.currentCompanyId = currentCompanyId;
      state.email = email;
      state.firstName = firstName;
      state.isPaid = isPaid;
      state.isPaidUpdatedAt = isPaidUpdatedAt;
      state.lastName = lastName;
      state.permissions = permissions;
      state.phoneNumber = phoneNumber;
      state.startDate = startDate;
      state.uid = uid;
    },
  },
});

export const { updateUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
