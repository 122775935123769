import React from "react";
import CheckBox from "../CheckBoxes/CheckBox";

const ServiceFieldItem = (props) => {
  return (
    <div className="">
      <CheckBox
        checked={props.service.checked}
        label={props.service.label}
        labelMargin={true}
        onClick={props.onClick}
        season={props.season}
      />
    </div>
  );
};

export default ServiceFieldItem;
