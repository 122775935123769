import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

// Components
import RoundButton from "../Buttons/RoundButton";
import StandardButton from "../Buttons/StandardButton";
import SubscriptionPlanCard from "../Cards/SubscriptionPlanCard";
import ModalWrapper from "./ModalWrapper";

// Redux
import { closeManageSubscriptionModal } from "../../store/reducers/metadataSlice";

// Services
import axiosCompany from "../../services/axios/companies";
import axiosContact from "../../services/axios/contact";
import axiosStripe from "../../services/axios/stripe";
import axiosUsers from "../../services/axios/users";
import AddUsersAndCompaniesCard from "../Cards/AddUsersAndCompaniesCard";

// TODO: Setup getting current subscription type from user.stripe.subscriptionType

const ALL_PLANS = [
  {
    details: ["1 Company", "1 User"],
    lookup_key: "hansolo-free-sub",
    name: "Han Solo",
    price: "$0/mo",
  },
  {
    details: ["1 Company", "20 Users"],
    lookup_key: "team-sub",
    name: "Team",
    price: "$89/mo",
  },
  {
    details: ["2 Companies/Subcontractors", "100 Users"],
    lookup_key: "squad-sub",
    name: "Squad",
    price: "$179/mo",
  },
  {
    details: ["5 Companies/Subcontractors", "300 Users"],
    lookup_key: "fleet-sub",
    name: "Fleet",
    price: "$269/mo",
  },
];

const ManageSubscriptionModal = (props) => {
  const dispatch = useDispatch();

  const [availablePlans] = useState(ALL_PLANS);
  const [chosenPlan, setChosenPlan] = useState(null);
  const [changePlanButtonLoading, setChangePlanButtonLoading] = useState(false);

  useEffect(() => {
    setChosenPlan(null);
    setChangePlanButtonLoading(false);
  }, [props.open]);

  const handleChoosePlan = (choice) => {
    setChosenPlan(choice);
  };

  const handleChangePlan = () => {
    setChangePlanButtonLoading(true);

    // Check if user is already stripe customer
    axiosUsers.getUserIsCustomer({ uid: props.currentUser.uid }, (data) => {
      if (data.isCustomer) {
        // If yes, change plan
        handleUpgradeSubscription();
      } else {
        // If no, direct to checkout session
        handleCreateUpgradeSubscriptionSession();
      }
    });
  };

  const handleUpgradeSubscription = () => {
    axiosStripe.updateSubscription(
      { uid: props.currentUser.uid, plan: chosenPlan },
      () => {
        alert("Great job! Successfully changed subscription!");
        setChangePlanButtonLoading(false);

        // Refresh page to refresh data
        window.location.reload();
        props.onClose();
      },
      (err) => {
        console.error("Handle error on updateSubscription: ", err);
      },
    );
  };

  const handleCreateUpgradeSubscriptionSession = () => {
    axiosStripe.purchaseUpgrade(
      {
        uid: props.currentUser.uid,
        products: [chosenPlan],
      },
      (data) => {
        dispatch(closeManageSubscriptionModal());
        // Redirect to checkout page
        window.location.href = data.sessionUrl;
      },
    );
  };

  const handleAddUsersAndCompanies = async ({
    additionalUsers,
    additionalCompanies,
  }) => {
    if (additionalUsers === 0 && additionalCompanies === 0) {
      return;
    }

    const totalUserCost = additionalUsers * 11.99;
    const totalCompanyCost = additionalCompanies * 49.99;
    const totalCost = totalUserCost + totalCompanyCost;

    const confirmation = confirm(`Cost: $${totalCost.toFixed(2)}/mo. Proceed?`);

    // Check if user is owner of company
    let company;
    await axiosCompany.getCompanyById(
      {
        companyId: props.currentUser.currentCompanyId,
      },
      (data) => {
        company = data;
      },
    );
    if (company.ownerUid !== props.currentUser.uid) {
      alert(
        "Only the owner of this company can make this change. Please contact the company owner and ask them to make the change.",
      );
      return;
    }

    if (!company) {
      alert("Error fetching company data. Please try again.");
      return;
    }

    // Get user account information
    let accountInfo;
    await axiosUsers.getUserAccountInformationById(
      {
        uid: props.currentUser.uid,
      },
      (data) => {
        accountInfo = data;
      },
    );

    const message = `
      Hi SnowScape Team,

      I would like to add the following to my subscription:

      Additional Users: ${additionalUsers}
      Additional Companies: ${additionalCompanies}

      Total Cost: $${totalCost.toFixed(2)}/mo

      You can find my Stripe customer here: https://dashboard.stripe.com/customers/${accountInfo.billingInformation.customerId}

      Please process this request within 48 hours and reflect it in my next billing cycle.

      Thank you!
    `;

    if (confirmation) {
      await axiosContact.sendContactUsEmail({
        email: "info@mysnowscape.com",
        name: `${company.name} - ${props.currentUser.firstName} ${props.currentUser.lastName}`,
        message,
        type: "contact-us",
      });

      alert("Request sent! We will process your request within 48 hours.");
    }
  };

  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.onClose}>
          <div
            className={`flex flex-col mx-4 sm:mx-0 sm:w-2/3 h-full rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll ${
              props.adjustForSidebar ? "md:ml-60" : null
            }`}
          >
            <div className="flex flex-col md:flex-row md:justify-between w-full">
              <div className="flex md:hidden h-10  flex-row justify-end">
                {chosenPlan ? (
                  <div className="mr-4">
                    <StandardButton
                      color="blue"
                      label="Back"
                      onClick={() => {
                        setChosenPlan(null);
                      }}
                    />
                  </div>
                ) : null}
                <StandardButton
                  color="red"
                  label="Cancel"
                  onClick={props.onClose}
                />
              </div>
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "SnowScape Billing Plans"}
              </div>
              <div className="h-10 hidden md:flex flex-row justify-end">
                {chosenPlan ? (
                  <div className="mr-4">
                    <StandardButton
                      color="blue"
                      label="Back"
                      onClick={() => {
                        setChosenPlan(null);
                      }}
                    />
                  </div>
                ) : null}
                <StandardButton
                  color="red"
                  label="Cancel"
                  onClick={props.onClose}
                />
              </div>
            </div>
            <div className="py-4">
              {!chosenPlan ? (
                <>
                  <ChoosePlanDisplay
                    availablePlans={availablePlans}
                    handleChoosePlan={handleChoosePlan}
                  />
                  <AddUsersAndCompaniesCard
                    onButtonClick={handleAddUsersAndCompanies}
                  />
                </>
              ) : (
                <ConfirmSelectionDisplay
                  changePlanButtonLoading={changePlanButtonLoading}
                  chosenPlan={chosenPlan}
                  handleChangePlan={handleChangePlan}
                />
              )}
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

const ChoosePlanDisplay = (props) => {
  return (
    <div className="flex flex-col w-full px-1 mt-4 overflow-y-scroll">
      {props.availablePlans.map((plan, key) => (
        <SubscriptionPlanCard
          handleChoosePlan={props.handleChoosePlan}
          plan={plan}
          key={key}
        />
      ))}
    </div>
  );
};

const ConfirmSelectionDisplay = (props) => {
  // const [confirmationInput, setConfirmationInput] = useState('');
  // const [disableButton, setDisableButton] = useState(true);

  // useEffect(() => {
  //     // Enable/disable button based on correct input
  //     if (confirmationInput === 'SUBSCRIBE') {
  //         setDisableButton(false);
  //     } else {
  //         setDisableButton(true);
  //     }
  // }, [confirmationInput]);

  return (
    <div>
      <SubscriptionPlanCard highlight={true} plan={props.chosenPlan} />

      {/* Confirmation Input */}
      <div className="mt-40">
        {/* <LabeledTextboxInput
                    label='Please type SUBSCRIBE (all capitals) to confirm plan change.'
                    placeholder='SUBSCRIBE'
                    setValue={setConfirmationInput}
                    value={confirmationInput}
                /> */}
        <RoundButton
          color="green"
          disabled={false}
          label="Change Plan"
          loading={props.changePlanButtonLoading}
          onClick={props.handleChangePlan}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser, metadata } = state;
  return { currentUser, metadata };
};

export default connect(mapStateToProps)(ManageSubscriptionModal);
