import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

// Component
import AuthComponent from "../../components/Auth/AuthComponent";

// Services
import firebaseAuth from "../../services/firebase/firebaseAuth";

const AdminLoginPage = () => {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/admin");
  }, [user, loading, navigate]);

  return (
    <div className="mt-40 px-4 md:px-20">
      <AuthComponent
        subtext="Don't have an account? Sign Up."
        subtextUrl="/get-started"
        title="Log in to SnowScape"
      />
    </div>
  );
};

export default AdminLoginPage;
