import axios from "axios";
import errors from "../errors";
const noop = () => {};

const axiosJobHistory = {
  createNewJobHistory: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("job/history/add", data);
      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  updateJobHistory: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.put("job/history/" + data._id, data);
      onSuccess(res.data);
      return res.data;
    } catch (error) {
      const backendMessage = error.response?.data?.message ?? "";
      error.message = `${backendMessage} (${error.message})`;
      errors.report(error);
      onFail(error);
    }
  },
  deleteJobHistory: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.delete(
        "job/history/" + data.jobId + "/delete",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllJobHistoryV2: async (companyId, searchPhrase, filter, take, skip, abortSignal) => {
    const res = await axios.post(`job/history/company/${companyId}/v2?take=${take}&skip=${skip}`, {
      searchPhrase,
      filter,
    }, {
      signal: abortSignal,
    });
    return res.data;
  },

  getAllJobHistory: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "job/history/company/" + data.companyId + "?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getJobHistoryById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("job/history/" + data._id);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getJobHistoryBySearchPhrase: async (
    data,
    onSuccess = noop,
    onFail = noop,
  ) => {
    try {
      const res = await axios.post("job/history/search", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  resendReportEmail: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("job/history/report/resend", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
};

export default axiosJobHistory;
