import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import LandingFeaturesCard from "../../components/Cards/LandingFeaturesCard";
import LandingVideoPlayer from "../../components/Video/LandingVideoPlayer";

// Resources
import AppleAppStoreIcon from "../../resources/app-store/apple-app-store-logo.svg";
import GooglePlayStoreIcon from "../../resources/app-store/google-play-store-logo.svg";
import CalendarBlueIcon from "../../resources/calendar-blue.svg";
import ClockBlueIcon from "../../resources/clock-blue.svg";
import SnowScapeLogoWithBottomText from "../../resources/logo/snowscape-logo-with-bottom-text.svg";
import ShieldBlueIcon from "../../resources/shield-check-blue.svg";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col overflow-scroll">
      {/* White - Snowscape */}
      <div className="flex flex-col w-full items-center px-4">
        <div className="m-8 md:mt-14 w-100">
          <img
            alt="snowscape-logo"
            className="w-full h-full"
            src={SnowScapeLogoWithBottomText}
          />
        </div>
        <div className="my-4">
          <RoundButton
            label="Get Started"
            color="blue"
            onClick={() => {
              navigate("/get-started");
            }}
          />
        </div>
        <div className="flex flex-row w-96 justify-around md:justify-between mt-6 mb-20">
          <a
            className="w-44 my-6 md:w-40 hover:opacity-70"
            href="https://apps.apple.com/us/app/snow-commercial-management/id6443944347"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="apple-store" className="w-full" src={AppleAppStoreIcon} />
          </a>

          <a
            className="w-44 my-6 md:w-40 hover:opacity-70"
            href="https://play.google.com/store/apps/details?id=com.trayl.dev.snow"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="google-store"
              className="w-full"
              src={GooglePlayStoreIcon}
            />
          </a>
        </div>
        {/* Gradient */}
      </div>
      <div className="-bottom-2 w-full h-16 bg-gradient-to-t from-snow-blue-3"></div>
      {/* Blue - Who is Snowscape */}
      <div className="flex flex-col w-full items-center px-4 md:px-12 py-12 md:py-16 bg-snow-blue-3">
        <div className="text-snow-white font-medium text-4xl">
          Who is SnowScape?
        </div>
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex flex-1 flex-col items-center md:px-20">
            <div className="mt-12 text-snow-white font-light text-2xl text-center md:text-left">
              SnowScape was founded by two contractors that wanted an easy to
              use platform to track job site progress as well as time and
              materials on the job. SnowScape is just that, a simple, easy to
              use job site tracking software that is designed to be easy both in
              the field and in the office.
              <br />
              <br />
              The main reasons to use SnowScape are to protect, manage and
              streamline your service based business. When you subscribe to our
              platform, you're not supporting a big tech company - instead you
              are supporting two small business owning contractors that want to
              make a platform to help improve not just their own operations but
              yours as well!
            </div>
            <div className="my-12 max-w-xs">
              <RoundButton
                color="white"
                label="About Us"
                onClick={() => {
                  navigate("/about");
                }}
                font="blue"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col xl:flex-row my-12  items-center justify-center">
          <div className="flex flex-1 w-full justify-center mx-2 p-4 bg-snow-white drop-shadow border-[1px] border-snow-light-grey rounded-2xl mb-4 max-w-[650px]">
            <LandingVideoPlayer
              label="Protect"
              url="https://www.youtube-nocookie.com/embed/hyiInDmCzHw?si=kVs8eZ9ev7LCguv-"
            />
          </div>
          <div className="flex flex-1 w-full justify-center mx-2 p-4 bg-snow-white drop-shadow border-[1px] border-snow-light-grey rounded-2xl mb-4 max-w-[650px]">
            <LandingVideoPlayer
              label="Manage"
              url="https://www.youtube.com/embed/dtHFxejiSv0?si=YekuA76oi4XC9dLG"
            />
          </div>
          <div className="flex flex-1 w-full justify-center mx-2 p-4 bg-snow-white drop-shadow border-[1px] border-snow-light-grey rounded-2xl mb-4 max-w-[650px]">
            <LandingVideoPlayer
              label="Streamline"
              url="https://www.youtube-nocookie.com/embed/LDLuEJPhqfk?si=v53nXtpSANwLm1ZT"
            />
          </div>
        </div>
      </div>
      {/* White - Snowscape */}
      <div className="flex flex-col w-full self-center items-center px-4 md:px-20 py-12 md:py-16 max-w-[1200px]">
        <div className="md:w-2/3 text-center border-b-[1px] border-snow-blue-3 mb-8 md:mb-16">
          <div className="text-snow-blue-3 font-medium text-4xl px-4 pb-2">
            Why Choose SnowScape?
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-center lg:justify-around items-center lg:items-start">
          <LandingFeaturesCard
            description="With time stamped job reports and pictures there is never a guess as to what was done on the job site and when. Having this customizable report logged for every service protects all parties from exposure to liabilities as well as damage and billing disputes."
            label="Protect"
            logo={ShieldBlueIcon}
          />
          <LandingFeaturesCard
            description="Create routes with all the details you need for each individual job, from site maps to lock combinations and everything in between.  In real time, track your crews and know exactly what was done, while seeing pictures of the work that has been completed.  Finally, generate reports to track your materials used, hours spent on site and everything needed to properly bid and bill your accounts."
            label="Manage"
            logo={CalendarBlueIcon}
          />
          <LandingFeaturesCard
            description="Everything is in one place. With a super simple platform, little to no training is needed. Even the most technologically illiterate person can figure it out."
            label="Streamline"
            logo={ClockBlueIcon}
          />
        </div>
      </div>
      <div className="flex flex-col items-center w-full ">
        <div className="text-snow-primary font-medium text-2xl text-center mb-4">
          Don't believe us?
        </div>
        <div className=" max-w-xl">
          <RoundButton
            label="Get Started With A 7 Day Free Trial"
            color="blue"
            onClick={() => {
              navigate("/get-started");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
