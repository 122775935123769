import React, { useEffect, useState } from "react";

// Components
import StandardButton from "../Buttons/StandardButton";
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";
import ModalWrapper from "./ModalWrapper";
import MultipleChoiceModal from "./MultipleChoiceModal";

const CreateMaterialModal = (props) => {
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  const [materialDescription, setMaterialDescription] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [materialUnit, setMaterialUnit] = useState("");

  useEffect(() => {
    if (props.initialMaterialValue) {
      setMaterialDescription(
        props.initialMaterialValue.description || "No description",
      );
      setMaterialName(props.initialMaterialValue.name);
      setMaterialUnit(props.initialMaterialValue.baseUnit);
    } else {
      // Refresh each time modal is opened
      setMaterialDescription("");
      setMaterialName("");
      setMaterialUnit("");
    }
  }, [props.open]);

  const handleSave = () => {
    if (!materialName) {
      alert("Please give a name to this material");
      return;
    }
    if (!materialUnit) {
      alert("Please give a unit to this material");
      return;
    }

    // if existing _id the backend will updated the value
    let newMaterial = {
      baseUnit: materialUnit,
      description: materialDescription,
      name: materialName,
    };
    if (props.initialMaterialValue?._id) {
      newMaterial._id = props.initialMaterialValue?._id;
    }
    props.onCreateMaterial(newMaterial);
  };

  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.onClose}>
          <div
            className={`flex flex-col w-2/3 h-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "Create New Material"}
              </div>
              <div className="h-10 flex flex-row">
                <StandardButton
                  color="red"
                  label={props.disableEdit ? "Close" : "Cancel"}
                  onClick={props.onClose}
                />

                {props.enableCreate ? (
                  <div className="ml-4">
                    <StandardButton
                      color="green"
                      label="Create"
                      onClick={handleSave}
                    />
                  </div>
                ) : (
                  <div className="ml-4">
                    <StandardButton
                      color="blue"
                      label="Save"
                      onClick={handleSave}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Material Name */}
            <LabeledTextboxInput
              disabled={props.disableEdit}
              label="Name"
              placeholder="Name your material..."
              setValue={setMaterialName}
              value={materialName}
            />

            {/* Material Unit */}
            {/* TODO: Replace with react-select CreatableSelect -
                        show all default units, allows user to create their own */}
            <LabeledTextboxInput
              disabled={props.disableEdit}
              label="Unit"
              placeholder="Eg. yards, tons, bags, etc..."
              setValue={setMaterialUnit}
              value={materialUnit}
            />

            {/* Material Description */}
            <LabeledTextboxInput
              disabled={props.disableEdit}
              label="Description (optional)"
              placeholder="Please describe your material..."
              setValue={setMaterialDescription}
              value={materialDescription}
            />

            <div className="flex flex-1 flex-col mb-8">
              {props.enableDelete ? (
                <div className="w-full flex justify-center mt-4">
                  <div
                    className="font-bold text-snow-red cursor-pointer hover:opacity-70 active:opacity-40"
                    onClick={() => setDisplayDeleteModal(true)}
                  >
                    Delete Material
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </ModalWrapper>
      ) : null}

      <MultipleChoiceModal
        open={displayDeleteModal}
        options={[
          {
            color: "red",
            label: "Delete Permanently",
            onClick: () => {
              setDisplayDeleteModal(false);
              props.onDelete(props.initialMaterialValue._id);
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeleteModal(false),
          },
        ]}
        subtitle="Are you sure you want to delete this material? Warning! This is a permanent action."
        title="Delete"
      />
    </>
  );
};

export default CreateMaterialModal;
