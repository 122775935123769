import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import axiosJobs from "../../services/axios/jobs";
import { connect } from "react-redux";
import axiosCompanies from "../../services/axios/companies";
import DateRangeFilter from "../DateRangePicker/DateRangeFilter";
import axiosRoutes from "../../services/axios/routes";

const seasonOptions = [
  { value: "snow", label: "Snow" },
  { value: "landscape", label: "Landscape" },
];
const flaggedOptions = [{ value: true, label: "Yes" }];
const PropertyHistoryFilters = (props) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [routeNameOptions, setRouteNameOptions] = useState([]);
  let filters = props.filters;

  const loadCityOptions = useCallback(
    async (abortSignal) => {
      try {
        let options = await axiosJobs.loadCityOptions(
          props.currentUser.currentCompanyId,
          abortSignal,
        );
        setCityOptions(
          options.map((o) => {
            return { value: o, label: o };
          }),
        );
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          console.error("Unable to load Jobs - try refreshing.");
        }
      }
    },
    [props.currentUser.currentCompanyId],
  );
  const loadOperatorOptions = useCallback(
    async (abortSignal) => {
      try {
        let options = await axiosJobs.loadOperatorOptions(
          props.currentUser.currentCompanyId,
          abortSignal,
        );
        setOperatorOptions(options);
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          console.error("Unable to load Jobs - try refreshing.");
        }
      }
    },
    [props.currentUser.currentCompanyId],
  );
  const loadRouteNameOptions = useCallback(
    async (abortSignal) => {
      try {
        let options = await axiosRoutes.getAllRoutesAsync(
          props.currentUser.currentCompanyId,
          abortSignal,
        );
        setRouteNameOptions(
          options.map((o) => {
            return { value: o._id, label: o.name };
          }),
        );
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          console.error("Unable to load Route name options - try refreshing.");
        }
      }
    },
    [props.currentUser.currentCompanyId],
  );
  const loadPropertyOptions = useCallback(
    async (abortSignal) => {
      try {
        let options = await axiosJobs.filterJobs(
          props.currentUser.currentCompanyId,
          {},
          null,
          0,
          3000,
          abortSignal,
        );
        setPropertyOptions(
          options.map((o) => {
            return { value: o._id, label: o.name };
          }),
        );
      } catch (err) {
        // already reported
      }
    },
    [props.currentUser.currentCompanyId],
  );

  const loadServiceOptions = useCallback(
    async (abortSignal) => {
      try {
        let data = await axiosCompanies.getCommonServicesAsync(
          props.currentUser.currentCompanyId,
          abortSignal,
        );
        const groupedOptions = [
          {
            label: "Landscape Services",
            options: data.landscape.map((option) => ({
              label: option.label,
              value: option.key,
            })),
          },
          {
            label: "Snow Services",
            options: data.snow.map((option) => ({
              label: option.label,
              value: option.key,
            })),
          },
        ];
        setServiceOptions(groupedOptions);
      } catch (err) {
        // pass already reported
      }
    },
    [props.currentUser],
  );

  const makeSetFilterFieldFnc = (field, multi = false) => {
    return async (value) => {
      setFilterValue(field, multi ? value.map((v) => v.value) : value?.value);
    };
  };

  const setFilterValue = (field, value) => {
    let newFilters = {
      ...filters,
      [field]: value,
    };
    props.onSubmitFilters(newFilters);
  };

  useEffect(() => {
    const abort = new AbortController();
    loadCityOptions(abort.signal);
    loadPropertyOptions(abort.signal);
    loadServiceOptions(abort.signal);
    loadOperatorOptions(abort.signal);
    loadRouteNameOptions(abort.signal);
    return () => {
      abort.abort();
    };
  }, [
    loadCityOptions,
    loadOperatorOptions,
    loadPropertyOptions,
    loadRouteNameOptions,
    loadServiceOptions,
  ]);

  const getSelectedServiceFilterOptions = () => {
    if (filters.services?.length > 0) {
      let selected = [];
      let landscapeOptions = serviceOptions.find(
        (o) => o.label == "Landscape Services",
      );
      let snowOptions = serviceOptions.find((o) => o.label == "Snow Services");
      if (landscapeOptions) {
        selected.push(
          ...landscapeOptions.options.filter((o) =>
            filters.services.includes(o.value),
          ),
        );
      }
      if (snowOptions) {
        selected.push(
          ...snowOptions.options.filter((o) =>
            filters.services.includes(o.value),
          ),
        );
      }
      return selected;
    } else {
      return [];
    }
  };

  return (
    <>
      <Select
        onChange={makeSetFilterFieldFnc("season")}
        options={seasonOptions}
        placeholder="Filter by Season"
        isClearable={true}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#829399",
          }),
        }}
        value={
          filters.season
            ? seasonOptions.find((o) => o.value == filters.season)
            : null
        }
      />
      <Select
        onChange={makeSetFilterFieldFnc("flaggedForReview")}
        options={flaggedOptions}
        placeholder="Filter by Flagged For Review"
        isClearable={true}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#829399",
          }),
        }}
        value={
          filters.flaggedForReview
            ? flaggedOptions.find((o) => o.value == filters.flaggedForReview)
            : null
        }
      />
      <Select
        isMulti
        onChange={makeSetFilterFieldFnc("cities", true)}
        options={cityOptions}
        placeholder="Filter by City"
        isClearable={true}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#829399",
          }),
        }}
        value={
          filters.cities
            ? cityOptions.filter((o) => filters.cities.includes(o.value))
            : null
        }
      />
      <Select
        isMulti
        onChange={makeSetFilterFieldFnc("jobs", true)}
        options={propertyOptions}
        placeholder="Filter by Property..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // Set's the border color to match the Date Picker == 'snow-light-grey'
            borderColor: "#829399",
          }),
        }}
        value={
          filters.jobs
            ? propertyOptions.filter((o) => filters.jobs.includes(o.value))
            : null
        }
      />
      <Select
        isMulti
        onChange={makeSetFilterFieldFnc("routes", true)}
        options={routeNameOptions}
        placeholder="Filter by Route..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // Set's the border color to match the Date Picker == 'snow-light-grey'
            borderColor: "#829399",
          }),
        }}
        value={
          filters.routes
            ? routeNameOptions.filter((o) => filters.routes.includes(o.value))
            : null
        }
      />
      <Select
        isMulti
        onChange={makeSetFilterFieldFnc("services", true)}
        options={serviceOptions}
        placeholder="Filter by Service..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // Set's the border color to match the Date Picker == 'snow-light-grey'
            borderColor: "#829399",
          }),
        }}
        value={getSelectedServiceFilterOptions()}
      />
      <Select
        isMulti
        onChange={makeSetFilterFieldFnc("operators", true)}
        options={operatorOptions}
        placeholder="Filter by Operator..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // Set's the border color to match the Date Picker == 'snow-light-grey'
            borderColor: "#829399",
          }),
        }}
        value={
          filters.operators
            ? operatorOptions.filter((o) => filters.operators.includes(o.value))
            : null
        }
      />
      <DateRangeFilter
        dateRange={
          filters.dateRange
            ? {
                startDate: filters.dateRange.firstDate,
                endDate: filters.dateRange.secondDate,
              }
            : null
        }
        waitForFullRange={true}
        buttonStyle={{
          width: "100%",
          borderColor: "rgb(130, 147, 153)",
          textAlign: "left",
        }}
        clearable={true}
        onChange={(v) => {
          if (v) {
            setFilterValue("dateRange", {
              firstDate: v.startDate,
              secondDate: v.endDate,
            });
          } else {
            setFilterValue("dateRange", null);
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(PropertyHistoryFilters);
