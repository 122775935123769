import React from "react";
import { Link } from "react-router-dom";

// Components
// import AddOnsCard from '../../components/Cards/AddOnsCard';
import PriceTierCard from "../../components/Cards/PriceTierCard";

function PricingPage() {
  return (
    <div className="flex flex-col items-center overflow-scroll px-4 md:px-20 pb-20">
      <div className="flex justify-center w-full max-w-5xl text-center lg:text-left mt-12 text-2xl font-light font-snow-blue-3">
        We know that each of our customers is different. That's why we have
        designed multiple tiers to best fit your personal needs.
      </div>
      <div className="flex flex-col xl:flex-row items-center justify-around lg:justify-between mt-9 w-full max-w-[1270px]">
        <div className="flex flex-col md:flex-row items-center w-full justify-around max-w-2xl">
          <PriceTierCard
            title="Han Solo"
            price="Free"
            details={["1 Company", "1 User"]}
            subtext="Add extra users for $11.99 / user"
          />
          <PriceTierCard
            title="Team"
            price="$89/mo"
            details={["1 Company", "20 Users"]}
            subtext="Add extra users for $11.99 / user"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center w-full justify-around max-w-2xl">
          <PriceTierCard
            title="Squad"
            price="$179/mo"
            details={["2 Companies/ Subcontractors", "100 Users"]}
            subtext="Add extra users for $11.99 / user"
          />
          <PriceTierCard
            title="Fleet"
            price="$269/mo"
            details={["5 Companies/ Subcontractors", "300 Users"]}
            subtext="Add extra users for $11.99 / user"
          />
        </div>
      </div>
      <div className="mx-auto mt-4 text-2xl font-light font-snow-blue-3 max-w-5xl">
        Get started with a{" "}
        <Link className="underline font-bold" to="/get-started">
          7 day free trial
        </Link>{" "}
        today!
      </div>

      <div className="mx-auto mt-4 text-2xl font-light font-snow-blue-3 max-w-5xl">
        Not seeing a tier that's right for you?{" "}
        <Link className="underline font-bold" to="quote">
          Get a quote.
        </Link>
      </div>
      <div className="mx-auto mt-4 text-sm font-light font-snow-blue-3 max-w-5xl">
        All our plans support adding extra users for $11.99 / user / month or
        extra companies at $49.99 / company / month. After signing up, you can
        modify your subscription to add extra users / companies - or just shoot
        us an email and we will do it for you!
      </div>
      {/* <div className='mx-auto mt-8 text-2xl font-light font-snow-blue-3 max-w-5xl'>
                In order to make our system even more flexible to our user's
                needs, we've designed a suite of add-ons to give you the best!
            </div>
            <div className='flex flex-row justify-around flex-wrap mt-9 w-full'>
                <AddOnsCard
                    title='+1 Companies/Subcontractors'
                    price='$49/mo'
                    // details={['1 Companies/Subcontractors']}
                />
                <AddOnsCard
                    title='+1 User'
                    price='$9.99/mo'
                    // details={['1 Employee']}
                />
                <AddOnsCard
                    title='+50 Users'
                    price='$49/mo'
                    // details={['50 Users']}
                />
            </div> */}
    </div>
  );
}

export default PricingPage;
