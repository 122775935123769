import React from "react";
import FilterIconBlue from "../../resources/filter-blue.svg";
import SearchIconBlue from "../../resources/search-blue.svg";
// import useWindowDimensions from '../../hooks/useWindowDimensions';
const SearchBar = (props) => {
  // const { width, height } = useWindowDimensions();
  const [showFilters, setShowFilters] = React.useState(
    window.innerWidth > 1000 || props.showFiltersDefault,
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSearch(e.target.search.value);
      }}
      className="mb-2"
    >
      <div className="flex flex-row items-center gap-2">
        <div
          className={`flex flex-row w-80 h-10 rounded-xl my-3 py-1.5 pr-5 text-snow-blue-3 bg-snow-extra-light-grey ${
            props.disabled ? "opacity-40" : null
          }`}
        >
          <button type="submit">
            <img
              alt="filter"
              className="w-5 h-5 justify-center self-center mx-2"
              src={SearchIconBlue}
            />
          </button>
          <input
            className="w-full text-lg font-light bg-snow-extra-light-grey border-none outline-none"
            disabled={props.disabled}
            inputMode={props.inputMode || "text"}
            maxLength={props.maxLength}
            placeholder={props.placeholder || "Search..."}
            defaultValue={props.value}
            name="search"
          />
        </div>
        {props.alwaysVisibleFilters ? props.alwaysVisibleFilters : null}
        {props.children ? (
          <button type="button">
            <img
              src={FilterIconBlue}
              alt="filter"
              className="w-5 h-5"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            />
          </button>
        ) : null}
      </div>
      {props.children ? (
        <div
          style={{ display: showFilters ? "grid" : "none" }}
          className="grid-cols-1 xl:grid-cols-3 grid gap-2"
        >
          {props.children}
        </div>
      ) : null}
    </form>
  );
};

export default SearchBar;
