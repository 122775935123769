import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import CheckBox from "../../../components/CheckBoxes/CheckBox";
import LabeledCheckBox from "../../../components/CheckBoxes/LabeledCheckBox";
import EmailField from "../../../components/Email/EmailField";
import HeaderBar from "../../../components/Header/HeaderBar";
import ImageNoteScroller from "../../../components/Images/ImageNoteScroller";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import ReportSettingsField from "../../../components/Report/ReportSettingsField";
import ServicesField from "../../../components/Services/ServicesField";

// Services
import axiosCompanies from "../../../services/axios/companies";
import axiosJobs from "../../../services/axios/jobs";

import { useJobState } from "./job-hooks";

// Dropdown options
const requiredImageOptions = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
];

const AdminCreateJobPage = (props) => {
  const navigate = useNavigate();

  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const {
    checkInternalMissingElements,
    isFormInvalid,
    jobState,
    setAddress,
    setInputValue,

    setLandscapeEnabled,
    setLandscapeInputValue,
    setLandscapeReportValue,
    setLandscapeState,

    setSnowEnabled,
    setSnowInputValue,
    setSnowReportValue,
    setSnowState,
    setRawJobState,

    buildPayload,
  } = useJobState();

  const { landscape: landscapeState, snow: snowState } = jobState;

  useEffect(() => {
    axiosCompanies.getCompanyById(
      { companyId: props.currentUser.currentCompanyId },
      (company) => {
        let rawState = {
          ...jobState,
          ...{
            landscape: {
              ...landscapeState,
              serviceOptions: company.commonCompanyServices.landscape,
            },
            snow: {
              ...snowState,
              serviceOptions: company.commonCompanyServices.snow,
            },
          },
        };
        setRawJobState(rawState);
      },
    );
  }, []);

  const checkForMissingElements = () => {
    checkInternalMissingElements(displayMissingElements);
  };

  // When the disabled create button is clicked, sends an alert
  const displayMissingElements = (missingElements) => {
    alert(
      `Be sure that you have filled in the following: ${missingElements.join(
        ", ",
      )}`,
    );
  };

  const handleCreateJob = () => {
    setCreateButtonLoading(true);

    // Submit new job to server
    axiosJobs.createNewJob(
      buildPayload(props.currentUser.currentCompanyId),
      () => {
        setCreateButtonLoading(false);
        navigate(-1);
      },
    );
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Create Property" />

      <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row">
            <div className="flex font-bold mb-6">
              <CheckBox
                checked={jobState.enableSnow}
                label="Enable Snow Season"
                lightMode={false}
                onClick={() => setSnowEnabled(!jobState.enableSnow)}
              />
            </div>
            <div className="flex font-bold mb-6">
              <CheckBox
                checked={jobState.enableLandscape}
                label="Enable Landscape Season"
                lightMode={false}
                onClick={() => setLandscapeEnabled(!jobState.enableLandscape)}
              />
            </div>
          </div>
          <div className="">
            <StandardButton
              color="green"
              label="Create"
              loading={createButtonLoading}
              onClick={handleCreateJob}
              disabled={isFormInvalid}
              disabledClick={checkForMissingElements}
            />
          </div>
        </div>
        <LabeledTextboxInput
          label="Name"
          placeholder="Name your job..."
          setValue={setInputValue("name")}
          value={jobState.name}
        />
        <LabeledTextboxInput
          label="Street Address"
          placeholder="1234 Street Name"
          setValue={(val) => {
            setAddress("streetLineOne", val);
          }}
          value={jobState.address.streetLineOne}
        />
        <LabeledTextboxInput
          label="Apt, suite, etc... (optional)"
          placeholder="Apt 23B"
          setValue={(val) => {
            setAddress("streetLineTwo", val);
          }}
          value={jobState.address.streetLineTwo}
        />
        <LabeledTextboxInput
          label="City"
          placeholder="Detroit"
          setValue={(val) => {
            setAddress("city", val);
          }}
          value={jobState.address.city}
        />
        <LabeledTextboxInput
          label="State"
          placeholder="MI"
          setValue={(val) => {
            setAddress("state", val);
          }}
          value={jobState.address.state}
        />
        <LabeledTextboxInput
          label="ZIP/Postal Code"
          placeholder="12345"
          setValue={(val) => {
            setAddress("zip", val);
          }}
          value={jobState.address.zip}
        />

        {jobState.enableSnow ? (
          <>
            {/* Snow */}
            <hr className="w-full mt-10 mb-6" />
            <div
              className={"flex w-full justify-center text-snow-primary mb-1"}
            >
              <div className="text-3xl font-bold">Snow Season</div>
            </div>
            <LabeledCheckBox
              label="Snow Sitemaps"
              checkboxLabel="Require sitemaps"
              checked={snowState.requireSiteMap ?? true}
              lightMode={false}
              onClick={() => {
                setSnowState("requireSiteMap", !snowState.requireSiteMap);
              }}
            >
              <ImageNoteScroller
                enableDelete={true}
                enableUpload={true}
                isSitemap={true}
                imageArray={snowState.sitemaps}
                label=""
                setImageArray={setSnowInputValue("sitemaps")}
                uploadBoxLabel="Upload Sitemaps"
              />
            </LabeledCheckBox>

            <LabeledCheckBox
              label="Snow Report Emails"
              checkboxLabel="Require emails for report"
              checked={snowState.requireReportEmails ?? true}
              lightMode={false}
              onClick={() => {
                setSnowState(
                  "requireReportEmails",
                  !snowState.requireReportEmails,
                );
              }}
            >
              <EmailField
                enableAdd={true}
                enableDelete={true}
                enableEdit={true}
                emailList={snowState.report?.emailList || []}
                label="Snow Report Emails"
                setEmailList={(updatedList) => {
                  setSnowReportValue("emailList", updatedList);
                }}
                subtitle="Add emails you would like snow reports sent to"
              />
            </LabeledCheckBox>

            <LabeledTextboxInput
              label="Snow Instructions"
              placeholder="Give special instructions to this property..."
              setValue={(val) => {
                setSnowState("instructions", val);
              }}
              value={snowState.instructions}
            />

            {/* Dropdowns using https://react-select.com/home */}
            <div className="flex flex-col w-full justify-start my-8">
              <div className="text-sm text-snow-primary font-bold mb-0.5">
                Property Images
              </div>
              <div className="text-sm text-snow-primary font-light mb-1">
                Select a quantity of images you want required to be taken by
                operators for this property
              </div>
              <div className="w-full max-w-sm">
                <Select
                  onChange={setSnowInputValue("requiredImageCount")}
                  options={requiredImageOptions}
                  placeholder="Number of Required Property Images"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      // Set's the border color to match the Date Picker == 'snow-light-grey'
                      borderColor: "#082D4A",
                    }),
                  }}
                  value={snowState.requiredImageCount}
                />
              </div>
            </div>
            <div className="w-full mb-5">
              <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
                Notes
              </div>
              <div className="text-sm font-light mb-1 self-start text-snow-primary"></div>
              <CheckBox
                checked={snowState.requireNotes ?? true}
                label="Require operators to enter notes when working on this property"
                lightMode={false}
                onClick={() => {
                  setSnowState("requireNotes", !snowState.requireNotes);
                }}
              />
            </div>
            <ServicesField
              enableAddNew={true}
              enableSelectAll={true}
              label="Snow Services Available"
              services={snowState.serviceOptions}
              season="snow"
              setServices={(updatedList) => {
                setSnowState("serviceOptions", updatedList);
              }}
              subtitle="Select what snow services you will provide to this property"
            />
            <ReportSettingsField
              enableSelectAll={true}
              reportSettings={snowState.report.reportSettings}
              season="snow"
              setReportSettings={(updatedList) => {
                setSnowReportValue("reportSettings", updatedList);
              }}
              subtitle="Select what information you want included in your snow report emails"
            />
          </>
        ) : null}

        {jobState.enableLandscape ? (
          <>
            {/* Landscape */}
            <hr className="w-full mt-10 mb-6" />
            <div
              className={"flex w-full justify-center text-snow-primary mb-1"}
            >
              <div className="text-3xl font-bold">Landscape Season</div>
            </div>
            <LabeledCheckBox
              label="Landscape Sitemaps"
              checkboxLabel="Require sitemaps"
              checked={landscapeState.requireSiteMap ?? true}
              lightMode={false}
              onClick={() => {
                setLandscapeState(
                  "requireSiteMap",
                  !landscapeState.requireSiteMap,
                );
              }}
            >
              <ImageNoteScroller
                enableDelete={true}
                enableUpload={true}
                isSitemap={true}
                imageArray={landscapeState.sitemaps}
                label=""
                setImageArray={setLandscapeInputValue("sitemaps")}
                uploadBoxLabel="Upload Sitemaps"
              />
            </LabeledCheckBox>

            <LabeledCheckBox
              label="Landscape Report Emails"
              checkboxLabel="Require emails for report"
              checked={landscapeState.requireReportEmails ?? true}
              lightMode={false}
              onClick={() => {
                setLandscapeState(
                  "requireReportEmails",
                  !landscapeState.requireReportEmails,
                );
              }}
            >
              <EmailField
                enableAdd={true}
                enableDelete={true}
                enableEdit={true}
                emailList={landscapeState.report?.emailList || []}
                label="Landscape Report Emails"
                setEmailList={(updatedList) => {
                  setLandscapeReportValue("emailList", updatedList);
                }}
                subtitle="Add emails you would like landscaping reports sent to"
              />
            </LabeledCheckBox>

            <LabeledTextboxInput
              label="Landscape Instructions"
              // maxLength={5}
              placeholder="Give special instructions to this property..."
              setValue={(val) => {
                setLandscapeState("instructions", val);
              }}
              value={landscapeState.instructions}
            />

            {/* Dropdowns using https://react-select.com/home */}
            <div className="flex flex-col w-full justify-start my-8">
              <div className="text-sm text-snow-primary font-bold mb-0.5">
                Number of Required Property Images Taken By Operator
              </div>
              <div className="text-sm text-snow-primary font-light mb-1">
                Select a quantity of images you want required to be taken by
                operators for this property
              </div>
              <div className="w-full max-w-sm">
                <Select
                  onChange={setLandscapeInputValue("requiredImageCount")}
                  options={requiredImageOptions}
                  placeholder="Number of Required Property Images"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      // Set's the border color to match the Date Picker == 'snow-light-grey'
                      borderColor: "#829399",
                    }),
                  }}
                  value={landscapeState.requiredImageCount}
                />
              </div>
            </div>
            <div className="w-full mb-5">
              <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
                Notes
              </div>
              <div className="text-sm font-light mb-1 self-start text-snow-primary"></div>
              <CheckBox
                checked={landscapeState.requireNotes ?? true}
                label="Require operators to enter notes when working on this property"
                lightMode={false}
                onClick={() => {
                  setLandscapeState(
                    "requireNotes",
                    !landscapeState.requireNotes,
                  );
                }}
              />
            </div>
            <ServicesField
              enableAddNew={true}
              enableSelectAll={true}
              label="Landscape Services Available"
              services={landscapeState.serviceOptions}
              season="landscape"
              setServices={(updatedList) => {
                setLandscapeState("serviceOptions", updatedList);
              }}
              subtitle="Select what landscape services you will provide to this property"
            />
            <ReportSettingsField
              enableSelectAll={true}
              reportSettings={landscapeState.report.reportSettings}
              season="landscape"
              setReportSettings={(updatedList) => {
                setLandscapeReportValue("reportSettings", updatedList);
              }}
              subtitle="Select what information you want included in your landscape report emails"
            />
          </>
        ) : null}
        {jobState.enableSnow || jobState.enableLandscape ? (
          <div className="w-full mb-10">
            <StandardButton
              color="green"
              label="Create Property"
              loading={createButtonLoading}
              onClick={handleCreateJob}
              disabled={isFormInvalid}
              disabledClick={checkForMissingElements}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminCreateJobPage);
