import backend from "../axios/backendAxios";

const service = {
  uploadFiles: async function (files, content_type) {
    const linksNeeded = files.length;
    let linkGen = this.linkGenerator(linksNeeded, content_type);
    let s3links = [];
    for (let file of files) {
      const link = (await linkGen.next()).value;
      const imageData = await fetch(file.uri);
      const blob = await imageData.blob();
      let response;
      try {
        // Hit issues with axios not uploading the blob properly: https://stackoverflow.com/questions/68286332/axios-in-react-native-cannot-post-a-blob-or-file
        // response = await axios.put(link, {
        //     data: blob,
        // }, {
        //     headers: {
        //         // 'Content-Type': 'application/octet-stream',
        //         'Content-Tye': 'image/jpeg',
        //     },
        //     transformRequest: (d) => d,
        // });
        // Fetch however seems to work fine:
        response = await fetch(link, { body: blob, method: "PUT" });
        file.s3Uri = link.split("?")[0];
        s3links.push(link.split("?")[0]);

        console.log("response: ", response);
      } catch (err) {
        console.log(err);
      }
    }
    return s3links;
  },
  linkGenerator: async function* (maxNeeded, content_type) {
    let linksFetched = 0;
    while (linksFetched < maxNeeded) {
      const res = await backend.post("/image/new-presigned-url", {
        count: Math.min(10, maxNeeded - linksFetched),
        content_type: content_type,
      });
      yield* res.data;
    }
  },
};

export default service;
