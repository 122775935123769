import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import EmployeeCard from "../../../components/Cards/EmployeeCard";
import FilterBar from "../../../components/Filters/FilterBar";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import InviteBySMSModal from "../../../components/Modals/InviteBySMSModal";
import NotificationModal from "../../../components/Modals/NotificationModal";

// Services
import axiosCompanies from "../../../services/axios/companies";

const AdminEmployeePage = (props) => {
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState("all");
  const [displayInviteModal, setDisplayInviteModal] = useState(false);
  const [displayInviteSuccessModal, setDisplayInviteSuccessModal] =
    useState(false);
  const [employees, setEmployees] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axiosCompanies.getAllUsers(
      { companyId: props.currentUser.currentCompanyId },
      (employees) => {
        setEmployees(employees);
        setLoading(false);
      },
    );
  }, []);

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          buttons={[
            {
              color: "blue",
              label: "Invite",
              onClick: () => setDisplayInviteModal(true),
            },
            {
              color: "blue",
              label: "Manage Admins",
              onClick: () => navigate("manage-admins"),
            },
            {
              color: "blue",
              label: "Add Time Clock Employee",
              onClick: () => navigate("/admin/timeclock/employee/new"),
            },
          ]}
          title="Employees"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <FilterBar
              currentFilter={currentFilter}
              filters={[
                { label: "All", value: "all" },
                { label: "Admins Only", value: "admin" },
              ]}
              setCurrentFilter={setCurrentFilter}
            />

            <div className="w-full">
              {employees.map((employee) =>
                currentFilter === "all" ||
                currentFilter ===
                  employee.companies[props.currentUser.currentCompanyId]
                    .role ? (
                  <EmployeeCard
                    key={employee._id}
                    employee={employee}
                    onClick={() => {
                      employee.uid
                        ? navigate(employee.uid)
                        : navigate(`/admin/timeclock/employee/${employee._id}`);
                    }}
                  />
                ) : null,
              )}
            </div>
          </div>
        )}
      </div>
      <InviteBySMSModal
        adjustForSidebar
        onClose={() => setDisplayInviteModal(false)}
        open={displayInviteModal}
        onSuccess={() => setDisplayInviteSuccessModal(true)}
      />
      <NotificationModal
        adjustForSidebar
        button={{
          color: "blue",
          label: "Okay",
          onClick: () => {
            // Close
            setDisplayInviteSuccessModal(false);
          },
        }}
        open={displayInviteSuccessModal}
        subtitle="Your message has been sent."
        title="Success"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminEmployeePage);
