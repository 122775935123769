import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import RouteCard from "../../../components/Cards/RouteCard";
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import FilterBar from "../../../components/Filters/FilterBar";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Services
import axiosRoutes from "../../../services/axios/routes";

import ReorderCardWrapper from "../../../components/Cards/ReorderCardWrapper";
import TwistedArrow from "../../../resources/twisted-arrow.svg";

import errors from "../../../services/errors";

const AdminRoutesPage = (props) => {
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState("all");
  const [displayChooseSeasonModal, setDisplayChooseSeasonModal] =
    useState(false);
  const [loading, setLoading] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (props.currentUser?.currentCompanyId) {
      axiosRoutes.getAllRoutes(
        { companyId: props.currentUser.currentCompanyId },
        (companyRoutes) => {
          setRoutes(companyRoutes);
          setLoading(false);
        },
      );
    } else {
      // If the user doesn't have a company yet, send them to the company page.
      // This can happen just after sign-up if they click on Admin Dashboard from the homepage.
      navigate("/admin/user/company/");
    }
  }, [navigate, props.currentUser.currentCompanyId]);

  const handleReorder = useCallback(
    async (route, order) => {
      try {
        setLoading(true);
        const response = await axiosRoutes.orderRoute(route._id, order);
        if (response.data.successfullyUpdated) {
          axiosRoutes.getAllRoutes(
            { companyId: props.currentUser.currentCompanyId },
            (companyRoutes) => {
              setRoutes(companyRoutes);
              setLoading(false);
            },
          );
        }
      } catch (error) {
        errors.report(error);
        alert(error.message);
        setLoading(false);
      }
    },
    [props.currentUser.currentCompanyId],
  );

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          button={{
            color: "blue",
            enabled: true,
            label: "Create Route",
            onClick: () => setDisplayChooseSeasonModal(true),
          }}
          title="Routes"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <FilterBar
              currentFilter={currentFilter}
              filters={[
                { label: "All", value: "all" },
                { label: "Landscape", value: "landscape" },
                { label: "Snow", value: "snow" },
              ]}
              setCurrentFilter={setCurrentFilter}
            />
            <div className="flex items-start w-full">
              <StartARouteCard></StartARouteCard>
            </div>
            {/* Render all Landscape Routes */}
            {currentFilter === "landscape" || currentFilter === "all" ? (
              <div className="w-full">
                {routes.filter((item) => item.season === "landscape").length >
                0 ? (
                  <div
                    className={
                      "flex w-full justify-between text-landscape-primary mb-4"
                    }
                  >
                    <div className="text-3xl font-bold flex-1">
                      Landscape Routes
                    </div>
                    <div className="flex flex-row justify-end align-top mb-2.5 pr-3">
                      <span className="mr-2 font-hand font-bold text-lg">
                        Order your routes here
                      </span>
                      <img
                        alt="-->"
                        className="w-10 relative top-4"
                        src={TwistedArrow}
                      />
                    </div>
                  </div>
                ) : null}

                <div className=" border-l-[5px] border-landscape-primary pl-2">
                  {routes
                    .filter((route) => route.season === "landscape")
                    .sort((a, b) => a.order - b.order)
                    .map((route, key) => (
                      <ReorderCardWrapper
                        key={key}
                        downPressed={() => handleReorder(route, key + 1)}
                        upPressed={() => handleReorder(route, key - 1)}
                        isActive={route.isActive}
                      >
                        <RouteCard
                          description={route.details}
                          numberOfJobs={route.jobIds.length}
                          isActive={route.isActive}
                          onClick={() => navigate("/admin/route/" + route._id)}
                          name={route.name}
                        />
                      </ReorderCardWrapper>
                    ))}
                </div>
              </div>
            ) : null}
            {/* Render all Snow Routes */}
            {currentFilter === "snow" || currentFilter === "all" ? (
              <div className="w-full">
                {routes.filter((item) => item.season === "snow").length > 0 ? (
                  <div
                    className={
                      "flex w-full justify-between text-snow-primary mb-4"
                    }
                  >
                    <div className="text-3xl font-bold flex-1">Snow Routes</div>
                    <div className="flex flex-row justify-end align-top mb-2.5 pr-3">
                      <span className="mr-2 font-hand font-bold text-lg">
                        Order your routes here
                      </span>
                      <img
                        alt="-->"
                        className="w-10 relative top-4"
                        src={TwistedArrow}
                      />
                    </div>
                  </div>
                ) : null}
                <div className=" border-l-[5px] border-snow-secondary pl-2">
                  {routes
                    .filter((route) => route.season === "snow")
                    .sort((a, b) => a.order - b.order)
                    .map((route, key) => (
                      <ReorderCardWrapper
                        key={key}
                        downPressed={() => handleReorder(route, key + 1)}
                        upPressed={() => handleReorder(route, key - 1)}
                        isActive={route.isActive}
                      >
                        <RouteCard
                          description={route.details}
                          numberOfJobs={route.jobIds.length}
                          isActive={route.isActive}
                          onClick={() => navigate("/admin/route/" + route._id)}
                          name={route.name}
                        />
                      </ReorderCardWrapper>
                    ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <MultipleChoiceModal
        closeModal={() => setDisplayChooseSeasonModal(false)}
        open={displayChooseSeasonModal}
        options={[
          {
            color: "green",
            label: "Landscape",
            onClick: () => {
              navigate("/admin/route/create/landscape");
            },
          },
          {
            color: "blue",
            label: "Snow",
            onClick: () => navigate("/admin/route/create/snow"),
          },
        ]}
        subtitle="Choose a season to create a new route for."
        title="Choose Season"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminRoutesPage);
