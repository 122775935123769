import React from "react";
import TrashRed from "../../resources/trash-can-red.svg";

const EmailItem = (props) => {
  return (
    <div className="flex flex-row bg-snow-white rounded-3xl py-2.5 px-8 mb-2.5 justify-between drop-shadow-md hover:drop-shadow-lg max-w-lg">
      {props.enableEdit ? (
        <input
          type="text"
          className="w-full bg-snow-white"
          onBlur={props.email === "" ? props.onDelete : null}
          onChange={(e) => props.onChange(e.target.value)}
          value={props.email}
          onKeyDown={(e) => {
            // Submit when Enter key is pressed
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
      ) : (
        <div className="w-full bg-snow-white">{props.email}</div>
      )}
      {props.enableDelete ? (
        <div
          className="ml-4 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={props.onDelete}
        >
          <img className="w-5" src={TrashRed} alt="trash" />
        </div>
      ) : null}
    </div>
  );
};

export default EmailItem;
