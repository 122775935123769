/**
 * A service to help check authorization
 * TECH DEBT: this is basically the same as the backend file and should be
 * moved into a common library.
 */
let auth = {};

// Returns full list of admin company ids
auth.adminCompanyIds = (user) => {
  return Object.values(user.companies)
    .filter((company) => company.role === "admin")
    .map((c) => c.companyId.toString());
};

// Returns full list of a single user's companies
auth.accessCompanyIds = (user) => {
  return Object.keys(user.companies);
};

// Returns true if user is allowed to act as admin for a company
auth.canAdminCompany = (user, companyId) => {
  if (!user) {
    return false;
  }
  return auth.adminCompanyIds(user).includes(companyId);
};

// Returns true if user is allowed to view a company as a user
auth.canViewCompany = (user, companyId) => {
  if (!user) {
    return false;
  }
  return auth.accessCompanyIds(user).includes(companyId);
};

// Returns true if user is allowed to act as owner for a company
auth.canOwnerCompany = (user, company) => {
  if (!user) {
    return false;
  }
  return company.ownerUid === user.uid;
};

auth.canSuperAdmin = (user) => {
  let permissions = user.permissions ?? new Map();
  if (!(permissions instanceof Map)) {
    permissions = new Map(
      Object.keys(permissions).map((k) => [k, permissions[k]]),
    );
  }
  let globalPermissions = permissions.get("global") ?? [];
  return globalPermissions.includes("super-admin");
};

auth.can = (user, permission, companyId) => {
  let permissions = user.permissions ?? new Map();
  if (!(permissions instanceof Map)) {
    permissions = new Map(
      Object.keys(permissions).map((k) => [k, permissions[k]]),
    );
  }
  let globalPermissions = permissions.get("global") ?? [];
  let companyPermissions = permissions.get(companyId.toString()) ?? [];
  let combinedPermissions = [...companyPermissions, ...globalPermissions];
  return combinedPermissions.includes(permission) || auth.canSuperAdmin(user);
};

// module.exports = auth;
export default auth;
