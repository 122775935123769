// This component is designed to block any unpaid use of our services.

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import NotificationModal from "../Modals/NotificationModal";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../services/axios/companies";

// Helper function checking date difference
const isWithinDayConstraint = (date1, date2) => {
  // Number of days allowed to return true, can updated to require more fresh data
  const dayConstraint = 1;

  // Number of milliseconds in a day
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(date1 - date2);

  // Calculate the difference in days
  const differenceInDays = differenceInMilliseconds / millisecondsPerDay;

  // Check if the difference is within 7 days
  return differenceInDays <= dayConstraint;
};

const SubscriptionPaywall = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [displayUnpaidCompanyModal, setDisplayUnpaidCompanyModal] =
    useState(false);
  const [isOwner, setIsOwner] = useState(false);

  // These whitelisted pages are allowed to be accessed even if not paid for
  const whitelistedPages = ["/admin/account", "/admin/user/company"];

  useEffect(() => {
    // Don't check paid status on whitelisted pages
    if (whitelistedPages.includes(location.pathname)) return;

    // If isPaid and isPaidUpdatedAt within 24 hours, allow continual use
    if (
      props.currentUser.isPaid &&
      isWithinDayConstraint(props.currentUser.isPaidUpdatedAt, Date.now())
    )
      return;

    if (props.currentUser?.currentCompanyId) {
      // Sometimes the user hasn't created a company yet
      axiosCompanies.checkIfPaid(
        {
          companyId: props.currentUser.currentCompanyId,
          uid: props.currentUser.uid,
        },
        async (paymentData) => {
          setIsOwner(false);

          if (paymentData.paid) {
            // Update currentUser.isPaid true
            await dispatch(
              updateUser({
                isPaid: true,
                isPaidUpdatedAt: Date.now(),
              }),
            );
            return;
          } else if (paymentData.companyOwner.uid === props.currentUser.uid) {
            setIsOwner(true);
            setDisplayUnpaidCompanyModal(true);

            // Update currentUser.isPaid false
            await dispatch(
              updateUser({
                isPaid: false,
                isPaidUpdatedAt: Date.now(),
              }),
            );

            // Redirect to account page
            navigate("/admin/account");
          } else {
            setDisplayUnpaidCompanyModal(true);

            // Update currentUser.isPaid false
            await dispatch(
              updateUser({
                isPaid: false,
                isPaidUpdatedAt: Date.now(),
              }),
            );

            // Redirect to manage companies (contact manager)
            navigate("/admin/user/company");
          }
        },
      );
    }
  }, [location.pathname]);

  return (
    <NotificationModal
      button={{
        color: "blue",
        label: "Okay",
        onClick: () => {
          // Close
          setDisplayUnpaidCompanyModal(false);
          setIsOwner(false);
        },
      }}
      open={displayUnpaidCompanyModal}
      subtitle={
        isOwner
          ? "The company you tried to access doesn't appear to be licensed. Please add them to your licensed companies list on your Account Page to continue."
          : "The company you tried to access doesn't appear to be licensed. Please contact the company manager to setup their subscription."
      }
      title="Subscription Notice"
    />
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(SubscriptionPaywall);
