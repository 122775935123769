import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import firebaseConfig from "../../config/firebaseConfig";

const noop = () => {};

// Firebase constants
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const firebaseAuth = {
  // Export Auth value
  auth,

  // Recaptcha Verification
  getRecaptchaVerify: async (onSuccess = noop, onFail = noop) => {
    if (window.recaptchaVerifier) {
      onSuccess();
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSuccess();
        },
        "expired-callback": () => {
          // Response expired. Ask the user to solve reCAPTCHA again.
          alert("Recaptcha has failed. Please refresh and try again.");
          onFail();
        },
      },
      auth,
    );
    onSuccess();
  },

  getUser: async (onSuccess = noop, onFail = noop) => {
    try {
      const user = auth.currentUser;
      onSuccess(user);
    } catch (err) {
      console.error(err);
      onFail(err);
    }
  },

  sendSMS: async (phoneNumber, onSuccess = noop, onFail = noop) => {
    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier,
      );
      window.confirmationResult = confirmationResult;
      onSuccess();
    } catch (error) {
      onFail(error);
    }
  },

  signInWithCode: async (code, onSuccess = noop, onFail = noop) => {
    try {
      const result = await window.confirmationResult.confirm(code);
      const user = result.user;
      onSuccess(user);
    } catch (error) {
      console.error("Verification Code Confirmation Error: ", error);
      if (error.code === "auth/invalid-verification-code") {
        // Handle the case of an invalid verification code
        console.error("Invalid verification code");
      } else {
        // Handle other Firebase authentication errors
        console.error("Other Authentication Error: ", error);
      }
      onFail(error);
    }
  },

  signOut: async (onSuccess = noop, onFail = noop) => {
    try {
      await signOut(auth);
      onSuccess();
    } catch (err) {
      console.error("Sign out error: ", err);
      alert("Sorry, there was an error signing out. Please try again.");
      onFail(err);
    }
  },
};

export default firebaseAuth;
