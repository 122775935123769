import axios from "./backendAxios";

const axiosActiveRoutes = {
  getActiveRouteStatus: async (routeId) => {
    const res = await axios.get(`activeRoute/route/${routeId}/status?v=3`);
    return res.data;
  },
  getActiveRouteById: async (routeId) => {
    const res = await axios.get(`activeRoute/route/${routeId}`);
    return res.data;
  },
};

export default axiosActiveRoutes;
