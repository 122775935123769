import React from "react";

function InputWrapper(props) {
  return (
    <div className="flex flex-col w-full justify-start my-8">
      <div className="text-sm text-snow-primary font-bold mb-0.5">
        {props.label}
      </div>
      {props.description ? (
        <div className="text-sm text-snow-primary font-light mb-1">
          {props.description}
        </div>
      ) : null}
      <div className="w-full max-w-sm">{props.children}</div>
    </div>
  );
}

export default InputWrapper;
