import React from "react";

/**
 *
 * @param props
 */
function Until(props) {
  let now = new Date();
  let until = props.until;
  if (now < until) {
    return <>{props.children}</>;
  } else if (props.else) {
    return <>{props.else}</>;
  }
  return null;
}
export default Until;
