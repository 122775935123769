// Package Docs: https://www.npmjs.com/package/react-date-range
import { format, subDays } from "date-fns";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
const DateRangeFilter = (props) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(
    props.dateRange
      ? { ...props.dateRange, key: "selection" }
      : {
          startDate: subDays(new Date(), 30),
          endDate: new Date(),
          key: "selection",
        },
  );
  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    if (props.waitForFullRange) {
      if (ranges.selection.startDate == ranges.selection.endDate) {
        return;
      }
      setOpen(false);
    }
    props.onChange(ranges.selection);
  };
  const buttonText = props.dateRange
    ? `${format(dateRange.startDate, "MMM d, yyyy")} → ${format(dateRange.endDate, "MMM d, yyyy")}`
    : "Filter By Date";
  return (
    <>
      <div
        className=""
        style={{
          position: "relative",
        }}
      >
        <div className="trigger">
          <button
            type="button"
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              border: "1px solid gray",
              borderRadius: 4,
              backgroundColor: "white",
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 6,
              paddingBottom: 6,
              color: props.dateRange ? "black" : "gray",
              display: "flex",
              justifyContent: "space-between",
              ...props.buttonStyle,
            }}
          >
            {buttonText}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {props.clearable ? (
                <div
                  style={{ width: 25, height: 25 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    props.onChange(null);
                  }}
                >
                  <svg
                    style={{}}
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                    className="css-tj5bde-Svg"
                  >
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </div>
              ) : null}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
            </div>
          </button>
        </div>
        {open ? (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              zIndex: 100,
            }}
          >
            <DateRange
              ranges={dateRange ? [dateRange] : []}
              onChange={handleSelect}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DateRangeFilter;
