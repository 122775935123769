import { ObjectId } from "bson";
import { format, subHours } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import InputWrapper from "../../../components/Inputs/InputWrapper";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import axios from "../../../services/axios/backendAxios";
import errors from "../../../services/errors";
import flash from "../../../services/flash";

const TimeRecordPage = (props) => {
  let { record_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isNew] = useState(record_id === "new");

  const loadData = async (signal) => {
    try {
      let loadEmployeePromise = axios.get(
        `company/${props.currentUser.currentCompanyId}/users?take=10000`,
      );
      setEmployees((await loadEmployeePromise).data);
      if (record_id === "new") {
        setRecord({
          _id: new ObjectId().toString(),
          companyId: props.currentUser.currentCompanyId,
          startTime: subHours(new Date(), 1),
          endTime: null,
        });
      } else {
        const recordData = (
          await axios.get(`timeclock/record/${record_id}`, {
            signal: signal,
          })
        ).data;
        setRecord(recordData);
      }
      setLoading(false);
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        errors.report(err);
        alert(
          "Sorry we were not able to load the data.  Please try refreshing.",
        );
      }
    }
  };

  useEffect(() => {
    // Note React intentionally calls this twice in dev mode so we need a way to abort: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    let controller = new AbortController();
    loadData(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  const setField = (field, value) => {
    let newObj = { ...record };
    newObj[field] = value;
    setRecord(newObj);
  };
  const save = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(`timeclock/record`, record);
      setRecord(resp.data);
      setLoading(false);
      flash.success("Saved record");
      navigate("/admin/timeclock");
    } catch (err) {
      errors.report(err, {
        message: "Unable to save edits to a record to the backend",
      });
      alert("Error trying to save: " + err?.response?.data);
      loadData();
    }
  };
  const deleteRecord = async () => {
    try {
      setLoading(true);
      await axios.delete(`timeclock/record/${record_id}`);
      flash.success("Record deleted");
      navigate("/admin/timeclock");
    } catch (err) {
      errors.report(err, { message: "Unable to delete a time record" });
      alert("Error trying to delete: " + err?.response?.data);
      loadData();
    }
  };
  const canSave = () => {
    return record.startTime != null && record.userId != null;
  };
  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          buttons={
            isNew
              ? []
              : [
                  {
                    color: "red",
                    label: "Delete Record",
                    onClick: () => deleteRecord(),
                  },
                ]
          }
          title="Time Record"
        />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="w-full px-10 pb-10">
            <InputWrapper label="Employee">
              <Select
                onChange={(value) => {
                  setField("userId", value._id);
                }}
                options={employees}
                isDisabled={!isNew}
                placeholder="Select an Employee"
                value={employees.find((e) => e._id === record.userId)}
                getOptionValue={(option) => option._id}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
              />
            </InputWrapper>

            <InputWrapper
              label="Start Time"
              description="Select the start time for this time record"
            >
              <input
                className="w-full text-lg font-regular border-none bg-transparent"
                onChange={(e) =>
                  setField("startTime", new Date(e.target.value).toISOString())
                }
                type="datetime-local"
                value={
                  record.startTime
                    ? format(new Date(record.startTime), "yyyy-MM-dd'T'HH:mm")
                    : null
                }
                max={
                  record.endTime
                    ? format(new Date(record.endTime), "yyyy-MM-dd'T'HH:mm")
                    : null
                }
              />
            </InputWrapper>
            <InputWrapper
              label="End Time"
              description="Select the end time for this time record"
            >
              <label>
                <input
                  type="checkbox"
                  checked={record.endTime === null}
                  onChange={(e) => {
                    setField("endTime", e.target.checked ? null : new Date());
                  }}
                />{" "}
                Active Record
              </label>

              {record.endTime ? (
                <input
                  className="w-full text-lg font-regular border-none bg-transparent"
                  onChange={(e) =>
                    setField("endTime", new Date(e.target.value).toISOString())
                  }
                  type="datetime-local"
                  value={
                    record.endTime
                      ? format(new Date(record.endTime), "yyyy-MM-dd'T'HH:mm")
                      : null
                  }
                  min={
                    record.endTime
                      ? format(new Date(record.startTime), "yyyy-MM-dd'T'HH:mm")
                      : null
                  }
                  max={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                />
              ) : (
                ""
              )}
            </InputWrapper>
            {isNew ? null : (
              <>
                <InputWrapper
                  label="Record Created By"
                  description="The user who was logged into the app when this record was created"
                >
                  <p>
                    {record.submittedBy.firstName} {record.submittedBy.lastName}{" "}
                    at {format(new Date(record.createdAt), "yyyy-MM-dd HH:mm")}
                  </p>
                </InputWrapper>
                <InputWrapper label="Last Modified Date">
                  <p>
                    {format(new Date(record.updatedAt), "yyyy-MM-dd HH:mm")}
                  </p>
                </InputWrapper>

                {record.wasEdited ? (
                  <div className="py-4">
                    <span style={{ marginRight: 7 }}>&#9432;</span>Notice: This
                    record has had it's start or end time modified by an admin.
                  </div>
                ) : null}
              </>
            )}

            <div className="text-sm text-snow-primary font-light mb-1">
              All dates on this page are displayed in your local timezone (
              {Intl.DateTimeFormat().resolvedOptions().timeZone}).
              <br />
              All reports will use the company payroll timezone.
            </div>
            <div style={{ maxWidth: 600, marginTop: 20 }}>
              <StandardButton
                label={isNew ? "Create" : "Save"}
                onClick={save}
                disabled={!canSave()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(TimeRecordPage);
