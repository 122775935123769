import React from "react";

// Resources

const AccountDataTable = (props) => {
  // const [displayDropdownIndex, setDisplayDropdownIndex] = useState(-1);

  // const handleDisplayDropdownClicked = (index) => {
  //   if (displayDropdownIndex === index) {
  //     setDisplayDropdownIndex(-1);
  //     return;
  //   }
  //   setDisplayDropdownIndex(index);
  // };
  const statusColor = (status) => {
    const finishedStatus = ["Declined", "Closed", "Inactive"];
    if (status in finishedStatus || status.includes("End on")) {
      return "text-snow-red";
    }
    if (status === "Late" || status === "Pending") {
      return "text-snow-red";
    }
    if (status === "Incomplete") {
      return "text-snow-yellow";
    }
    return "text-snow-green";
  };

  return (
    <>
      {!props.data || props.data.labels.length === 0 ? null : (
        <div className="flex flex-col w-full bg-snow-white mb-4 border-snow-light-grey border-[1px] rounded-xl text-snow-primary">
          <div className="flex flex-col flex-1 h-full pt-4 pb-2 px-5 border-snow-light-grey">
            <div className="flex flex-row justify-between">
              {props.title ? (
                <div className="text-2xl font-bold">{props.title || ""}</div>
              ) : null}
              {props.headerButton ? (
                <div
                  className="flex items-center py-0 px-2 bg-snow-secondary rounded-lg  drop-shadow text-xs text-snow-white hover:opacity-70 active:opacity-40 cursor-pointer select-none"
                  onClick={props.headerButton.onClick}
                >
                  {props.headerButton.label}
                </div>
              ) : null}
            </div>
            {props.subtitle ? (
              <div className="font-light font-base">{props.subtitle || ""}</div>
            ) : null}
          </div>
          <table className="table-auto border-collapse border-spacing-2 text-left">
            <thead>
              <tr>
                {props.data.labels.map((label, key) => (
                  <th
                    className={`border-snow-light-grey border-t-[1px] ${
                      props.data.content?.length > 0 ? "border-b-[1px]" : null
                    } py-1 px-5 font-normal`}
                    key={key}
                  >
                    {label}
                  </th>
                ))}
                {/* Additional th for complete solid line (fixing ... dropdown issue) */}
                <th
                  className={`border-snow-light-grey border-t-[1px] ${
                    props.data.content?.length > 0 ? "border-b-[1px]" : null
                  } py-1 px-5 font-normal`}
                ></th>
              </tr>
            </thead>
            <tbody className="">
              {props.data.content?.map((row, rowKey) => (
                <tr key={rowKey}>
                  {row.data.map((item, itemKey) => (
                    <td
                      className={`py-2 px-5 ${itemKey === 0 ? "font-bold" : ""}
                                            ${
                                              props.data.labels[itemKey] ===
                                              "Status"
                                                ? "font-bold"
                                                : ""
                                            }
                                            ${
                                              // Set color of status
                                              props.data.labels[itemKey] ===
                                              "Status"
                                                ? statusColor(item)
                                                : ""
                                            }
                                            `}
                      key={itemKey}
                    >
                      {item}
                    </td>
                  ))}

                  {row.options.length > 0 ? (
                    <td className="w-40" key={row.length}>
                      {row.options.map((option, key) => (
                        <div
                          className="text-center cursor-pointer underline"
                          key={key}
                          onClick={option.operation}
                        >
                          {option.label}
                        </div>
                      ))}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
          {props.data.content.length === 0 ? (
            <div className="flex flex-1 w-full self-center justify-center p-6 border-t-[1px]">
              {props.emptyTableText || "No data to display..."}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default AccountDataTable;
