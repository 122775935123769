import { combineReducers } from "redux";
import currentUserReducer from "./currentUserSlice";
import metadataReducer from "./metadataSlice";

// import AsyncStorage from '@react-native-async-storage/async-storage';

const appReducer = combineReducers({
  currentUser: currentUserReducer,
  metadata: metadataReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_CLEAR_STORE") {
    // for all keys defined in your persistConfig(s)

    localStorage.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
