import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function RoundButton(props) {
  return (
    <div
      className={`${props.className || ""} flex items-center justify-center w-full self-center h-14 py-4 px-12 rounded-full ${
        props.color === "red"
          ? "bg-snow-red"
          : props.color === "green"
            ? "bg-snow-green"
            : props.color === "white"
              ? "bg-snow-white"
              : "bg-snow-blue-3"
      }
            ${
              props.disabled
                ? "opacity-60"
                : "hover:opacity-80 active:opacity-40"
            }
            ${props.fullWidth ? "w-full" : ""}`}
      onClick={!props.disabled ? props.onClick : null}
    >
      {props.loading ? (
        <PulseLoader
          color={props.loaderColor || "#fffafa"}
          size={props.loaderSize || 5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div
          className={`font-medium text-lg text-center ${
            props.font === "blue" ? "text-snow-primary" : "text-snow-white"
          }`}
        >
          {props.label}
        </div>
      )}
    </div>
  );
}

export default RoundButton;
