import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import StandardButton from "../Buttons/StandardButton";
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";
import ModalWrapper from "./ModalWrapper";

// Services
import axiosCompanies from "../../services/axios/companies";

const InviteBySMSModal = (props) => {
  const [error, setError] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);

  // Reset state on render
  useEffect(() => {
    setError("");
    setInviteLoading(false);
    setName("");
    setPhoneNumber("");
    setPhoneNumberValid(false);
  }, [props.open]);

  useEffect(() => {
    // Regular expression to match a typical phone number
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    setPhoneNumberValid(phoneRegex.test(phoneNumber));
  }, [phoneNumber]);

  const handleSendInvite = () => {
    setError("");
    setInviteLoading(true);
    axiosCompanies.inviteBySMS(
      {
        companyId: props.currentUser.currentCompanyId,
        phoneNumber,
        name,
      },
      () => {
        setInviteLoading(false);
        props.onClose();
        props.onSuccess();
      },
      (err) => {
        console.error("err: ", err);
        setError(err.response.data.message);
        setInviteLoading(false);
      },
    );
  };

  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.onClose}>
          <div
            className={`flex flex-col w-1/2 h-96 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll min-w-[500px] ${
              props.adjustForSidebar ? "md:ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "Send Invite Message"}
              </div>
              <div className="h-10 flex flex-row">
                <StandardButton
                  color="red"
                  label="Cancel"
                  onClick={props.onClose}
                />
              </div>
            </div>
            <div>
              <LabeledTextboxInput
                label="*Phone Number"
                maxLength={10}
                numbersOnly={true}
                placeholder="555-123-1234"
                setValue={setPhoneNumber}
                value={phoneNumber}
              />
              <LabeledTextboxInput
                label="Name Of Recipient (optional)"
                placeholder="John Doe"
                setValue={setName}
                value={name}
              />
            </div>
            <StandardButton
              color="green"
              disabled={!phoneNumberValid}
              label={"Send SMS to " + phoneNumber}
              loading={inviteLoading}
              onClick={handleSendInvite}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(InviteBySMSModal);
