import React from "react";
import FilterCheckBox from "../CheckBoxes/FilterCheckBox";

const FilterBar = (props) => {
  return (
    <div className="flex w-full items-start mb-6">
      {props.filters.map((filter, key) => (
        <FilterCheckBox
          checked={filter.value === props.currentFilter}
          key={key}
          label={filter.label}
          lightMode={props.lightMode}
          onClick={() => props.setCurrentFilter(filter.value)}
        />
      ))}
    </div>
  );
};

export default FilterBar;
