import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";

// Components
import StandardButton from "../Buttons/StandardButton";
import CheckBox from "../CheckBoxes/CheckBox";
import FilterBar from "../Filters/FilterBar";
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";
import ModalWrapper from "./ModalWrapper";
import MultipleChoiceModal from "./MultipleChoiceModal";

// Services
import axiosMaterials from "../../services/axios/materials";

const CreateServiceModal = (props) => {
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayMaterialsPicker, setDisplayMaterialsPicker] = useState(false);
  const [serviceKey, setServiceKey] = useState(null);
  const [serviceMaterials, setServiceMaterials] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceSeason, setServiceSeason] = useState("snow");
  const [serviceTextInputs, setServiceTextInputs] = useState([]);

  useEffect(() => {
    if (props.initialServiceValue) {
      setServiceKey(props.initialServiceValue.key);
      setServiceName(props.initialServiceValue.label);
      setServiceOptions(props.initialServiceValue.options || []);
      setServiceSeason(props.initialServiceValue.season || "snow");
      setServiceTextInputs(props.initialServiceValue.textInput || []);

      if (props.initialServiceValue?.materials?.length > 0) {
        getInitialMaterials(props.initialServiceValue.materials);
      }
    } else {
      // Refresh each time modal is opened
      setDisplayMaterialsPicker(false);
      setServiceKey(null);
      setServiceMaterials([]);
      setServiceName("");
      setServiceOptions([]);
      setServiceTextInputs([]);
    }
  }, [props.open]);

  const getInitialMaterials = async (initialMaterialIds) => {
    if (!initialMaterialIds) return;

    const materialData = await axiosMaterials.getCompanyMaterials({
      companyId: props.currentUser.currentCompanyId,
    });

    const initialMaterials = initialMaterialIds.map(
      (matId) => materialData.filter((d) => d._id === matId)[0],
    );

    const initialMaterialsShaped = initialMaterials.map((item) => {
      return {
        label: item.name + " (" + item.baseUnit + ")",
        value: item._id,
      };
    });
    setServiceMaterials(initialMaterialsShaped);
    setDisplayMaterialsPicker(true);
  };

  // Load materials for dropdown picker

  const loadMaterials = async (searchValue) => {
    // loadMaterials relevant data for dropdown

    const materialData = await axiosMaterials.getCompanyMaterials({
      companyId: props.currentUser.currentCompanyId,
    });

    const filteredOptions = materialData.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase()),
    );

    const filteredOptionsShaped = filteredOptions.map((item) => {
      return {
        label: item.name + " (" + item.baseUnit + ")",
        value: item._id,
      };
    });

    return filteredOptionsShaped;
  };

  // Append new service option
  const handleAddServiceOption = () => {
    setServiceOptions([
      ...serviceOptions,
      {
        label: "",
        checked: serviceOptions.length === 0,
      },
    ]);
  };

  // Append new service textInput
  const handleAddServiceTextInput = () => {
    setServiceTextInputs([
      ...serviceTextInputs,
      {
        label: "",
        placeholder: "Input here...",
        value: "",
      },
    ]);
  };

  const handleSave = () => {
    if (!serviceName) {
      alert("Please give a name to this service");
      return;
    }

    const newService = {
      checked: true,
      key: serviceKey,
      materials: serviceMaterials.map((s) => s.value),
      label: serviceName,
      options: serviceOptions,
      textInput: serviceTextInputs,
    };

    props.onCreateService(newService, serviceSeason);
  };

  return (
    <>
      {props.open ? (
        <ModalWrapper>
          <div
            className={`flex flex-col w-2/3 h-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "Add New Service"}
              </div>
              <div className="h-10 flex flex-row">
                <div className="mr-4">
                  <StandardButton
                    color="red"
                    label="Cancel"
                    onClick={props.onClose}
                  />
                </div>

                <StandardButton
                  color="green"
                  label="Save"
                  onClick={handleSave}
                />
              </div>
            </div>
            <div className="text-base font-light my-4">
              Please create your new service. Include its name and any
              subcategories.
            </div>
            {props.chooseSeason ? (
              <FilterBar
                currentFilter={serviceSeason}
                filters={[
                  { label: "Snow", value: "snow" },
                  { label: "Landscape", value: "landscape" },
                ]}
                setCurrentFilter={setServiceSeason}
              />
            ) : null}
            {/* Service Name */}
            <LabeledTextboxInput
              label="Service Name"
              placeholder="Name your service..."
              setValue={setServiceName}
              value={serviceName}
            />
            <div className="flex flex-1 flex-col mb-8">
              {/* Service Options */}
              {serviceOptions.map((option, index) => (
                <div className="flex flex-row w-full items-center" key={index}>
                  <CheckBox
                    checked={true}
                    labelMargin={true}
                    onClick={null}
                    season="snow"
                  />
                  <LabeledTextboxInput
                    cancelClicked={() => {
                      const updatedOptions = [...serviceOptions];
                      updatedOptions.splice(index, 1);
                      setServiceOptions(updatedOptions);
                    }}
                    label={"Check Box " + (index + 1)}
                    placeholder="Name this option..."
                    setValue={(val) => {
                      const updatedOptions = [...serviceOptions];
                      updatedOptions[index].label = val;
                      setServiceOptions(updatedOptions);
                    }}
                    showCancelTopRight
                    value={option.label}
                  />
                </div>
              ))}
              {/* Service Materials Picker */}
              {displayMaterialsPicker ? (
                <div className="flex flex-col w-full my-4">
                  <div className="font-bold mb-1">
                    Choose Materials To Track
                  </div>
                  <AsyncSelect
                    className="w-full rounded-md border-2 border-snow-primary"
                    defaultOptions
                    cacheOptions
                    value={serviceMaterials}
                    isMulti
                    loadOptions={loadMaterials}
                    onChange={(selectedOptions) =>
                      setServiceMaterials(selectedOptions)
                    }
                    placeholder="All Materials"
                  />
                </div>
              ) : null}

              {/* Service Text Inputs */}
              {serviceTextInputs.map((option, index) => (
                <LabeledTextboxInput
                  cancelClicked={() => {
                    const updatedTextInputs = [...serviceTextInputs];
                    updatedTextInputs.splice(index, 1);
                    setServiceTextInputs(updatedTextInputs);
                  }}
                  key={index}
                  label="Label This Text Box"
                  placeholder="Tools Used, Materials Purchased, etc..."
                  setValue={(val) => {
                    const updatedTextInputs = [...serviceTextInputs];
                    updatedTextInputs[index].label = val;
                    setServiceTextInputs(updatedTextInputs);
                  }}
                  showCancelTopRight
                  value={option.label}
                />
              ))}
              {props.enableDelete ? (
                <div className="w-full flex justify-center mt-4">
                  <div
                    className="font-bold text-snow-red cursor-pointer hover:opacity-70 active:opacity-40"
                    onClick={() => setDisplayDeleteModal(true)}
                  >
                    Delete Service
                  </div>
                </div>
              ) : null}
            </div>
            <div className="sticky bottom-4 left-0 right-0">
              <div className="flex flex-row w-full">
                <SmallOptionButton
                  label="+ Check Option"
                  onClick={handleAddServiceOption}
                />
                <SmallOptionButton
                  label="+ Text Input"
                  onClick={handleAddServiceTextInput}
                />
                <SmallOptionButton
                  disabled={displayMaterialsPicker}
                  label="+ Material to Track"
                  onClick={() => {
                    setDisplayMaterialsPicker(true);
                  }}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      ) : null}

      <MultipleChoiceModal
        open={displayDeleteModal}
        options={[
          {
            color: "red",
            label: "Delete Permanently",
            onClick: () => {
              setDisplayDeleteModal(false);
              props.onDelete();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeleteModal(false),
          },
        ]}
        subtitle="Are you sure you want to delete this service? Warning, this is a permanent action."
        title="Delete"
      />
    </>
  );
};

const SmallOptionButton = (props) => {
  return (
    <div
      className={`flex w-44 h-7 justify-center items-center bg-snow-primary rounded-full text-snow-white text-sm font-bold mr-2 ${
        props.disabled ? "hidden" : "hover:opacity-80 active:opacity-40"
      }`}
      onClick={props.disabled ? null : props.onClick}
    >
      {props.label}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(CreateServiceModal);
