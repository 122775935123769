import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import ActiveRouteCard from "../../../components/Cards/ActiveRouteCard";
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";

const ActiveRoutesPage = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState([]);
  const [activeRoutes, setActiveRoutes] = useState([]);

  const loadData = () => {
    setLoading(true);
    axios
      .get(
        `activeRoute/company/${props.currentUser.currentCompanyId}?take=10000`,
      )
      .then((res) => {
        setActiveRoutes(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.currentUser?.currentCompanyId) {
      loadData();
    } else {
      navigate("/admin/");
    }
  }, []);

  return (
    <div className="flex flex-col items-center">
      <HeaderBar
        title="Active Routes"
        button={{
          color: "blue",
          enabled: true,
          label: "Refresh Data",
          onClick: () => loadData(),
        }}
      />
      <div className="items-start w-full px-10 pt-3">
        <p>
          See which routes and properties your staff are currently servicing.
        </p>
      </div>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
          {/* Render all Landscape Routes */}
          <div className="w-full">
            {activeRoutes.map((route, key) => (
              <ActiveRouteCard
                route={route}
                key={key}
                onDelete={loadData}
              ></ActiveRouteCard>
            ))}
          </div>
        </div>
      )}
      <div className="flex items-start w-full px-10">
        <StartARouteCard></StartARouteCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesPage);
