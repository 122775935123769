import React from "react";
import { connect } from "react-redux";
import features from "../utils/features";

/**
 *
 * @param props
 */
function IfFeature(props) {
  if (
    features.checkEnabled(
      props.feature,
      props.metadata.features,
      props.currentUser.currentCompanyId,
    )
  ) {
    return <>{props.children}</>;
  } else if (props.else) {
    return <>{props.else}</>;
  }
  return null;
}

export default connect((state) => {
  return {
    currentUser: state.currentUser,
    metadata: state.metadata,
  };
})(IfFeature);
