import React from "react";
import CheckBox from "../CheckBoxes/CheckBox";

const ReportSettingsField = (props) => {
  return (
    <div className="w-full mb-5">
      <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
        {props.season === "snow" ? "Snow " : "Landscape "}Report Includes
      </div>

      <div className="text-sm font-light mb-1 self-start text-snow-primary">
        {props.subtitle ||
          "Select what information you want included in your report emails"}
      </div>
      {props.enableSelectAll ? (
        <div
          className="text-sm font-light underline self-start text-snow-primary hover:opacity-60 active:opacity-40 w-fit cursor-pointer mt-1"
          onClick={() => {
            const updatedReportSettings = {
              ...props.reportSettings,
            };

            Object.keys(props.reportSettings).forEach((k) => {
              updatedReportSettings[k] = true;
            });

            props.setReportSettings(updatedReportSettings);
          }}
        >
          (Select All)
        </div>
      ) : null}

      <CheckBox
        checked={props.reportSettings?.driverName}
        label="Driver Name"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            driverName: !(props.reportSettings?.driverName ?? true),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.servicesPerformed}
        label="Services Performed"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            servicesPerformed: !(
              props.reportSettings?.servicesPerformed ?? true
            ),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.startEndTime}
        label="Start/End Time"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            startEndTime: !(props.reportSettings?.startEndTime ?? true),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.weatherConditions}
        label="Weather Condition"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            weatherConditions: !(
              props.reportSettings?.weatherConditions ?? true
            ),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.workImages}
        label="Work Images"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            workImages: !(props.reportSettings?.workImages ?? true),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.workNotes}
        label="Work Notes"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            workNotes: !(props.reportSettings?.workNotes ?? false),
          });
        }}
        season={props.season}
      />
      <CheckBox
        checked={props.reportSettings?.materialConsumed}
        label="Material Consumed"
        labelMargin={true}
        onClick={() => {
          props.setReportSettings({
            ...props.reportSettings,
            materialConsumed: !(
              props.reportSettings?.materialConsumed ?? false
            ),
          });
        }}
        season={props.season}
      />
    </div>
  );
};

export default ReportSettingsField;
