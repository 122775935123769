import React, { useEffect, useState } from "react";
import CheckBox from "./CheckBoxes/CheckBox";
import axios from "axios";
import errors from "../services/errors";
import flash from "../services/flash";
import auth from "../utils/auth";

const allPossiblePermissions = ["view-active-routes"]; // Tech Debt pull this from the backend or a shared lib
/**
 *
 * @param props
 */
function UserPermissions({ userToEdit, companyId }) {
  const [checkedPermissions, setCheckedPermissions] = useState([]);

  useEffect(() => {
    setCheckedPermissions(userToEdit.permissions[companyId] ?? []);
  }, [userToEdit, companyId]);

  const updatePermissions = async (newPerms) => {
    try {
      const res = await axios.post(
        `user/${userToEdit._id}/permissions?company_id=${companyId}`,
        newPerms,
      );
      setCheckedPermissions(res.data.permissions[companyId]);
      flash.success(
        "Updated permissions.  The user may need to log out and back in before they gain access.",
      );
    } catch (error) {
      errors.report(error);
      flash.error(
        "Unable to update permissions for this user.  Please contact support.",
      );
    }
  };

  return (
    <>
      <h3 className="text-lg font-bold">Permissions</h3>
      {auth.canSuperAdmin(userToEdit) ? (
        <p>
          This user is a super-admin so they have full access to all data /
          permissions.
        </p>
      ) : (
        <>
          <p>
            All employees can use the mobile app to perform basic actions. You
            can also add extra permissions to this user by checking any of the
            options below.
          </p>
          {allPossiblePermissions.map((perm, key) => (
            <div className="capitalize" key={key}>
              <CheckBox
                label={perm.replaceAll("-", " ")}
                checked={checkedPermissions.includes(perm)}
                onClick={() => {
                  let newPerms = [];
                  if (checkedPermissions.includes(perm)) {
                    newPerms = checkedPermissions.filter((p) => p != perm);
                  } else {
                    newPerms = [...checkedPermissions, perm];
                  }
                  setCheckedPermissions(newPerms);
                  updatePermissions(newPerms);
                }}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default UserPermissions;
