import React from "react";

const AddCircleButton = (props) => {
  return (
    <div
      className="w-10 h-10 rounded-full bg-snow-primary items-center justify-items-center flex items-center justify-center hover:opacity-60"
      onClick={props.onClick}
    >
      <div className="text-snow-white text-lg	font-bold">+</div>
    </div>
  );
};

export default AddCircleButton;
