import React from "react";
import PencilBlue from "../../resources/pencil-blue.svg";

function CompanyCard(props) {
  return (
    <div
      className={`flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 ${
        props.disable ? "opacity-40" : "hover:opacity-80 active:opacity-40"
      }`}
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl font-bold font-snow-primary">
          {props.name || "No Company Name Given"}
        </div>
        {props.role ? (
          <div className="font-md">
            <b>Role:</b> {props.role}
          </div>
        ) : null}
        {props.joinCode ? (
          <div className="font-md">
            <b>Join Code:</b> {props.joinCode}
          </div>
        ) : null}
        {props.showLicensed ? (
          <div className="text-md text-snow-red font-light">
            {!props.isLicensed ? "Inactive" : null}
          </div>
        ) : null}
      </div>
      {props.showEdit ? (
        <div
          className="flex w-16 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={(e) => {
            props.onRightIconClicked();
            // Don't run card pressed function
            e.stopPropagation();
          }}
        >
          <img className="w-7" src={PencilBlue} alt="edit" />
        </div>
      ) : null}
    </div>
  );
}

export default CompanyCard;
