import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import AddPaymentMethodModal from "../../../components/Modals/AddPaymentMethodModal";
import DeactivateConfirmModal from "../../../components/Modals/DeactivateConfirmModal";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";
import AccountDataTable from "../../../components/Tables/AccountDataTable";

// Redux
import { openManageSubscriptionModal } from "../../../store/reducers/metadataSlice";

// Services
import UnsubscribeConfirmModal from "../../../components/Modals/UnsubscribeConfirmModal";
import UnsubscribeReasonModal from "../../../components/Modals/UnsubscribeReasonModal";
import axiosCompanies from "../../../services/axios/companies";
import axiosContact from "../../../services/axios/contact";
import axiosStripe from "../../../services/axios/stripe";
import axiosUsers from "../../../services/axios/users";

const AccountPage = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [accountInformation, setAccountInformation] = useState(null);
  const [billingInformation, setBillingInformation] = useState(null);
  const [billingTableData, setBillingTableData] = useState(null);
  const [companyTableData, setCompanyTableData] = useState(null);
  const [displayAddPaymentMethodModal, setDisplayAddPaymentMethodModal] =
    useState(false);
  const [displayRemovePaymentMethodModal, setDisplayRemovePaymentMethodModal] =
    useState(false);
  const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
  const [displayUnsubscribeReasonModal, setDisplayUnsubscribeReasonModal] =
    useState(false);
  const [unsubscirbeSubscriptionId, setUnsubscribeSubscriptionId] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [subscriptionTableData, setSubscriptionTableData] = useState(null);

  const [displayConfirmAddLicenseModal, setDisplayConfirmAddLicenseModal] =
    useState(false);
  const [
    displayConfirmRemoveLicenseModal,
    setDisplayConfirmRemoveLicenseModal,
  ] = useState(false);
  const [companyToAddLicense, setCompanyToAddLicense] = useState(null);
  const [companyToRemoveLicense, setCompanyToRemoveLicense] = useState(null);

  // Format the Subscription Information Table
  const formatSubscriptionTableData = useCallback((data, prodInfo) => {
    // If props not passed in, skip formatting
    if (!data || !prodInfo) return;

    const formattedData = {
      labels: ["Type", "Companies", "Users", "Started", "Status"],
      content: [],
    };

    // Add each row of subscriptions
    // TODO: This doesn't quite reflect reality accurately since it implies that these items are separate subscriptions, which they might not be.
    data.forEach((sub) => {
      sub.items.data.forEach((sub_item) => {
        formattedData.content.push({
          data: [
            // Name of subscription plan
            prodInfo[sub_item.plan.product].metadata.name ??
              prodInfo[sub_item.plan.product].name,

            // Number of companies in subscription plan
            Number(prodInfo[sub_item.plan.product].metadata.companies ?? 0) *
              Number(sub_item.quantity),

            // Number of users in subscription plan
            Number(prodInfo[sub_item.plan.product].metadata.users ?? 0) *
              Number(sub_item.quantity),

            // Start date of subscription plan
            new Date(sub.start_date * 1000).toLocaleDateString(),

            // Status of subscription of plan or cancel date
            sub.cancel_at_period_end
              ? "End on " + new Date(sub.cancel_at * 1000).toLocaleDateString()
              : sub.status.charAt(0).toUpperCase() + sub.status.slice(1),
          ],
          options: [
            sub.status === "active" || sub.status === "trialing"
              ? {
                  label: "Cancel Subscription",
                  operation: () => handleDisplayUnsubscribeModal(sub.id),
                }
              : null,
          ],
        });
      });
    });
    setSubscriptionTableData(formattedData);
  }, []);

  const fetchAccountData = useCallback(() => {
    setPageLoading(true);

    // Get user's account, billing, products and subscription information
    axiosUsers.getUserAccountInformationById(
      { uid: props.currentUser.uid },
      (data) => {
        // Set account information
        setAccountInformation(data.accountInformation);
        setBillingInformation(data.billingInformation);

        // Format tables for billing and subscriptions
        formatBillingTableData(
          data.billingInformation,
          data.subscriptionInformation,
        );
        formatSubscriptionTableData(
          data.subscriptionInformation,
          data.productInformation,
        );
        formatCompanyTableData(data.companyInformation);

        setPageLoading(false);
      },
      (err) => {
        alert("Error in getting account info " + err);
      },
    );
  }, [formatSubscriptionTableData, props.currentUser.uid]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  // If redirecting here from a subscribtion change, set customerId then refresh
  const handleSuccessfulSubscribe = useCallback(
    (sessionId) => {
      if (!sessionId) return;

      // TECH DEBT: There is an issue here where the getAuth().currentUser is null when this page first loads.  Later
      // the props change a few times and in one of those subsequent refreshes, the currentUser object is available. This
      // also means that this network request gets sent to the backend 4-15 times.  This really needs fixed, but it works for
      // now.  We work around it by checking if the currentUser is available.  Long term we should figure out why it's
      // not available on first load and find a way to await it.
      if (props.currentUser.uid) {
        axiosUsers.setStripeCustomerIdFromSessionId(
          {
            uid: props.currentUser.uid,
            sessionId,
          },
          () => {
            fetchAccountData();
          },
          (err) => {
            alert("Error in setting user.stripe.customerId " + err);
          },
        );
      }
    },
    [fetchAccountData, props.currentUser.uid],
  );

  // Handle success of upgrade
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const sessionId = query.get("session_id");
      handleSuccessfulSubscribe(sessionId);
    }
  }, [handleSuccessfulSubscribe]);

  // Format the Billing Information Table
  const formatBillingTableData = (billingData, subscriptionData) => {
    // If no billing information/subscription information (eg. not subscribed), don't format billing table
    if (!subscriptionData) return;

    // Format next payment date
    const currentPeriodEnd = subscriptionData[0]?.current_period_end * 1000;
    const nextPaymentDate = currentPeriodEnd
      ? new Date(currentPeriodEnd)?.toLocaleDateString()
      : "N/A";

    const formattedData = {
      labels: [
        "Method",
        "Number",
        "Name on Card",
        "Expiration",
        "Next Bill",
        "Next Payment",
      ],
      content: billingData
        ? [
            {
              data: [
                billingData.type,
                "*-" + billingData.cardLastFour,
                billingData.nameOnCard,
                billingData.cardExpirationDate,
                billingData.nextChargeAmount,
                nextPaymentDate,
              ],
              options: [
                {
                  label: "Remove Card",
                  operation: () => setDisplayRemovePaymentMethodModal(true),
                },
              ],
            },
          ]
        : [],
    };

    setBillingTableData(formattedData);
  };

  // Format the Companies Information Table
  const formatCompanyTableData = (data) => {
    // If props not passed in, skip formatting
    if (!data) return;

    const formattedData = {
      labels: ["Name", "Users", "Created", "Status"],
      content: [],
    };

    // Add each row of subscriptions
    data.forEach((company) => {
      // Format createdAt

      formattedData.content.push({
        data: [
          company.name,
          company.userCount.toString(),
          new Date(company.createdAt).toLocaleDateString(),
          company.licensed ? "Active" : "Inactive",
        ],
        options: [
          company.licensed
            ? {
                label: "Deactivate",
                operation: () => {
                  setCompanyToRemoveLicense(company.companyId);
                  setDisplayConfirmRemoveLicenseModal(true);
                },
              }
            : {
                label: "Activate",
                operation: () => {
                  setCompanyToAddLicense(company.companyId);
                  setDisplayConfirmAddLicenseModal(true);
                },
              },
        ],
      });
    });

    setCompanyTableData(formattedData);
  };

  const handleRemovePaymentMethod = () => {
    // If customerId doesn't exist, refresh data
    if (!billingInformation?.customerId) {
      setDisplayRemovePaymentMethodModal(false);
      alert(
        "Sorry about that. It seems something went wrong on our end. Please try again.",
      );

      // Refresh account data
      fetchAccountData();

      return;
    }

    axiosStripe.removePaymentMethod(
      { stripeCustomerId: billingInformation.customerId },
      () => {
        alert(
          "Successfully removed your method of payment. Your subscription will automatically be canceled at the end of this period if there is no payment method on your account.",
        );
        setDisplayRemovePaymentMethodModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        console.error("err: ", err);
        alert(
          "Sorry about that. It seems something went wrong on our end. Please try again.",
        );

        // Refresh account data
        fetchAccountData();
      },
    );
  };

  const handleAddCompanyLicense = () => {
    if (!companyToAddLicense) return;

    axiosCompanies.addCompanyLicense(
      { companyId: companyToAddLicense, uid: props.currentUser.uid },
      () => {
        setCompanyToAddLicense(null);
        setDisplayConfirmAddLicenseModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        setCompanyToAddLicense(null);
        setDisplayConfirmAddLicenseModal(false);
        alert(err.data.message);
        console.error("Error in handleLicenseCompany: ", err);
        // If error caused by user unsubscribed or undersubscribed, display upgrade modal
        if (err.data.needsUpgrade) {
          dispatch(openManageSubscriptionModal());
        }
      },
    );
  };
  const handleRemoveCompanyLicense = () => {
    if (!companyToRemoveLicense) return;
    axiosCompanies.removeCompanyLicense(
      { companyId: companyToRemoveLicense, uid: props.currentUser.uid },
      () => {
        setCompanyToRemoveLicense(null);
        setDisplayConfirmRemoveLicenseModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        setCompanyToRemoveLicense(null);
        console.error("Error in handleLicenseCompany: ", err);
        alert(err);
      },
    );
  };

  const handleUnsubscribe = async (subscriptionId, message) => {
    const customer = await axiosStripe.getCustomerById({
      uid: props.currentUser.uid,
    });

    await axiosContact.sendContactUsEmail({
      email: customer.email,
      message: `Subscription: ${subscriptionId} - Reason for cancellation: ${message}`,
      name: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
      type: "contact-us",
    });

    axiosStripe.removeSubscription(
      { subscriptionId, uid: props.currentUser.uid },
      () => {
        alert(
          "Successfully unsubscribed. Your subscription will end at the end of this billing period.",
        );

        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        console.error("err: ", err);
        alert(
          "Sorry about that. It seems something went wrong on our end. Please try again.",
        );

        // Refresh account data
        fetchAccountData();
      },
    );
  };

  const handleDisplayUnsubscribeModal = (subId) => {
    setUnsubscribeSubscriptionId(subId);
    setDisplayUnsubscribeModal(true);
  };

  // Handle 'Save' button clicked
  const handleSaveClicked = () => {
    axiosUsers.updateUserById(
      { ...accountInformation, uid: props.currentUser.uid },
      () => {
        navigate("/admin/route");
      },
      (err) => {
        console.error(err);
      },
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          button={{
            color: "green",
            enabled: true,
            label: "Save",
            onClick: handleSaveClicked,
          }}
          title="My Account"
        />
        {pageLoading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 pb-40 items-center overflow-y-scroll">
            <div className="flex flex-1 flex-col w-full mb-10">
              <LabeledTextboxInput
                label="First Name"
                maxLength={30}
                placeholder="John"
                setValue={(val) => {
                  setAccountInformation({
                    ...accountInformation,
                    firstName: val,
                  });
                }}
                value={accountInformation?.firstName}
              />

              <LabeledTextboxInput
                label="Last Name"
                maxLength={30}
                placeholder="Doe"
                setValue={(val) => {
                  setAccountInformation({
                    ...accountInformation,
                    lastName: val,
                  });
                }}
                value={accountInformation?.lastName}
              />
              <LabeledTextboxInput
                disabled={true}
                label="Phone Number"
                placeholder="+1(***) *** ****"
                setValue={null}
                value={accountInformation?.phoneNumber}
              />
              <LabeledTextboxInput
                label="Email"
                maxLength={50}
                placeholder="email@address.com"
                setValue={(val) => {
                  setAccountInformation({
                    ...accountInformation,
                    email: val,
                  });
                }}
                value={accountInformation?.email}
              />
            </div>
            {/* Billing Information */}
            {billingTableData ? (
              <AccountDataTable
                data={billingTableData}
                emptyTableText="You have no payment method on record..."
                headerButton={
                  // If no payment method
                  !billingTableData.content ||
                  billingTableData.content.length <= 0
                    ? {
                        label: "Add Payment Method",
                        onClick: () => setDisplayAddPaymentMethodModal(true),
                      }
                    : null
                }
                subtitle="This is the payment method we have on file for you."
                title="Billing Information"
              />
            ) : null}

            {/* Subscription Information */}
            <AccountDataTable
              data={subscriptionTableData}
              emptyTableText="You are not subscribed to SnowScape..."
              headerButton={{
                label: "Manage Subscription Plan",
                onClick: () => {
                  dispatch(openManageSubscriptionModal());
                },
              }}
              subtitle="These is the subscriptions you are currently registered for."
              title="Subscriptions"
            />

            {/* My Companies */}
            <AccountDataTable
              data={companyTableData}
              subtitle="These are the companies you own."
              title="My Companies"
            />
          </div>
        )}
      </div>
      {/* Add Payment Method Modal */}
      <AddPaymentMethodModal
        adjustForSidebar={true}
        open={displayAddPaymentMethodModal}
        onClose={() => {
          setDisplayAddPaymentMethodModal(false);
          fetchAccountData();
        }}
        title="Add Payment Method"
      />

      {/* Delete Payment Method Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayRemovePaymentMethodModal}
        options={[
          {
            color: "red",
            label: "Remove",
            onClick: handleRemovePaymentMethod,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayRemovePaymentMethodModal(false),
          },
        ]}
        subtitle="Are you sure you want to remove this payment method?"
        title="Delete"
      />
      {/* Add License To Company Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayConfirmAddLicenseModal}
        options={[
          {
            color: "green",
            label: "Activate",
            onClick: handleAddCompanyLicense,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmAddLicenseModal(false),
          },
        ]}
        subtitle="Are you sure you want to activate this company?"
        title="Activate Company"
      />

      {/* Remove License To Company Modal */}
      <DeactivateConfirmModal
        adjustForSidebar={true}
        open={displayConfirmRemoveLicenseModal}
        options={[
          {
            color: "red",
            label: "Deactivate",
            onClick: handleRemoveCompanyLicense,
          },
          {
            color: "blue",
            label: "I've changed my mind",
            onClick: () => setDisplayConfirmRemoveLicenseModal(false),
          },
        ]}
        title="Deactivate Company"
      />
      <UnsubscribeConfirmModal
        adjustForSidebar
        open={displayUnsubscribeModal}
        options={[
          {
            color: "red",
            label: "Cancel Subscription",
            onClick: () => {
              setDisplayUnsubscribeModal(false);
              setDisplayUnsubscribeReasonModal(true);
            },
          },
          {
            color: "blue",
            label: "I've changed my mind",
            onClick: () => {
              setDisplayUnsubscribeModal(false);
              setUnsubscribeSubscriptionId(null);
            },
          },
        ]}
        title="Cancel Subscription"
        subtitle="Are you sure you want to unsubscribe from this subscription?"
      />
      <UnsubscribeReasonModal
        adjustForSidebar
        closeModal={() => {
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onUnsubscribe={async (message) => {
          await handleUnsubscribe(unsubscirbeSubscriptionId, message);
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        open={displayUnsubscribeReasonModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AccountPage);
