import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AddressWidget from "../../../components/Address/AddressWidget";
import HeaderBar from "../../../components/Header/HeaderBar";
import ImageNoteScroller from "../../../components/Images/ImageNoteScroller";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import GreenCheckmark from "../../../resources/checked-circle-green.svg";
import CrossCircleRed from "../../../resources/cross-circle-red.svg";
import axiosActiveRoutes from "../../../services/axios/activeRoutes";
import axiosRoutes from "../../../services/axios/routes";
import axiosJobs from "../../../services/axios/jobs";

const ActiveRouteDetailsPage = ({ currentUser }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [route, setRoute] = useState();
  const [activeRoute, setActiveRoute] = useState();
  const [currentJob, setCurrentJob] = useState(null);
  const { routeId } = useParams();

  const loadData = useCallback(async () => {
    const statusResponse =
      await axiosActiveRoutes.getActiveRouteStatus(routeId);
    setStatus(statusResponse.propertyStatus);

    const activeRouteResponse =
      await axiosActiveRoutes.getActiveRouteById(routeId);
    setActiveRoute(activeRouteResponse);
    if (activeRouteResponse.currentJobId) {
      axiosJobs.getJobById({ jobId: activeRouteResponse.currentJobId }, (r) => {
        setCurrentJob(r);
        setLoading(false);
      });
    }
    axiosRoutes.getRouteById(
      { routeId: activeRouteResponse.routeId },
      (route) => {
        setRoute(route);
        setLoading(false);
      },
    );
  }, [routeId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const jobNames = useMemo(() => {
    return status ? Object.keys(status) : [];
  }, [status]);

  const isInProgress = useCallback((jobHistory) => {
    return jobHistory?.startTimeUTC && !jobHistory?.endTimeUTC;
  }, []);

  const isCompleted = useCallback((jobHistory) => {
    return jobHistory?.startTimeUTC && jobHistory?.endTimeUTC;
  }, []);

  return (
    <div>
      <HeaderBar
        title={
          route
            ? `Active Route Details for ${route.name}`
            : "Active Route Details"
        }
      />
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="flex flex-col p-10">
          <ul>
            {jobNames.map((jobName, index) => (
              <li key={index} className="mb-4 last-of-type:mb-0 ">
                <div className="px-4">
                  <h2 className="text-xl font-bold">{jobName}</h2>
                  <hr className="" />
                  {status[jobName]?.length > 0 ? (
                    <AddressWidget address={status[jobName][0].address} small />
                  ) : (
                    <p>Not yet serviced</p>
                  )}
                  {status[jobName] ? (
                    <>
                      {status[jobName].map((jh, index) => (
                        <div
                          key={index}
                          className={`jobHistoryEntry bg-full-white ${isInProgress(jh) ? "border-orange" : isCompleted(jh) ? "border-snow-green" : "border-snow-primary"} border-l-4  p-6 mt-6`}
                        >
                          {isInProgress(jh) ? (
                            <p className="text-orange">
                              Note: Currently being serviced - data may be
                              incomplete
                            </p>
                          ) : null}
                          <div className="grid grid-cols-3 mb-2">
                            <div className="mb-2">
                              <label className="text-sm font-semibold underline">
                                Operator Notes:
                              </label>
                              <p className="text-sm">{jh.notes}</p>
                            </div>
                            <div className="mb-2">
                              <label className="text-sm font-semibold underline">
                                Completed By:
                              </label>
                              <p className="text-sm">
                                <label className="font-thin">Name:</label>{" "}
                                {jh.completedBy?.firstName +
                                  " " +
                                  jh.completedBy?.lastName}
                              </p>
                              <p className="text-sm">
                                <label className="font-thin">Phone:</label>{" "}
                                {jh.completedBy?.phoneNumber}
                              </p>
                            </div>
                            <div className="mb-2">
                              <label className="text-sm font-semibold underline">
                                Start/End Times:
                              </label>
                              <div className="">
                                <p className="text-sm">
                                  <label className="font-thin">Start:</label>{" "}
                                  {jh.startTime}
                                </p>
                                <p className="text-sm">
                                  <label className="font-thin">End:</label>{" "}
                                  {jh.endTimeUTC ? jh.endTime : "In Progress"}
                                </p>
                              </div>
                            </div>
                            <div className="mb-2">
                              <label className="text-sm font-semibold underline">
                                Services Performed
                              </label>
                              {jh.servicesPerformed.map((servicePerformed) => (
                                <div
                                  key={servicePerformed.key}
                                  className="flex items-center gap-2"
                                >
                                  {servicePerformed.checked ? (
                                    <img
                                      src={GreenCheckmark}
                                      className="w-4 h-4"
                                    />
                                  ) : (
                                    <img
                                      src={CrossCircleRed}
                                      className="w-4 h-4"
                                    />
                                  )}
                                  <span className="text-small">
                                    {servicePerformed.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div className="mb-2">
                              <label className="text-sm font-semibold underline">
                                Materials Consumed:
                              </label>
                              {jh.materialsConsumed?.length === 0 && (
                                <p className="text-sm">No materials consumed</p>
                              )}
                              {jh.materialsConsumed?.map((material) => (
                                <div
                                  key={material.key}
                                  className="flex items-center"
                                >
                                  <label className="font-thin text-sm">
                                    {material.name}:
                                  </label>
                                  <span className="text-sm">
                                    {material.quantity} {material.unit}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <ImageNoteScroller
                              enableUpload={false}
                              imageArray={jh.completedJobImages}
                              label="Property Site Images"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="my-2">
                      <p className="text-sm">
                        {jobName == currentJob?.name
                          ? "Property is being serviced now"
                          : "Property not completed yet"}
                      </p>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRouteDetailsPage);
