import { Honeybadger } from "@honeybadger-io/react";

let errors = {};
errors.report = function (err, context = {}) {
  try {
    if (err.code === "ERR_CANCELED") {
      return; // Don't report canceled axios requests - that generally means the user navigated to another page during a request.
    }
    if (Honeybadger.config.apiKey) {
      Honeybadger.notify(err, {
        context: context,
      });
    } else {
      console.log(err);
    }
  } catch (error) {
    console.log(error);
  }
};

export default errors;
