import React, { useCallback, useState } from "react";

const AddUsersAndCompaniesCard = (props) => {
  const [additionalUsers, setAdditionalUsers] = useState(0);
  const [additionalCompanies, setAdditionalCompanies] = useState(0);

  const handleAddUsersAndCompanies = useCallback(() => {
    props.onButtonClick({ additionalUsers, additionalCompanies });
  }, [additionalUsers, additionalCompanies]);

  return (
    <div className="flex flex-col w-full rounded-xl bg-snow-white drop-shadow py-6 px-8 mb-4 border-snow-light-grey border-[0.5px]">
      <div className="text-2xl text-snow-primary font-bold">
        Add Additional Users and Companies
      </div>
      <div className="mt-8">
        <p className="mb-2">
          You can add additional users and companies to your subscription at any
          time. Additional users are{" "}
          <span className="font-bold underline">$11.99 per user per month</span>
          , and additional companies are{" "}
          <span className="font-bold underline">
            $49.99 per company per month
          </span>
          .
        </p>
        <p className="font-bold">
          Your request for additional users and companies will be processed
          within 48 hours and will be reflected in your next billing cycle.
        </p>
      </div>
      <div className="mt-8 w-full">
        <div className="flex flex-row items-center justify-between">
          <div className="text-lg text-snow-light-grey">
            Additional Users ($11.99/mo/user)
          </div>
          <div className="text-2xl font-bold text-snow-primary text-left">
            <input
              className="border border-snow-light-grey rounded-md w-20 px-2 py-1"
              type="number"
              name="additionalUsers"
              defaultValue={0}
              onChange={(e) =>
                setAdditionalUsers(Number.parseInt(e.target.value, 10))
              }
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-between mt-4">
          <div className="text-lg text-snow-light-grey">
            Additional Companies ($49.99/mo/company)
          </div>
          <div className="text-2xl font-bold text-snow-primary text-left">
            <input
              className="border border-snow-light-grey rounded-md w-20 px-2 py-1"
              type="number"
              name="additionalCompanies"
              defaultValue={0}
              onChange={(e) =>
                setAdditionalCompanies(Number.parseInt(e.target.value, 10))
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-center">
        <div
          className={`flex items-center w-full lg:w-1/2 h-10 py-4 px-12 rounded-full bg-snow-blue-3 justify-center ${
            additionalUsers === 0 && additionalCompanies === 0
              ? "opacity-40 cursor-default"
              : "cursor-pointer hover:opacity-80 active:opacity-40"
          }`}
          onClick={handleAddUsersAndCompanies}
        >
          <div className={`font-medium text-lg text-center text-snow-white`}>
            Upgrade Subscription
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsersAndCompaniesCard;
