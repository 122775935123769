import React, { useState } from "react";

// Resources
import EllipsesBlue from "../../resources/ellipses-blue.svg";

const MaterialDataTable = (props) => {
  const [displayDropdownIndex, setDisplayDropdownIndex] = useState(-1);

  const handleDisplayDropdownClicked = (index) => {
    if (displayDropdownIndex === index) {
      setDisplayDropdownIndex(-1);
      return;
    }
    setDisplayDropdownIndex(index);
  };

  return (
    <>
      {!props.data || props.data.labels.length === 0 ? null : (
        <div className="flex flex-col w-full bg-snow-white mb-4 border-snow-light-grey border-[1px] rounded-xl text-snow-primary overflow-hidden">
          <div className="flex flex-col flex-1 h-full pt-4 pb-2 px-5 border-snow-light-grey">
            <div className="flex flex-row justify-between">
              {props.title ? (
                <div className="text-2xl font-bold">{props.title || ""}</div>
              ) : null}
              {props.headerButton ? (
                <div
                  className="flex items-center py-0 px-2 bg-snow-white rounded-lg border-snow-light-grey border-[1px] drop-shadow text-xs hover:opacity-70 active:opacity-40 cursor-pointer select-none"
                  onClick={props.headerButton.onClick}
                >
                  {props.headerButton.label}
                </div>
              ) : null}
            </div>
            {props.subtitle ? (
              <div className="font-light font-base">{props.subtitle || ""}</div>
            ) : null}
          </div>

          <table className="table-auto border-collapse border-spacing-2 text-left">
            <thead>
              <tr>
                {props.data.labels.map((label, key) => (
                  <th
                    className={`border-snow-light-grey border-t-[1px] ${
                      props.data.content?.length > 0 ? "border-b-[1px]" : null
                    } py-1 px-5 font-normal ${
                      key === props.data.labels.length - 1
                        ? "flex justify-end pr-6"
                        : ""
                    }`}
                    key={key}
                  >
                    {label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="">
              <tr>
                <th
                  colSpan={4}
                  className="w-full text-center py-3 bg-snow-extra-light-grey"
                >
                  Snow Materials
                </th>
              </tr>
              {props.data.content?.map((row, rowKey) => (
                <>
                  {row.season === "snow" ? (
                    <tr key={rowKey}>
                      {row.data.map((item, itemKey) => (
                        <td
                          onClick={
                            itemKey === 0
                              ? () => {
                                  props.setMaterialDetailBeingDisplayed(rowKey);
                                }
                              : null
                          }
                          className={`py-3 px-5 whitespace-nowrap text-ellipsis overflow-hidden max-w-lg ${
                            itemKey === 0 ? "font-bold hover:opacity-60" : ""
                          }
                                            `}
                          key={itemKey}
                        >
                          {item}
                        </td>
                      ))}

                      {/* Ellipses Dropdown Menu */}
                      {row.options.length > 0 ? (
                        <td
                          className="flex justify-end pr-6 py-3"
                          key={row.length}
                        >
                          <img
                            alt="menu"
                            className="w-6 hover:opacity-60 "
                            onClick={() => handleDisplayDropdownClicked(rowKey)}
                            src={EllipsesBlue}
                          />
                          {displayDropdownIndex === rowKey ? (
                            <div className="relative">
                              {/* Overlay to close on clickoff */}
                              <div
                                className=" fixed top-0 left-0 w-full h-full z-10"
                                onClick={() => setDisplayDropdownIndex(-1)}
                              ></div>
                              <ul className="absolute right-0 top-5 list-none bg-snow-white border-[1.5px] border-snow-primary rounded-lg p-2 z-20 drop-shadow-lg">
                                {row.options.map((option, key) => (
                                  <li
                                    className={`whitespace-nowrap mx-3 my-2 hover:opacity-60 ${
                                      option.label === "Delete" ||
                                      option.label === "Remove"
                                        ? "text-snow-red"
                                        : null
                                    }`}
                                    key={key}
                                    onClick={() => {
                                      // Run passed in operation
                                      option.operation();

                                      // Close dropdown
                                      setDisplayDropdownIndex(-1);
                                    }}
                                  >
                                    {option.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ) : null}
                </>
              ))}
            </tbody>

            {/* Landscape Materials */}
            <tbody className="">
              <tr>
                <th
                  colSpan={4}
                  className="w-full text-center py-3 bg-snow-extra-light-grey"
                >
                  Landscape Materials
                </th>
              </tr>
              {props.data.content?.map((row, rowKey) => (
                <>
                  {row.season === "landscape" ? (
                    <tr key={rowKey}>
                      {row.data.map((item, itemKey) => (
                        <td
                          onClick={
                            itemKey === 0
                              ? () => {
                                  props.setMaterialDetailBeingDisplayed(rowKey);
                                }
                              : null
                          }
                          className={`py-3 px-5 whitespace-nowrap text-ellipsis overflow-hidden max-w-lg ${
                            itemKey === 0 ? "font-bold hover:opacity-60" : ""
                          }
                                            `}
                          key={itemKey}
                        >
                          {item}
                        </td>
                      ))}

                      {/* Ellipses Dropdown Menu */}
                      {row.options.length > 0 ? (
                        <td
                          className="flex justify-end pr-6 py-3"
                          key={row.length}
                        >
                          <img
                            alt="menu"
                            className="w-6 hover:opacity-60"
                            onClick={() => handleDisplayDropdownClicked(rowKey)}
                            src={EllipsesBlue}
                          />
                          {displayDropdownIndex === rowKey ? (
                            <div className="relative">
                              {/* Overlay to close on clickoff */}
                              <div
                                className=" fixed top-0 left-0 w-full h-full z-10"
                                onClick={() => setDisplayDropdownIndex(-1)}
                              ></div>
                              <ul
                                className={`absolute right-0 ${
                                  rowKey >
                                  props.data.content.filter(
                                    (i) => i.season === "landscape",
                                  ).length
                                    ? "bottom-5"
                                    : "top-5"
                                } list-none bg-snow-white border-[1.5px] border-snow-primary rounded-lg p-2 z-20 drop-shadow-lg`}
                              >
                                {row.options.map((option, key) => (
                                  <li
                                    className={`whitespace-nowrap mx-3 my-2 hover:opacity-60 ${
                                      option.label === "Delete" ||
                                      option.label === "Remove"
                                        ? "text-snow-red"
                                        : null
                                    }`}
                                    key={key}
                                    onClick={() => {
                                      // Run passed in operation
                                      option.operation();

                                      // Close dropdown
                                      setDisplayDropdownIndex(-1);
                                    }}
                                  >
                                    {option.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
          {props.data.content.length === 0 ? (
            <div className="flex flex-1 w-full self-center justify-center p-6 border-t-[1px]">
              You have no materials...
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default MaterialDataTable;
