import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import LandingFeaturesCard from "../../components/Cards/LandingFeaturesCard";

// Resources
import RoundButton from "../../components/Buttons/RoundButton";
import CalendarBlueIcon from "../../resources/calendar-blue.svg";
import ClockBlueIcon from "../../resources/clock-blue.svg";
import ShieldBlueIcon from "../../resources/shield-check-blue.svg";

const FeaturesPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll">
      <div className="w-full max-w-[1200px]">
        <div className="text-snow-primary font-medium text-5xl text-center md:text-left">
          Features
        </div>
        <div className="mt-7 text-snow-primary font-light text-2xl text-left">
          Finally, an app that does what you need, nothing less and nothing
          more. We felt the need, as contractors ourselves, to create something
          that is simple to set up and easy to use out in the field. SnowScape
          is not a billing software, not an estimating software nor a
          bookkeeping program.
          <br />
          <br />
          SnowScape helps you and your team easily track the work being done, to
          eliminate paper and protect you from unnecessary exposure to liability
          or disputes about services rendered.
          <br />
          <br />
          No matter the size of your business or the subscription you have with
          SnowScape, you will always get:
        </div>

        <div className="flex flex-col lg:flex-row w-full max-w-[1200px] justify-center md:justify-between items-center lg:items-start my-12">
          <LandingFeaturesCard
            bulletFeatures={[
              "Protection from slip and fall liability",
              "Reduce exposure to slip and falls with picture documentation of all services rendered (time stamped)",
              "Unlimited job sites",
              "Ability to grow with you and your staff",
              "Affordable service packages for all company sizes",
              "Service reports to protect from billing disputes",
              "Service reports that automatically record the weather conditions",
            ]}
            label="Protect"
            logo={ShieldBlueIcon}
          />
          <LandingFeaturesCard
            bulletFeatures={[
              "Create and customize routes year round",
              "Unlimited service reports emailed upon completion of a job",
              "Unlimited picture documentation",
              "Job history report generating",
              "Tracking materials used",
              "Tracking of time spent at each job",
              "Payroll reports",
              "TimeClock management",
              "Customizable notes for each job",
              "Customizable site maps for each job",
              "Crews can flag issues as urgent",
            ]}
            label="Manage"
            logo={CalendarBlueIcon}
          />
          <LandingFeaturesCard
            bulletFeatures={[
              "Everything in one place",
              "Simple and user friendly",
              "Month to month payment terms",
              "Eliminate paperwork",
              "Real time reporting of services being performed",
              "Site maps to specify job site parameters",
              "Ability to track subcontractors",
              "A customizable report generator to give you all the data you need to bid jobs properly and bill accurately",
              "Option to send service report directly to clients",
            ]}
            label="Streamline"
            logo={ClockBlueIcon}
          />
        </div>
        <div className="flex flex-col items-center w-full ">
          <div className="text-snow-primary font-medium text-2xl text-center mb-4">
            Don't believe us?
          </div>
          <div className=" max-w-xl">
            <RoundButton
              label="Get Started With A 7 Day Free Trial"
              color="blue"
              onClick={() => {
                navigate("/get-started");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPage;
