import React from "react";

function AuthLabeledTextInput(props) {
  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (props.numbersOnly) {
      if (e.target.value === "" || re.test(e.target.value)) {
        props.setValue(e.target.value);
      }
    } else {
      props.setValue(e.target.value);
    }
  };

  return (
    <div
      className={`w-full h-16 rounded-xl my-3 py-1.5 px-5 text-snow-blue-3 bg-snow-extra-light-grey ${
        props.disabled ? "opacity-40" : null
      }`}
    >
      <div className="text-sm font-bold">{props.label}</div>
      <input
        className="w-full text-lg font-light bg-snow-extra-light-grey border-none"
        disabled={props.disabled}
        inputMode={props.inputMode || "text"}
        maxLength={props.maxLength}
        onChange={onChange}
        placeholder={props.placeholder}
        value={props.value}
      />
    </div>
  );
}

export default AuthLabeledTextInput;
