import { Honeybadger } from "@honeybadger-io/react";
import axios from "axios";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";

// Axios
import axiosUsers from "./services/axios/users";

// Redux
import { updateUser } from "./store/reducers/currentUserSlice";

// Router
import router from "./router/router";

const AuthenticationManager = (props) => {
  const auth = getAuth();
  const dispatch = useDispatch();

  // Get firebase authToken on every axios request
  axios.interceptors.request.use(async function (config) {
    const user = getAuth();
    if (user?.currentUser) {
      const token = await getIdToken(getAuth().currentUser);
      config.headers.Authorization = token;
    } else {
      console.log("no user token available to send with this request");
    }
    return config;
  });

  useEffect(() => {
    onAuthStateChanged(
      auth,
      (fbUser) => {
        try {
          if (fbUser) {
            // User Logged In
            Honeybadger.setContext({
              user_id: fbUser.uid,
            });

            // Get user data each time app is started
            axiosUsers.getUserById({ uid: fbUser.uid }, (user) => {
              // If no currentCompanyId, set as first company (usally on login)
              if (!props.currentUser?.currentCompanyId && user) {
                user.currentCompanyId = Object.keys(user.companies)[0];
              }

              dispatch(updateUser(user));
            });
          } else {
            // User Logged Out
          }
        } catch (err) {
          // Error has occured within onAuthStateChanged
          console.error("onAuthStateChanged Error (written): ", err);
        }
      },
      (err) => {
        // Error has occured on onAuthStateChanged
        console.error("onAuthStateChanged Error (internal): ", err);
      },
    );
    if (
      props.currentUser?.currentCompanyId &&
      props.currentUser?.companies &&
      // eslint-disable-next-line no-unsafe-optional-chaining
      !(props.currentUser?.currentCompanyId in props.currentUser?.companies)
    ) {
      console.log(
        "resetting current company id since it looks like you no longer have access to the current company id",
      );
      dispatch(
        updateUser({
          currentCompanyId: Object.keys(props.currentUser.companies)[0],
        }),
      );
    }
  }, [auth, dispatch]);

  return <RouterProvider router={router} />;
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AuthenticationManager);
