import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Redux
import { openManageSubscriptionModal } from "../../store/reducers/metadataSlice";

// Resources
import UnlockWhiteIcon from "../../resources/unlock-white.svg";
import features from "../../utils/features";
import auth from "../../utils/auth";

const developerSection = {
  title: "Developer",
  options: [
    {
      name: "Push Notifications",
      redirectTo: "/admin/developer/broadcast-push-notification",
    },
  ],
};

const AdminSideNavBar = (props) => {
  const dispatch = useDispatch();

  const manageSection = {
    title: "Manage",
    options: [
      { name: "Routes", redirectTo: "/admin/route" },
      { name: "Active Routes", redirectTo: "/admin/active-routes" },
      { name: "Properties", redirectTo: "/admin/property" },
      { name: "Property History", redirectTo: "/admin/property/history" },
      { name: "Time Clock", redirectTo: "/admin/timeclock" },
      { name: "Employees", redirectTo: "/admin/employee" },
      { name: "Services", redirectTo: "/admin/company/services" },
      { name: "Materials", redirectTo: "/admin/material" },
      { name: "Reports", redirectTo: "/admin/report" },
      {
        name: "Company Settings",
        redirectTo: "/admin/user/company/" + props.currentUser.currentCompanyId,
      },
    ],
  };
  features.refresh();
  return (
    <nav className="hidden sm:flex flex-col w-56 fixed top-20 bottom-0 left-0 bg-snow-primary z-30  overflow-y-scroll">
      <div className="w-full text-xl pt-3 opacity-90 text-center text-snow-white italic">
        <Link
          className="hover:opacity-60"
          to={"/admin/user/company/" + props.currentUser.currentCompanyId}
        >
          {props.currentUser.companies && props.currentUser.currentCompanyId
            ? props.currentUser.companies[props.currentUser.currentCompanyId]
                ?.companyName
            : null}
        </Link>
      </div>
      <div className="flex flex-col justify-between w-full flex-1">
        {props.currentUser.currentCompanyId ? (
          <SideBarSection section={manageSection} />
        ) : null}
        {auth.canSuperAdmin(props.currentUser) ? (
          <SideBarSection section={developerSection} />
        ) : null}
      </div>
      <div
        className="flex flex-row w-full justify-center mb-6 hover:opacity-60 active:opacity-40"
        onClick={() => dispatch(openManageSubscriptionModal())}
      >
        <img alt="upgrade" className="w-6 h-6" src={UnlockWhiteIcon} />
        <div className="text-center text-snow-white font-bold text-md ml-2">
          Upgrade Subscription
        </div>
      </div>
    </nav>
  );
};

const SideBarSection = (props) => {
  return (
    <div className="flex flex-1 flex-col mt-10 text-snow-white">
      <div className="border-b-2 w-full px-5 opacity-80 text-base	">
        {props.section.title}
      </div>
      <div className="flex flex-col mx-11 font-bold">
        {props.section.options.map((option, key) => (
          <Link
            className="my-2 hover:opacity-60"
            key={key}
            to={option.redirectTo}
          >
            {option.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser, metadata } = state;
  return { currentUser, metadata };
};

export default connect(mapStateToProps)(AdminSideNavBar);
