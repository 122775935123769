import React from "react";
import TrashRed from "../../resources/trash-can-red.svg";

function JobCard(props) {
  return (
    <div
      className="flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40"
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl font-bold font-snow-primary">
          {props.name || "No Property Name Given"}
        </div>
        <div className="font-light text-base mb-2">
          {props.address.streetLineOne +
            ", " +
            (props.address.streetLineTwo
              ? props.address.streetLineTwo + ", "
              : "") +
            props.address.city +
            ", " +
            props.address.state +
            " " +
            props.address.zip}
        </div>
        <div className="font-md">{props.instructions}</div>
      </div>
      {props.showDelete ? (
        <div
          className="flex w-16 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={(e) => {
            props.onRightIconClicked();
            // Don't run card pressed function
            e.stopPropagation();
          }}
        >
          <img className="w-7" src={TrashRed} alt="trash" />
        </div>
      ) : props.cardSequence ? (
        <div className="flex w-16 justify-end items-center text-lg font-bold text-snow-green">
          {props.cardSequence}
        </div>
      ) : null}
    </div>
  );
}

export default JobCard;
