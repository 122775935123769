import React from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";

const SignOutModal = (props) => {
  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.closeModal}>
          <div
            className={`flex flex-col w-96 h-72 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 pb-6 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                Sign Out
              </div>
            </div>
            <div className="text-base font-light mb-4">
              Are you sure you want to sign out?
            </div>

            <div className="flex flex-col flex-1 w-full justify-around">
              <RoundButton
                color="blue"
                fullWidth
                label="Yes, sign me out"
                loading={null}
                onClick={props.handleSignout}
              />
              <RoundButton
                color="red"
                fullWidth
                label="Cancel"
                loading={null}
                onClick={props.closeModal}
              />
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default SignOutModal;
