import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import LandingVideoPlayer from "../../components/Video/LandingVideoPlayer";

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll">
      <div className="w-full max-w-[1200px]">
        <div className="text-snow-primary font-medium text-5xl text-center md:text-left">
          About
        </div>
        <div className="flex flex-col xl:flex-row w-full">
          <div className="flex flex-1 text-snow-primary justify-center items-center font-light text-2xl text-left">
            As contractors and business owners ourselves, founders Jason and Tim
            saw a need within our own company to better track our team and job
            sites. As we started developing this for ourselves, we realized this
            need was prevalent within the entire snow and landscape industry. We
            set out to create an app specifically for tracking and optimizing in
            field operations. We wanted something simple and easy to use, that
            was the name of the game. Realizing that not everyone is
            technologically savvy, we made it our goal to allow a new user to
            download the app and figure out how it works without any training.
          </div>
          <div className="flex flex-1 flex-col justify-center items-center my-12">
            <div className="flex w-full justify-center pl-6 pr-3">
              <LandingVideoPlayer
                bgColor="#254862"
                showBackgroundLayer={true}
                url="https://www.youtube-nocookie.com/embed/8mrnxlnQL_c?si=249XmiTvZkNBM7xo"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1  text-snow-primary font-light text-2xl text-left">
          There were two common pain points within our industry that we wanted
          to address. First being companies of all sizes not having good record
          keeping, with pictures to protect themselves from unnecessary exposure
          to liability. The second being a lack of proof and transparency when
          it came to services rendered. Many clients would question when their
          property was serviced and the company would not have good records to
          show what services were performed and when.
          <br />
          <br />
          SnowScape is designed to give business owners a streamlined platform
          to track work being performed in the field in real time, employees
          hours, materials used all the while creating ironclad reports and
          proof as to what was done, by whom and when. It's so easy, my 4 year
          old can figure it out. SnowScape helps you and your team easily track
          the work being done, to eliminate paper and protect you from
          unnecessary exposure to liability or disputes about services rendered.
        </div>

        <div className="flex flex-col items-center w-full mt-10">
          <div className="text-snow-primary font-medium text-2xl text-center mb-4">
            Don't believe us?
          </div>
          <div className=" max-w-xl">
            <RoundButton
              label="Get Started With A 7 Day Free Trial"
              color="blue"
              onClick={() => {
                navigate("/get-started");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
