import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosUsers from "../../services/axios/users";
import firebaseAuth from "../../services/firebase/firebaseAuth";

// Shared Auth Page
const AuthComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [codeSent, setCodeSent] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [displayResendCode, setDisplayResendCode] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    // Initialize recaptcha
    firebaseAuth.getRecaptchaVerify();
  }, []);

  const handleError = (error) => {
    console.error("Authentication Error: ", error);
    if (error.code === "auth/invalid-phone-number") {
      setError("Invalid phone number, please enter a valid number");
    } else if (error.code === "auth/too-many-requests") {
      setError("Too many requests. Please try again later");
    } else if (error.message.includes("reCAPTCHA")) {
      setError(
        "There was a problem with the reCAPTCHA, please refresh the page and try again",
      );
    } else if (error.code === "auth/invalid-verification-code") {
      setError(
        "Invalid verification code, please enter the code that was sent to your phone",
      );
    } else if (error.code === "auth/code-expired") {
      // Possible TODO
      // Clear verification code to have user send a new text. Will also likely need to reset the reCAPTCHA
      setError(
        "The verification code has expired. Please refresh and try again",
      );
    } else {
      setError("An unexpected error occurred, please try again");
    }
    setDisableButton(false);
  };

  const sendNumberToFirebase = () => {
    setError("");
    setDisableButton(true);
    setDisplayResendCode(false);

    // TODO: Shouldn't force +1 since this will eventually be international
    // Remove dashes and parentheses and add +1 to the beginning
    const cleanedNumber = "+1" + ("" + phoneNumber).replace(/\D/g, "");

    firebaseAuth.sendSMS(
      cleanedNumber,
      () => {
        setCodeSent(true);
        setDisableButton(false);
      },
      (error) => {
        handleError(error);
      },
    );

    setTimeout(() => {
      setDisplayResendCode(true);
    }, 10000);
  };

  const sendCodeToFirebase = async () => {
    setError("");
    setDisableButton(true);

    try {
      // Send code to Firebase
      await firebaseAuth.signInWithCode(
        verificationCode,
        async (user) => {
          // Check if existing user or new user
          axiosUsers.getUserById({ uid: user.uid }, async (userData) => {
            if (userData) {
              // If existing user, set user in redux and complete login
              userData.currentCompanyId = Object.keys(userData.companies)[0];
              dispatch(updateUser(userData));
              navigate("/admin");
            } else {
              // If new user, navigate to AccountSetupPage
              navigate("/account-setup");
            }
          });
        },
        (error) => {
          handleError(error);

          setDisableButton(false);
        },
      );
    } catch (error) {
      // Handle other unexpected errors
      console.error("An unexpected error occurred: ", error);
      handleError(error);
      setDisableButton(false);
    }
  };

  const resendSMS = () => {
    const cleanedNumber = "+1" + ("" + phoneNumber).replace(/\D/g, "");

    firebaseAuth.sendSMS(
      cleanedNumber,
      () => {},
      (error) => {
        handleError(error);
      },
    );
  };
  return (
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="w-full max-w-sm">
        <div className="mb-2 text-center font-bold text-2xl text-snow-blue-3">
          {props.title}
        </div>
        {error ? (
          <div className="mb-4 text-center text-base text-snow-red">
            {error}
          </div>
        ) : null}
        <AuthLabeledTextInput
          disabled={false}
          inputMode="numeric"
          label="Phone Number"
          maxLength={10}
          numbersOnly={true}
          placeholder="(555)-123-4567"
          setValue={setPhoneNumber}
          value={phoneNumber}
        />
        <AuthLabeledTextInput
          disabled={!codeSent}
          inputMode="numeric"
          label="Verification Code"
          maxLength={6}
          numbersOnly={true}
          placeholder="Enter the 6 digit code we just texted you"
          setValue={setVerificationCode}
          value={verificationCode}
        />
        <div className="w-full">
          <RoundButton
            label={codeSent ? "Login" : "Submit"}
            color="blue"
            disabled={
              disableButton ||
              (phoneNumber.length < 10 && !codeSent) ||
              (codeSent && verificationCode.length < 6)
            }
            onClick={codeSent ? sendCodeToFirebase : sendNumberToFirebase}
          />
        </div>
        <div className="flex mt-0 justify-center items-center">
          <div id="recaptcha-container"></div>
        </div>
        {displayResendCode && (
          <div
            className="my-6 text-center text-base text-snow-blue-3 hover:opacity-80 active:opacity-40 cursor-pointer"
            onClick={resendSMS}
          >
            <div className="underline">Don't see our text message? </div>
            <span className="underline">Click here to resend code</span>
          </div>
        )}
        <div
          className="hover:opacity-80 active:opacity-40 mt-6 mb-6 text-center font-bold text-sm text-snow-blue-3"
          onClick={() => {
            navigate(props.subtextUrl);
          }}
        >
          {props.subtext}
        </div>
      </div>
    </div>
  );
};

export default AuthComponent;
