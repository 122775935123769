import React from "react";

function StandardLabel(props) {
  return (
    <div className={"w-full text-snow-primary mb-1"}>
      <div className="text-sm font-bold">{props.label}</div>
    </div>
  );
}

export default StandardLabel;
