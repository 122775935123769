import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

// Resources
import CrossCircleIcon from "../../resources/cross-circle-red.svg";

const ImageNoteItem = (props) => {
  return (
    <>
      <div className="relative w-52 h-52 flex-shrink-0 bg-full-black rounded-md">
        {props.loading ? (
          <div className="absolute top-1 left-1 bottom-1 right-1 z-10">
            <div className="flex w-full h-full justify-center items-center">
              <MoonLoader color="#fffafa" speedMultiplier={0.5} />
            </div>
          </div>
        ) : null}

        {props.enableDelete && props.uri && !props.loading ? (
          <div
            onClick={props.deletePressed}
            className={`absolute top-0 right-0 z-10 hover:opacity-70 active:opacity-40`}
          >
            <img className="w-5" src={CrossCircleIcon} alt="delete" />
          </div>
        ) : null}

        <img
          alt="img-note"
          className={`w-full h-full rounded-md border-x border-y border-snow-primary object-cover ${
            props.loading ? "opacity-40" : null
          }`}
          src={props.uri}
        />
      </div>
    </>
  );
};

export default ImageNoteItem;
