import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import StandardButton from "../Buttons/StandardButton";
import GenericCard from "./GenericCard";
import ClockBlue from "../../resources/clock-blue.svg";
function ActiveRouteCard(props) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({});

  const fetchRoutes = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`activeRoute/route/${props.route._id}/status`)
      .then((res) => {
        setStatus(res.data);
        setLoading(false);
      });
  }, [props.route]);

  useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);

  const handleDelete = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Display an alert asking if the user is sure they want to delete the route
      if (
        window.confirm(
          "Are you sure you want to discontinue this route? The active route will be discontinued, but no data will be lost.",
        )
      ) {
        await axios.delete(
          `activeRoute/operator/${props.route.operator.uid}/delete`,
        );
        props.onDelete();
      }
    },
    [props],
  );

  let isInProgress = (propertyName) => {
    let jh = status[propertyName];
    return (
      (jh && jh.endTimeUTC == null) ||
      props.route.currentJob?.name == propertyName
    );
  };

  let isComplete = (propertyName) => {
    return status[propertyName] && status[propertyName].endTimeUTC;
  };

  return (
    <GenericCard
      title={props.route.name}
      numberOfJobs={props.route.jobIds.length}
      name={props.route.name}
      noOpacity
    >
      <div className="md:flex justify-between">
        <div>
          <p>
            Foreman: {props.route.operator?.firstName}{" "}
            {props.route.operator?.lastName}
          </p>
          <p>
            Current Property:{" "}
            {props.route.currentJob?.name ?? "Between properties"}
          </p>
          <p>Properties on Route: {props.route.jobIds.length}</p>
        </div>
        <div className="mt-4 md:mt-0">
          <table>
            <tbody>
              {loading
                ? [...Array(props.route.jobIds.length)].map((i) => (
                    <tr key={i}>
                      <td>Loading property status...</td>
                    </tr>
                  ))
                : Object.keys(status).map((name, key) => (
                    <tr key={key}>
                      <td>
                        {status[name] ? (
                          <NavLink
                            className={`underline`}
                            to={`/admin/property/history/${status[name]._id}`}
                          >
                            {name}
                          </NavLink>
                        ) : (
                          name
                        )}
                      </td>
                      <td
                        className={`pl-3 ${isComplete(name) ? "text-snow-green" : "text-snow-extra-light-grey"}`}
                      >
                        {isInProgress(name) ? (
                          <img
                            alt="clock"
                            className="w-7 h-7"
                            src={ClockBlue}
                          />
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="20"
                              height="20"
                              rx="10"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              className="check"
                              d="M16.5 7.375L9.625 15.375L6.49994 11.7386"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-4">
        <StandardButton
          className="z-10"
          color="red"
          label="Discontinue"
          onClick={handleDelete}
        />
        <a href={`/admin/active-routes/${props.route._id}`}>
          <StandardButton className="z-10" color="blue" label="View Details" />
        </a>
      </div>
    </GenericCard>
  );
}

export default ActiveRouteCard;
