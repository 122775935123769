import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import AddressWidget from "../../../components/Address/AddressWidget";
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import ImageNoteScroller from "../../../components/Images/ImageNoteScroller";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import ServicesField from "../../../components/Services/ServicesField";
import MaterialsConsumedTable from "../../../components/Tables/MaterialsConsumedTable";
import CrossCircleRed from "../../../resources/cross-circle-red.svg";
import backend from "../../../services/axios/backendAxios";
import axiosJobHistory from "../../../services/axios/jobHistory";
import flash from "../../../services/flash";
import axios from "axios";
import errors from "../../../services/errors";
import TwistedArrow from "../../../resources/twisted-arrow.svg";
import Until from "../../../components/Until";
import { format } from "date-fns";
const AdminJobHistoryDetailsPage = () => {
  let { jobHistoryId } = useParams();

  const [displayOptionsPopup, setDisplayOptionsPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [jobHistory, setJobHistory] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosJobHistory.getJobHistoryById({ _id: jobHistoryId }, (jobHistory) => {
      setJobHistory(jobHistory);
      // setHeaderLabel(jobHistory.name);
      setLoading(false);
    });
  }, [jobHistoryId]);

  const handleResendReportEmail = () => {
    if (!jobHistoryId) return;

    axiosJobHistory.resendReportEmail(
      { jobHistoryId },
      () => {
        alert("Successfully Sent");
      },
      (err) => {
        alert(err);
      },
    );
  };

  const updateMaterials = (key, val) => {
    let b = { ...jobHistory };
    // check if valid number
    if (!isNaN(Number(val))) {
      b.materialsConsumed[key].quantity = Number(val);
      setJobHistory(b);
      backend
        .patch(`/material-consumed/${b.materialsConsumed[key]._id}`, {
          quantity: val,
        })
        .then(() => {
          flash.success("Saved: Material consumption updated");
        });
    }
  };

  const updateJobHistory = useCallback(async (jobHistory) => {
    try {
      let data = (
        await axios.post(`job/history/add?return_with_materials=1`, jobHistory)
      ).data;
      setJobHistory(data.jobHistory);
      flash.success("Property history updated.");
      setIsDirty(false);
    } catch (err) {
      errors.report(err);
      flash.error(
        "Unable to save your changes.  Please refresh and try again.",
      );
    } finally {
      setSaving(false);
    }
  });

  const handleClearFlaggedForReviewClick = () => {
    updateJobHistory({
      ...jobHistory,
      flaggedForReview: false,
    });
  };

  const modifyJH = useCallback((newJobHistoryData) => {
    setJobHistory(newJobHistoryData);
    setIsDirty(true);
  });

  let buttons = [
    {
      color: "blue",
      label: "Resend Report",
      onClick: () => handleResendReportEmail(),
    },
    {
      color: "green",
      disabled: !isDirty,
      label: "Save Changes",
      loading: saving,
      onClick: () => updateJobHistory(jobHistory),
    },
  ];
  let until = new Date("2024-10-01T00:00:00.000Z");

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Property History" buttons={buttons} />
      <Until until={until}>
        <div className="flex flex-row justify-end align-top mb-2.5 pr-20 w-full">
          <span className="mr-2 font-hand font-bold text-lg">
            Now you can edit this property history
          </span>
          <img
            alt="-->"
            className="w-10 relative -top-4"
            src={TwistedArrow}
            style={{ transform: "scaleY(-1)" }}
          />
        </div>
      </Until>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div
          className={`flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll mb-8 ${new Date() < until ? "-mt-10" : ""}`}
        >
          {jobHistory.flaggedForReview && (
            <div className="flex items-center justify-between px-8 bg-snow-red w-full mb-8 py-4 rounded-lg">
              <span className="text-xl text-full-white">
                Flagged for Review
              </span>
              <div
                className="text-full-white cursor-pointer"
                onClick={handleClearFlaggedForReviewClick}
              >
                <img
                  className="w-12"
                  src={CrossCircleRed}
                  alt="Unflag for review"
                />
              </div>
            </div>
          )}
          <div className="flex flex-row w-full justify-between">
            <div className="text-3xl font-medium text-snow-primary">
              {jobHistory.name}
            </div>
          </div>

          <AddressWidget address={jobHistory.address} />

          <ImageNoteScroller
            enableUpload={false}
            imageArray={jobHistory.sitemaps}
            isSitemap={true}
            label="Sitemaps"
          />

          <LabeledTextboxInput
            disabled={true}
            label="Instructions"
            multiline={true}
            value={jobHistory.instructions}
          />
          <LabeledTextboxInput
            disabled={false}
            label="Operator Notes"
            multiline={true}
            value={jobHistory.notes}
            setValue={(value) => {
              modifyJH({ ...jobHistory, notes: value });
            }}
          />
          <LabeledTextboxInput
            disabled={true}
            label="Operator Name"
            multiline={false}
            value={
              jobHistory.completedBy.firstName +
              " " +
              jobHistory.completedBy.lastName
            }
          />
          <LabeledTextboxInput
            disabled={true}
            label="Operator Contact"
            multiline={false}
            value={jobHistory.completedBy.phoneNumber}
          />
          <LabeledTextboxInput
            disabled={true}
            label="Start Time"
            multiline={false}
            value={jobHistory.startTime}
          />
          <LabeledTextboxInput
            disabled={true}
            label="End Time"
            multiline={false}
            value={jobHistory.endTime}
          />
          <ServicesField
            key="service-fields"
            label="Services Completed"
            subtitle="The following services were completed during this visit to the property."
            services={jobHistory.servicesPerformed}
            setServices={(newServices) => {
              modifyJH({ ...jobHistory, servicesPerformed: newServices });
            }}
            showSubOptions={true}
            readOnly={false}
          />
          <MaterialsConsumedTable
            materialsConsumed={jobHistory.materialsConsumed}
            onMaterialUpdate={updateMaterials}
          ></MaterialsConsumedTable>
          <ImageNoteScroller
            enableUpload={false}
            imageArray={jobHistory.completedJobImages}
            label="Property Site Images"
          />
          {jobHistory.updatedBy ? (
            <div className="py-4">
              <span style={{ marginRight: 7 }}>&#9432;</span>Notice: This record
              was updated at{" "}
              {format(new Date(jobHistory.updatedAt), "yyyy-MM-dd HH:mm")} by{" "}
              {jobHistory.updatedBy?.firstName} {jobHistory.updatedBy?.lastName}
              .
            </div>
          ) : null}
          <div className="text-snow-light-grey text-xs">
            Record ID: {jobHistory._id}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminJobHistoryDetailsPage;
