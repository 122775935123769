import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

// Components
import StandardButton from "../Buttons/StandardButton";
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";
import ModalWrapper from "./ModalWrapper";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../services/axios/companies";

const JoinCompanyModal = (props) => {
  const dispatch = useDispatch();

  const [joinCode, setJoinCode] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    setJoinCode("");
    setSubmitLoading(false);
  }, [props.open]);

  const handleJoinCompany = () => {
    if (joinCode.length < 8) return;

    setSubmitLoading(true);
    // Join Company
    axiosCompanies.joinCompany(
      { joinCode, userId: props.currentUser.uid },
      (data) => {
        if (!data.successfullyJoined) {
          alert(data.message);
        } else {
          // Update currentUser's companies
          dispatch(updateUser({ companies: data.user.companies }));
          setSubmitLoading(false);
          props.onClose();
          props.refreshPage();
        }
      },
      (error) => {
        console.error("Failed to join company: ", error);
        alert(error);
        props.onClose();
        props.refreshPage();
      },
    );
  };

  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.onClose}>
          <div
            className={`flex flex-col w-2/3 h-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 overflow-x-scroll ${
              props.adjustForSidebar ? "md:ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title || "Join Company"}
              </div>
              <div className="h-10 flex flex-row">
                <StandardButton
                  color="red"
                  label="Cancel"
                  onClick={props.onClose}
                />
              </div>
            </div>
            <div className="text-lg font-light mb-4">{props.subtitle}</div>
            <div className="w-full">
              <LabeledTextboxInput
                label="Company Join Code"
                maxLength={8}
                numbersOnly={true}
                placeholder="12345678"
                setValue={setJoinCode}
                value={joinCode}
              />
              <StandardButton
                color="green"
                label="Join Company"
                loading={submitLoading}
                disabled={joinCode.length < 8}
                onClick={handleJoinCompany}
              />
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(JoinCompanyModal);
