import axios from "axios";
import errors from "../errors";

const axiosMaterials = {
  createOrUpdateMaterial: async (data) => {
    try {
      const res = await axios.post(`material/`, data);

      return res.data;
    } catch (err) {
      errors.report(err);
    }
  },
  deleteMaterial: async (data) => {
    try {
      const res = await axios.delete(`material/${data.materialId}`);

      return res.data;
    } catch (err) {
      errors.report(err);
    }
  },
  getCompanyMaterials: async (data) => {
    try {
      const res = await axios.get(
        `material/company/${data.companyId}?take=10000`,
      );

      return res.data;
    } catch (err) {
      errors.report(err);
    }
  },
};

export default axiosMaterials;
