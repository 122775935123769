// Package Docs: https://www.npmjs.com/package/react-date-range
import React from "react";
import { DateRangePicker } from "react-date-range";

const DateRangePickerWrapper = (props) => {
  const handleSelect = (ranges) => {
    console.log(ranges);
    props.setDateRange(ranges.selection);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  return (
    <>
      {props.open ? (
        <div className="">
          <DateRangePicker
            className=""
            // minDate={new Date('10/1/2023')}
            maxDate={new Date()}
            months={1}
            onChange={handleSelect}
            rangeColors={["#04A777"]}
            ranges={[props.dateRange]}
            // calendarFocus='backwards'
            // scroll={{ enabled: true }}
          />
        </div>
      ) : null}
    </>
  );
};

export default DateRangePickerWrapper;
