import React from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";

const MultipleChoiceModal = (props) => {
  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.closeModal}>
          <div
            className={`flex flex-col w-96 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 pb-6 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title}
              </div>
            </div>
            <div className="text-base font-light mb-4">{props.subtitle}</div>

            <div className="flex flex-col flex-1 w-full">
              {props.options.map((option, key) => (
                <div className="mb-2" key={key}>
                  <RoundButton
                    color={option.color || "blue"}
                    fullWidth
                    label={option.label}
                    loading={null}
                    onClick={option.onClick}
                  />
                </div>
              ))}
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default MultipleChoiceModal;
