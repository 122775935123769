import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

const LoadingIcon = (props) => {
  return (
    <div className="fixed top-1/2">
      <BounceLoader
        color={props.color || "#082D4A"}
        size={props.size || 70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingIcon;
