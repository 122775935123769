import React from "react";
import CheckGreen from "../../resources/checked-circle-green.svg";

const SubscriptionPlanCard = (props) => {
  return (
    <div
      className={`flex flex-col w-full rounded-xl bg-snow-white drop-shadow  py-6 pl-8 mb-4 ${
        props.highlight
          ? "border-[2px] border-snow-green"
          : "border-[0.5px] border-snow-light-grey"
      }`}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row w-32 justify-between items-center">
          <div className="text-lg text-snow-light-grey">{props.plan.name}</div>
          <div className="text-2xl font-bold text-snow-primary w-10 text-left">
            {props.plan.price}
          </div>
        </div>
        {props.plan.current ? (
          <div className="flex items-center rounded-l-lg text-xl px-12 font-bold text-snow-white bg-snow-green">
            Current
          </div>
        ) : null}
      </div>
      <div className="flex flex-col mt-6">
        {props.plan.details?.map((detail, key) => (
          <div className="flex flex-row mb-2 items-center" key={key}>
            <img className="mr-2 w-4 h-4" src={CheckGreen} alt="checkmark" />
            <div className="">{detail}</div>
          </div>
        ))}
      </div>

      {props.handleChoosePlan ? (
        <div
          className={`flex justify-center items-center self-center w-full lg:w-1/2 h-10 py-4 px-12 mr-8 rounded-full bg-snow-blue-3 justify-center ${
            props.plan.current
              ? "opacity-40"
              : "hover:opacity-80 active:opacity-40"
          }`}
          onClick={
            !props.plan.current
              ? () => props.handleChoosePlan(props.plan)
              : null
          }
        >
          <div className={`font-medium text-lg text-center text-snow-white`}>
            Choose Plan
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default SubscriptionPlanCard;
