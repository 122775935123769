import React, { useState } from "react";

// Components
import ImageNoteItem from "../Images/ImageNoteItem";

// Services
import fileUploader from "../../services/s3/fileUploader";

const DragAndDropUpload = (props) => {
  const [selectedImages, setSelectedImages] = useState(null);

  const handleImagePicked = async (file) => {
    file.uri = URL.createObjectURL(file);

    // Add thumbnail template to array while loading
    setSelectedImages(file);

    try {
      // Upload image and update thumbnail with S3 link
      let links = await fileUploader.uploadFiles([file]);

      props.setImageArray([...props.imageArray, ...links]);
      setSelectedImages(null);
    } catch (error) {
      setSelectedImages(null);
      console.error(error);
      alert(error);
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    if (!file) {
      alert("Somethign went wrong uploading file");
      return;
    }

    handleImagePicked(file);
  };
  return (
    <>
      {selectedImages?.uri ? (
        <div>
          <ImageNoteItem uri={selectedImages.uri} loading={true} />
        </div>
      ) : (
        <label
          className={`flex justify-center font-bold items-center text-snow-blue-3 aspect-square border-dashed border-2 rounded-md border-snow-primary mb-5 ${
            props.disabled ? "opacity-60" : "hover:opacity-70 active:opacity-40"
          }
            ${props.fullWidth ? "h-52 w-full text-lg" : "h-52 w-52 text-md"}`}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleFileInput}
            className={"bg-snow-light-grey hidden"}
          />
          {props.uploadBoxLabel}
        </label>
      )}
    </>
  );
};

export default DragAndDropUpload;
