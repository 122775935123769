import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

function PrivateRoutes(props) {
  return props.currentUser.uid ? props.children : <Navigate to="/" />;
}

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(PrivateRoutes);
