import React from "react";

function GenericCard(props) {
  return (
    <div
      className={`flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 ${
        props.noOpacity ? "" : "active:opacity-40"
      }`}
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl">{props.title}</div>
        <div className="mb-2">{props.children}</div>
      </div>
    </div>
  );
}

export default GenericCard;
